import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import { fetchPurchasedContentIds } from '../contents/contentsActions';
import {
  addContentToCart,
  removeContentFromCart,
  resetCart,
  syncCartContents,
  doCheckout,
  saveCartContents,
  updateCheckout
} from './cartActions';
import { initialState } from './cartState';

function addToCart(cart: string[], contentId: string) {
  // Prevent duplicates
  if (!cart.includes(contentId)) {
    cart.push(contentId);
  }
}

function removeFromCart(cart: string[], contentId: string) {
  const index = cart.findIndex((id) => id === contentId);
  if (index >= 0) {
    cart.splice(index, 1);
  }
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add to cart

    builder.addCase(addContentToCart.pending, (state, action) => {
      // Immediately add to the cart
      addToCart(state.contentIds, action.meta.arg.id);
    });

    // Note: if needed, we could verify that the server accepted the
    // change in a fulfilled case but at the moment the API does not
    // modify the cart.

    builder.addCase(addContentToCart.rejected, (state, action) => {
      // Remove the newly added item
      removeFromCart(state.contentIds, action.meta.arg.id);
    });

    // Remove from cart

    builder.addCase(removeContentFromCart.pending, (state, action) => {
      // Immediately remove from the cart
      removeFromCart(state.contentIds, action.meta.arg.id);
    });

    // Note: if needed, we could verify that the server accepted the
    // change in a fulfilled case but at the moment the API does not
    // modify the cart.

    builder.addCase(removeContentFromCart.rejected, (state, action) => {
      // Add back the removed item
      addToCart(state.contentIds, action.meta.arg.id);
    });

    // Reset cart

    builder.addCase(resetCart, (state, action) => {
      state.contentIds = [];
    });

    // Sync contents

    builder.addCase(syncCartContents.fulfilled, (state, action) => {
      state.onlineId = action.payload.cartOnlineId;
      state.contentIds = action.payload.cartContents?.map((c) => c.id) ?? [];

      // The actual contents are merged in the contents slice
    });

    // Save contents

    builder.addCase(saveCartContents.fulfilled, (state, action) => {
      state.onlineId = action.payload.id;

      // We could update the cart contents if the API could change it (eg. for corrections)
      // but it's not the case right now so it would be useless
    });

    // Checkout

    builder.addCase(doCheckout.rejected, (state, action) => {
      state.checkout = undefined;
    });

    builder.addCase(updateCheckout, (state, action) => {
      state.checkout = action.payload;
    });

    // Fetch purchased contents: remove fetched contents from the cart!

    builder.addCase(fetchPurchasedContentIds.fulfilled, (state, action) => {
      state.contentIds = _.difference(state.contentIds, action.payload);
    });
  }
});

export default cartSlice.reducer;
