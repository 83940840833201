import { createSelector } from 'reselect';

import { RootState } from '../../store';
import { UMap } from '../../utils';
import { Vendor, BranchInfo, Content, BranchContents, BranchContentSlice } from './contentsTypes';

export const MARKET_ID = 'market';
export const LOCAL_ID = 'local';
export const PURCHASED_ID = 'purchased'; // Special category for the purchased contents
export const SKATTER_ID = 'skatter'; // Special category for Skatter-compatible contents

export const PAGINATION_SIZE = 40;

interface ContentsState {
  branchesInfo: UMap<BranchInfo>;
  branchesContents: UMap<BranchContents>;

  // Tracks local branches being removed (between removeLocalFolder.pending
  // and removeLocalFolder.fulfilled/rejected) to ensure that folder watching
  // does not add back a folder in the process of being removed.
  localBranchesBeingRemoved: string[];

  contents: UMap<Content>;
  purchasedContentIds: string[]; // TODO redundant with branchesInfo?
  freeContentIds: string[];
  featuredContentIds: string[];
  popularContentIds: string[];

  vendors: UMap<Vendor>;
}

export function createBranchInfo(options: Partial<BranchInfo>): BranchInfo {
  const defaults: BranchInfo = {
    name: '',
    childrenIds: [],
    childrenStatus: 'loaded',
    contentAmount: 0
  };

  return {
    ...defaults,
    ...options
  };
}

export function createBranchContents(options: Partial<BranchContentSlice> = {}): BranchContents {
  return {
    all: {
      contentIds: [],
      contentComplete: false,
      contentStatus: 'unloaded',
      ...options
    },
    filtered: {
      contentIds: [],
      contentComplete: false,
      contentStatus: 'unloaded',
      ...options
    }
  };
}

export const initialState: ContentsState = {
  branchesInfo: {
    [MARKET_ID]: createBranchInfo({
      name: 'Marketplace',
      icon: 'mdi-storefront-outline'
    }),
    [LOCAL_ID]: createBranchInfo({
      name: 'Local',
      icon: 'mdi-monitor'
    })
  },

  branchesContents: {
    [MARKET_ID]: createBranchContents(),
    [LOCAL_ID]: createBranchContents()
  },

  localBranchesBeingRemoved: [],

  contents: {},
  purchasedContentIds: [], // TODO redundant with branchesInfo?
  freeContentIds: [],
  featuredContentIds: [],
  popularContentIds: [],

  vendors: {}
};

export function getBranchContents(branchId: string, root: RootState) {
  const branchContents = root.contents.branchesContents[branchId];
  const allContents = root.contents.contents;

  return (
    branchContents?.all.contentIds
      .map((id) => allContents[id])
      .filter((c): c is Content => c !== undefined) ?? []
  );
}

export const selectAllBranchesInfos = (root: RootState) => root.contents.branchesInfo;
export const selectAllBranchesContents = (root: RootState) => root.contents.branchesContents;
export const selectAllContents = (root: RootState) => root.contents.contents;

export const selectPurchasedContentIds = (root: RootState) => root.contents.purchasedContentIds;

export const selectFreeContentIds = (root: RootState) => root.contents.freeContentIds;
export const selectFreeContents = createSelector(
  [selectFreeContentIds, selectAllContents],
  (freeContentIds, allContents) =>
    freeContentIds
      .map((id) => allContents[id])
      // Remove missing contents
      .filter((c): c is Content => c !== undefined)
);

export const selectFeaturedContentIds = (root: RootState) => root.contents.featuredContentIds;
export const selectFeaturedContents = createSelector(
  [selectFeaturedContentIds, selectAllContents],
  (featuredContentIds, allContents) =>
    featuredContentIds
      .map((id) => allContents[id])
      // Remove missing contents
      .filter((c): c is Content => c !== undefined)
);

export const selectPopularContentIds = (root: RootState) => root.contents.popularContentIds;
export const selectPopularContents = createSelector(
  [selectPopularContentIds, selectAllContents],
  (popularContentIds, allContents) =>
    popularContentIds
      .map((id) => allContents[id])
      // Remove missing contents
      .filter((c): c is Content => c !== undefined)
);
