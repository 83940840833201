import { ContentPackageType, ContentType, Renderer } from './features/contents/contentsTypes';

import sketchupLogo from './img/sketchup-logo.svg';
import vrayLogo from './img/vray-logo.svg';
import enscapeLogo from './img/enscape-logo.svg';
import theaLogo from './img/thea-logo.svg';

export const CONTENT_TYPES = [
  'asset',
  'bundle',
  'model',
  'composition',
  'material',
  'hdri',
  'tool'
] as const;
export const CONTENT_TYPES_DATA: {
  [key in ContentType]: { name: string; icon: string; iconColor?: string };
} = {
  asset: { name: 'Asset', icon: 'mdi-file-outline' },
  bundle: { name: 'Bundle', icon: 'mdi-layers-triple', iconColor: undefined },
  model: { name: '3D Model', icon: 'mdi-cube-outline', iconColor: undefined },
  composition: { name: 'Skatter Composition', icon: 'l-icon-skatter', iconColor: '#eb3128' },
  material: { name: 'Material', icon: 'l-icon-material', iconColor: undefined },
  hdri: { name: 'HDRI', icon: 'mdi-brightness-5', iconColor: undefined },
  tool: { name: 'Tool', icon: 'mdi-power-plug-outline', iconColor: undefined }
};

export const PACKAGE_TYPES_DATA: { [key in ContentPackageType]: { name: string } } = {
  default: { name: 'Default' },
  highpoly: { name: 'High-poly' },
  lowpoly: { name: 'Low-poly' },
  proxy: { name: 'Proxy' }
};

export const RENDERERS = ['sketchup', 'vray', 'enscape', 'thea'] as const;
export const RENDERERS_DATA: { [key in Renderer]: { name: string; img: any } } = {
  sketchup: { name: 'SketchUp', img: sketchupLogo },
  vray: { name: 'V-Ray', img: vrayLogo },
  enscape: { name: 'Enscape', img: enscapeLogo },
  thea: { name: 'Thea', img: theaLogo }
};
