import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as MUI from '@material-ui/core';
import * as MUILab from '@material-ui/lab';
import { fade } from '@material-ui/core/styles/colorManipulator';
import classnames from 'classnames';

import Icon from '../lindaleui/components/Icon';
import { Tooltip } from '../lindaleui/components/Tooltip';

import TreeItem from './TreeItem';

import { dispatchAndNotify } from '../store';
import { createLocationString, isExtension, useCustomSnackbar } from '../utils';
import { selectExtensionVersion, selectReadOnly } from '../features/app/appState';
import { LOCAL_ID, MARKET_ID, selectAllBranchesInfos } from '../features/contents/contentsState';
import {
  selectCurrentLocation,
  selectCurrentBranchId,
  DEFAULT_FILTERS,
  SortCriteria
} from '../features/navigation/navigationState';
import { selectIsLoggedIn, selectUserEmail } from '../features/auth/authState';
import { removeLocalFolder } from '../features/library/libraryActions';

import lindaleLogo from '../img/logo_text_small.svg';

const useStyles = MUI.makeStyles(({ palette }: MUI.Theme) =>
  MUI.createStyles({
    drawer: (props: Props) => ({
      width: props.width,
      zIndex: 1201
    }),
    drawerPaper: (props: Props) => ({
      width: props.width,
      boxShadow: '3px 0 5px rgba(0, 0, 0, 0.1)'
    }),
    tree: {
      padding: '8px',
      flex: 1
    },
    addLocalFolder: {
      color: 'rgba(0,0,0,0.5)',
      fontStyle: 'italic'
    },
    content: {
      height: '100%',
      flexDirection: 'column',
      display: 'flex'
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '16px'
    },
    version: {
      fontFamily: 'monospace',
      color: 'grey'
    },
    logoContainer: {
      width: '100%'
    },
    logoImage: {
      width: '80%',
      padding: '10px'
    },
    buttonIcon: {
      paddingLeft: '8px',
      paddingRight: '4px',
      color: 'gray'
    },
    accountActive: {
      color: palette.primary.main
    },
    divider: {
      backgroundColor: fade(palette.divider, 0.06) // Very discreet dividers
    }
  })
);

interface Props {
  width: number;
  mobileDrawerOpen: boolean;
  onShowTerms: () => void;
  onAddLocalFolder: () => void;
  onResize: (width: number) => void;
  onMobileDrawerToggle: () => void;
  onOpenAccountOverlay: () => void;
}

const defaultProps: Partial<Props> = {
  width: 200
};

export function NavigationDrawer(props: Props) {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useCustomSnackbar();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const allBranches = useSelector(selectAllBranchesInfos);
  const history = useHistory();
  const currentLocation = useSelector(selectCurrentLocation);
  const currentBranchId = useSelector(selectCurrentBranchId);
  const readOnly = useSelector(selectReadOnly);
  const extensionVersion = useSelector(selectExtensionVersion);
  const userEmail = useSelector(selectUserEmail);

  const marketBranch = allBranches[MARKET_ID];
  const localBranch = allBranches[LOCAL_ID];

  const renderBranch = (
    branchId: string,
    parentPath: string[],
    depth: number,
    defaultCollapseIcon?: string,
    defaultExpandIcon?: string,
    defaultEndIcon?: string,
    defaultSelectedEndIcon?: string,
    extraChildren?: React.ReactElement[]
  ) => {
    const branch = allBranches[branchId];

    if (branch === undefined) {
      console.error(`Navigation drawer: cannot find branch ${branchId}`);
      return null;
    }

    const branchPath = [...parentPath];
    branchPath.push(branchId);

    const selected = branchPath.includes(branchId);

    let children: (React.ReactElement | null)[] = [];
    if (branch.childrenStatus !== 'loaded') {
      children = [
        <TreeItem loading key={1} nodeId='' name='' depth={depth + 1} />,
        <TreeItem loading key={2} nodeId='' name='' depth={depth + 1} />,
        <TreeItem loading key={3} nodeId='' name='' depth={depth + 1} />
      ];
    } else {
      children = branch.childrenIds.map((id) =>
        renderBranch(
          id,
          branchPath,
          depth + 1,
          defaultCollapseIcon,
          defaultExpandIcon,
          defaultEndIcon,
          defaultSelectedEndIcon
        )
      );
    }

    if (extraChildren) {
      children = children.concat(extraChildren);
    }

    const onClick = (e: React.MouseEvent) => {
      // Close the mobile drawer when a clicked item has no children
      if (props.mobileDrawerOpen && React.Children.count(children) === 0) {
        props.onMobileDrawerToggle();
      }

      e.preventDefault();
    };

    // Local root folders: add a "remove" action
    const action =
      branchPath[0] === LOCAL_ID && depth === 1 && !readOnly
        ? {
            icon: 'mdi-close',
            label: 'Remove folder',
            callback: () => {
              dispatchAndNotify(removeLocalFolder({ branchId }), enqueueSnackbar);

              // Go back to the local root if we just removed the folder that we're in

              if (currentBranchId === branchId) {
                history.push(createLocationString({ ...currentLocation, branchPath: [LOCAL_ID] }));
              }
            }
          }
        : undefined;

    return (
      <TreeItem
        key={branchId}
        className='navigation-tree-item'
        href={createLocationString({
          ...currentLocation,
          branchPath,
          searchKeywords: [],
          vendorId: undefined
        })}
        nodeId={branchId}
        name={branch.name}
        icon={branch.errors ? 'mdi-alert' : branch.icon}
        iconColor={branch.iconColor}
        collapseIcon={branch.errors ? 'mdi-alert' : branch.collapseIcon ?? defaultCollapseIcon}
        expandIcon={branch.errors ? 'mdi-alert' : branch.expandIcon ?? defaultExpandIcon}
        endIcon={branch.errors ? 'mdi-alert' : selected ? defaultSelectedEndIcon : defaultEndIcon}
        info={branchPath.length > 1 ? branch.contentAmount : undefined}
        onIconClick={onClick}
        onLabelClick={onClick}
        depth={depth}
        tooltip={branch.errors?.join('\n')}
        action={action}
      >
        {
          // To have the corrent endIcon, we need to have only one entry here. That's why we merged children and extraChildren above
          children
        }
      </TreeItem>
    );
  };

  const addLocalFolder = (
    <TreeItem
      key='addFolder'
      nodeId='addFolder'
      name='Add Folder'
      icon='mdi-folder-plus-outline'
      onIconClick={
        readOnly
          ? undefined
          : (e) => {
              e.preventDefault();
              props.onAddLocalFolder();
            }
      }
      onLabelClick={
        readOnly
          ? undefined
          : (e) => {
              e.preventDefault();
              props.onAddLocalFolder();
            }
      }
      className={classes.addLocalFolder}
      depth={1}
      disabled={readOnly}
      tooltip={readOnly ? 'You cannot add a folder in Read-Only mode' : undefined}
    />
  );

  const accountLabel = userEmail ?? 'Not logged in';

  const drawerContents = (
    <>
      <Link
        // Back to the marketplace's root
        to={createLocationString({
          branchPath: [MARKET_ID],
          contentPath: [],
          searchKeywords: [],
          searchFilters: DEFAULT_FILTERS,
          sortCriteria: SortCriteria.DEFAULT,
          vendorId: undefined
        })}
        className={classnames(classes.logoContainer, 'main-logo')}
      >
        <img src={lindaleLogo} alt='3D Bazaar logo' className={classes.logoImage} />
      </Link>

      <MUI.Divider variant='middle' className={classes.divider} />

      {/* Log in/out button */}

      <Tooltip title={isLoggedIn ? accountLabel : 'Login'}>
        <MUI.Button
          style={{ justifyContent: 'start', textTransform: 'none', flexShrink: 0 }}
          onClick={props.onOpenAccountOverlay}
        >
          <Icon
            className={classnames(classes.buttonIcon, { [classes.accountActive]: isLoggedIn })}
            icon={isLoggedIn ? 'mdi-account' : 'mdi-account-off-outline'}
          />

          {isLoggedIn ? (
            <MUI.Typography style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {accountLabel}
            </MUI.Typography>
          ) : (
            <MUI.Typography style={{ fontStyle: 'italic', color: 'gray' }}>
              {accountLabel}
            </MUI.Typography>
          )}
        </MUI.Button>
      </Tooltip>

      <MUI.Divider variant='middle' className={classes.divider} />

      {/* Tree + terms (same container to handle overflow consistently) */}

      <MUI.Box className={classes.content} style={{ overflowY: 'auto' }}>
        <MUILab.TreeView
          className={classnames(classes.tree, 'navigation-tree')}
          expanded={currentLocation.branchPath}
          selected={currentLocation.branchPath.slice(-1)}
        >
          {marketBranch && renderBranch(MARKET_ID, [], 0, 'mdi-menu-down', 'mdi-menu-right')}
          {localBranch &&
            renderBranch(
              LOCAL_ID,
              [],
              0,
              'mdi-folder-open-outline',
              'mdi-folder-outline',
              'mdi-folder-outline',
              'mdi-folder-open-outline',
              isExtension() ? [addLocalFolder] : undefined
            )}
        </MUILab.TreeView>

        <div className={classes.footer}>
          <MUI.Link
            onClick={(e: any) => {
              e.preventDefault();
              props.onShowTerms();
            }}
            color='inherit'
            variant='body2'
            href='#'
          >
            Terms of Use
          </MUI.Link>

          {extensionVersion && <span className={classes.version}>version {extensionVersion}</span>}
        </div>
      </MUI.Box>
    </>
  );

  return (
    <>
      {/* Large viewport: use an persistent drawer */}
      <MUI.Hidden xsDown>
        <MUI.Drawer
          className={classnames(classes.drawer, 'navigation-drawer')}
          classes={{ paper: classes.drawerPaper }}
          variant='permanent'
        >
          {drawerContents}
        </MUI.Drawer>
      </MUI.Hidden>

      {/* Mobile drawer controlled externally (because the button is in another component */}
      <MUI.Drawer
        variant='temporary'
        anchor='left'
        open={props.mobileDrawerOpen}
        onClose={props.onMobileDrawerToggle}
        className={classnames(classes.drawer, 'navigation-drawer')}
        classes={{
          root: classes.drawer,
          paper: classes.drawerPaper
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        {drawerContents}
      </MUI.Drawer>
    </>
  );
}

NavigationDrawer.defaultProps = defaultProps;

export default React.memo(NavigationDrawer);
