import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import sleep from 'sleep-promise';
import * as zod from 'zod';

import { ActionError, RootState, toActionError } from '../../store';
import { extractJsendError, jsendSchema, webApiAsync } from '../../utils';
import { refreshAuthToken } from '../auth/authActions';
import { selectUserId } from '../auth/authState';
import { TermsSchema, areTermsAccepted, Terms } from './termsState';

export const fetchTerms = createAsyncThunk<
  Terms,
  void,
  { state: RootState; rejectValue: ActionError }
>('terms/fetch', async (_, thunkAPI) => {
  try {
    const schema = jsendSchema(
      {
        terms: TermsSchema
      },
      {
        name: zod.string()
      }
    );

    const response = await webApiAsync({
      method: 'get',
      endpoint: 'terms/v1/get/marketplace',
      schema
    });

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    return response.data.data.terms;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch terms', error));
  }
});

export const acceptTerms = createAsyncThunk<
  void,
  void,
  { state: RootState; rejectValue: ActionError }
>('terms/accept', async (_, thunkAPI) => {
  try {
    const terms = thunkAPI.getState().terms.contents;

    if (terms === undefined) {
      throw new Error('terms not loaded');
    }

    const authToken = thunkAPI.getState().auth.authToken;
    const userId = selectUserId(thunkAPI.getState());

    if (authToken === undefined || userId === undefined) {
      throw new Error('cannot accept terms without being logged in');
    }

    if (areTermsAccepted(authToken, terms.id)) {
      throw new Error('terms already accepted');
    }

    const schema = jsendSchema(
      {
        accepted: zod.literal(true)
      },
      {
        user: zod.string().optional(),
        id: zod.string().optional()
      }
    );

    const response = await webApiAsync({
      method: 'post',
      endpoint: 'terms/v1/accept/' + terms.id,
      jwt: authToken,
      schema
    });

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    if (!response.data.data.accepted) {
      throw new Error('terms could not be accepted');
    }

    // Refresh the auth token so that it contains the new terms acceptance
    // (retry a few times)

    for (let i = 0; i < 10; ++i) {
      const tokens = await thunkAPI.dispatch(refreshAuthToken()).then(unwrapResult);

      if (tokens.authToken !== undefined && areTermsAccepted(tokens.authToken, terms.id)) {
        return; // Ok!
      }

      await sleep(3000);
    }

    throw new Error('auth token does not contain newly accepted terms');
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('accept terms', error));
  }
});
