import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Base64 } from 'js-base64';
import { basename } from 'path';
import { Blurhash } from 'react-blurhash';
import * as MUI from '@material-ui/core';
import * as MUIIcons from '@material-ui/icons';
import * as MUILab from '@material-ui/lab';

import Checkbox from '../lindaleui/components/Checkbox';
import Icon from '../lindaleui/components/Icon';
import RatioBox from '../lindaleui/components/RatioBox';

import { CONTENT_TYPES_DATA } from '../constants';
import { dispatchAndNotify } from '../store';
import { selectCartContentIds } from '../features/cart/cartState';
import { selectAllContents } from '../features/contents/contentsState';
import { toggleFavorite } from '../features/favorites/favoritesActions';
import { selectFavoriteContentIds } from '../features/favorites/favoritesState';
import { isContentLocal } from '../features/contents/contentsTypes';

import missingImage from '../img/cube-outline.svg';
import { createLocationString, getBestImageUrl, useCustomSnackbar } from '../utils';
import {
  getContentPackagesBeingDownloaded,
  selectDownloadingPackages
} from '../features/download/downloadState';
import ContentPrice from './ContentPrice';
import { selectCurrentLocation } from '../features/navigation/navigationState';
import ContentDownloadProgress from './ContentDownloadProgress';
import ContentQuickAction from './ContentQuickAction';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    tile: {
      overflow: 'initial'
    },

    root: {
      display: 'block',
      position: 'relative',
      width: '100%',
      paddingTop: '100%',
      color: 'inherit',
      textDecoration: 'inherit',
      cursor: 'pointer'
    },

    paper: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      overflow: 'hidden'
    },
    paperBottom: {
      top: 0,
      right: '12px',
      bottom: '12px',
      left: 0
    },
    paperMiddle: {
      top: '6px',
      right: '6px',
      bottom: '6px',
      left: '6px'
    },
    paperTop: {
      top: '12px',
      right: 0,
      bottom: 0,
      left: '12px'
    },

    // Images fit vertically and are horizontally cropped if wider than container
    imageContainer: {
      flex: 1,
      backgroundColor: 'rgb(240, 240, 240)',
      overflow: 'hidden',
      position: 'relative'
    },
    blurHash: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0
    },
    image: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      '& img': {
        objectFit: 'contain',
        height: '100%',
        width: '100%'
      }
    },

    cartChip: {
      textTransform: 'uppercase',
      paddingLeft: '4px',
      position: 'absolute',
      top: '8px',
      right: '8px',
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)' // copied from MUI.Button
    },

    contentTypes: {
      position: 'absolute',
      top: '8px',
      left: '8px'
    },
    contentType: {
      height: '24px',
      width: '24px',
      backgroundColor: '#fff',
      color: '#888',
      border: '1px solid #ccc'
    },

    titleBar: {
      height: '40px',
      width: '100%',
      padding: '0 12px',
      display: 'flex',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.72)',
      fontSize: '13px',
      fontWeight: 500
    },
    titleBarAction: {
      marginRight: '8px',
      display: 'none',
      alignItems: 'center',
      '$paper:hover &': {
        display: 'inline-flex'
      },
      '&:last-of-type': {
        marginRight: 0
      }
    },
    downloadProgress: {
      marginRight: theme.spacing(1)
    },

    icon: {
      marginRight: '8px',
      color: 'rgba(0, 0, 0, 0.54)'
    },

    name: {
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginRight: '8px'
    },

    menu: {
      marginRight: '-8px',
      visibility: 'hidden', // We don't use display:none because we need the element to exist to anchor the menu popover
      '$paper:hover &': {
        visibility: 'visible'
      }
    }
  })
);

type Props = MUI.GridListTileProps & {
  contentId: string;
  columnWidth: number;
  icon?: string;
  iconColor?: string;
  stacked?: boolean;
  className?: string;
  onOpenCart: () => void;
  onOpenBundleDownloadDetails: (id: string) => void;
};

function ContentCard_(props: Props) {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useCustomSnackbar();

  const [elevation, setElevation] = React.useState(0);

  const currentLocation = useSelector(selectCurrentLocation);
  const allContents = useSelector(selectAllContents);
  const favoriteContentIds = useSelector(selectFavoriteContentIds);
  const cartContentIds = useSelector(selectCartContentIds);
  const downloadingPackages = useSelector(selectDownloadingPackages);

  const content = allContents[props.contentId];
  const loading = content?.status === 'loading';

  const inCart = cartContentIds.includes(props.contentId);

  const onToggleFavorite = React.useCallback(
    (e, checked) => {
      dispatchAndNotify(toggleFavorite({ id: props.contentId, toggle: checked }), enqueueSnackbar);
    },
    [props.contentId, enqueueSnackbar]
  );

  // Ref to the name to display  a tooltip when the name overflows

  const nameRef = React.useRef<HTMLHeadingElement>(null);

  const nameOverflown = (nameRef.current?.scrollWidth ?? 0) > (nameRef.current?.offsetWidth ?? 0);

  if (content === undefined) {
    return null;
  }

  // Render icons depending on the type of the content

  const contentTypes =
    content.type === 'marketcontent' || content.type === 'localcontent' ? (
      <MUILab.AvatarGroup className={classes.contentTypes}>
        {content.contentTypes.includes('bundle') && (
          <MUI.Tooltip title={CONTENT_TYPES_DATA['bundle'].name}>
            <MUI.Avatar className={classes.contentType}>
              <Icon icon={CONTENT_TYPES_DATA['bundle'].icon} size={18} />
            </MUI.Avatar>
          </MUI.Tooltip>
        )}
        {content.contentTypes.includes('composition') && (
          <MUI.Tooltip title={CONTENT_TYPES_DATA['composition'].name}>
            <MUI.Avatar className={classes.contentType}>
              <Icon
                icon={CONTENT_TYPES_DATA['composition'].icon}
                size={18}
                color={CONTENT_TYPES_DATA['composition'].iconColor}
              />
            </MUI.Avatar>
          </MUI.Tooltip>
        )}
        {content.contentTypes.includes('model') && (
          <MUI.Tooltip title={CONTENT_TYPES_DATA['model'].name}>
            <MUI.Avatar className={classes.contentType}>
              <Icon icon={CONTENT_TYPES_DATA['model'].icon} size={18} />
            </MUI.Avatar>
          </MUI.Tooltip>
        )}
      </MUILab.AvatarGroup>
    ) : null;

  const isFavorite = favoriteContentIds.includes(props.contentId);
  const isLocal = isContentLocal(content);

  // Retrieve this content's download progress if it exists
  const packagesBeingDownloaded = getContentPackagesBeingDownloaded(
    props.contentId,
    downloadingPackages
  );
  const downloadProgress = packagesBeingDownloaded.length > 0 ? packagesBeingDownloaded[0] : null;

  const name =
    content.type === 'marketcontent' || content.type === 'localcontent'
      ? content.name ?? '[MISSING NAME]'
      : basename(Base64.decode(content.id)); // file name for local files

  const imageUrl =
    content.type === 'marketcontent' || content.type === 'localcontent'
      ? content.images?.[0]?.urls
        ? getBestImageUrl(content.images?.[0]?.urls, props.columnWidth)
        : undefined
      : content.image;

  const ratio =
    content.type === 'marketcontent' || content.type === 'localcontent'
      ? content.images?.[0]?.ratio
      : undefined;

  const contentElement = (
    <>
      <div className={classes.imageContainer}>
        {/* Loading animation */}
        {loading && <MUILab.Skeleton variant='rect' width='100%' height='100%' animation='wave' />}

        {/* Blurhash preview as background */}
        {(content.type === 'localcontent' || content.type === 'marketcontent') &&
          content.images[0]?.blurHash && (
            <RatioBox ratio={ratio} rootProps={{ width: '100%', height: '100%' }}>
              <Blurhash hash={content.images[0].blurHash} width='100%' height='100%' />
            </RatioBox>
          )}

        {/* Loaded image */}
        <div className={classes.image}>
          <img src={imageUrl ?? missingImage} loading='lazy' alt={name} />
        </div>

        {contentTypes}

        {inCart && (
          <MUI.Tooltip title='Click to open your cart'>
            <MUI.Chip
              classes={{ root: classes.cartChip }}
              icon={<MUIIcons.ShoppingCart />}
              label='In your cart'
              color='secondary'
              size='small'
              onClick={(e) => {
                e.preventDefault(); // Prevent the underlying overlay's link getting clicked
                props.onOpenCart();
              }}
            />
          </MUI.Tooltip>
        )}
      </div>

      <div className={classes.titleBar}>
        {props.icon && props.icon !== '' && (
          <Icon className={classes.icon} icon={props.icon} color={props.iconColor} />
        )}

        <MUI.Tooltip title={nameOverflown ? name : ''}>
          <MUI.Typography variant='subtitle2' ref={nameRef} className={classes.name}>
            {name}
          </MUI.Typography>
        </MUI.Tooltip>

        {/* Download progress: here and not in the quick action so that it's always visible */}
        {downloadProgress && (
          <span className={classes.downloadProgress}>
            <ContentDownloadProgress progress={downloadProgress} />
          </span>
        )}

        <span
          className={classes.titleBarAction}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation(); // Prevents the overlay opening
          }}
        >
          <ContentQuickAction
            content={content}
            onOpenCart={props.onOpenCart}
            onOpenBundleDownloadDetails={props.onOpenBundleDownloadDetails}
          />
        </span>

        {content.type === 'marketcontent' && <ContentPrice content={content} />}

        {isLocal && (
          <span
            className={classes.titleBarAction}
            style={isFavorite ? { display: 'inline' } : {}}
            onClick={(e) => {
              e.stopPropagation(); // Prevents the overlay opening
            }}
          >
            <Checkbox
              icon='mdi-heart-outline'
              checkedIcon='mdi-heart'
              checked={isFavorite}
              tooltip={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
              onChange={onToggleFavorite}
            />
          </span>
        )}
      </div>
    </>
  );

  return (
    <MUI.GridListTile style={props.style} classes={{ tile: classes.tile }} className='content-card'>
      <Link
        to={createLocationString({ ...currentLocation, contentPath: [props.contentId] })}
        className={classes.root}
      >
        {props.stacked && (
          <>
            <MUI.Paper
              className={classnames(props.className, classes.paper, classes.paperBottom)}
              elevation={elevation}
            ></MUI.Paper>
            <MUI.Paper
              className={classnames(props.className, classes.paper, classes.paperMiddle)}
              elevation={2 + elevation}
            ></MUI.Paper>
          </>
        )}
        <MUI.Paper
          className={classnames(props.className, classes.paper, {
            [classes.paperTop]: props.stacked
          })}
          elevation={props.stacked ? 4 + elevation : elevation}
          onMouseOver={() => setElevation(2)}
          onMouseOut={() => setElevation(0)}
        >
          {contentElement}
        </MUI.Paper>
      </Link>
    </MUI.GridListTile>
  );
}

export const ContentCard = React.memo(ContentCard_);

type LoadingContentCardProps = MUI.GridListTileProps & {};

export function LoadingContentCard(props: LoadingContentCardProps) {
  const classes = useStyles(props);

  return (
    <MUI.GridListTile style={props.style} classes={{ tile: classes.tile }}>
      <div className={classes.root}>
        <MUI.Paper className={classnames(classes.paper, props.className)} elevation={0}>
          <div className={classes.imageContainer}>
            <MUILab.Skeleton variant='rect' width='100%' height='100%' animation='wave' />
          </div>

          <div className={classes.titleBar}>
            <MUILab.Skeleton variant='text' width='60%' animation='wave' />
          </div>
        </MUI.Paper>
      </div>
    </MUI.GridListTile>
  );
}
