import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import sleep from 'sleep-promise';
import * as zod from 'zod';

import { ActionError, RootState, toActionError } from '../../store';
import {
  isExtension,
  sketchupSchema,
  sketchupAsync,
  nullToUndefined,
  jsendSchema,
  webApiAsync,
  downloadUrl,
  extractJsendError
} from '../../utils';
import { ContentPackageSchema, ContentPackage } from '../contents/contentsTypes';
import { addLocalFolder } from '../library/libraryActions';
import { ImportStatus, PackageDownload, PackageDownloadSchema } from './downloadState';

export const updateDownloadProgress = createAction<{
  packageId: string;
  progress: PackageDownload;
}>('download/updateDownloadProgress');

export const fetchDownloadedPackages = createAsyncThunk<
  ContentPackage[],
  void,
  { state: RootState; rejectValue: ActionError }
>('download/fetchDownloadedPackages', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot fetch downloaded packages out of extension');
    }

    const schema = sketchupSchema(zod.array(ContentPackageSchema));

    const response = await sketchupAsync({
      methodName: 'getDownloadedPackages',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch downloaded packages', error));
  }
});

export const pickDownloadLocation = createAsyncThunk<
  string | undefined,
  void,
  { state: RootState; rejectValue: ActionError }
>('download/pickDownloadLocation', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot pick download location out of the extension');
    }

    const schema = sketchupSchema(zod.string().nullable());

    const response = await sketchupAsync({
      methodName: 'pickDownloadLocation',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    return nullToUndefined(response.data);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('pick download location', error));
  }
});

export const downloadPackage = createAsyncThunk<
  ContentPackage | undefined,
  {
    downloadLocation?: string;
    contentName: string; // for naming the downloaded file
    contentId: string;
    packageId: string;
  },
  { state: RootState; rejectValue: ActionError }
>(
  'download/downloadPackage',
  async ({ downloadLocation, contentName, contentId, packageId }, thunkAPI) => {
    try {
      // Fetch a download URL

      const authToken = thunkAPI.getState().auth.authToken;

      const schema = jsendSchema(
        {
          url: zod.string()
        },
        {
          message: zod.string().optional()
        }
      );

      const urlResponse = await webApiAsync({
        method: 'post',
        endpoint: 'marketplace/v1/contents/assets/download/' + contentId,
        data: { packageId },
        jwt: authToken,
        schema
      });

      if (urlResponse.data.status !== 'success') {
        throw extractJsendError(urlResponse.data);
      }

      const packageUrl = urlResponse.data.data.url;

      // Web download

      if (!isExtension()) {
        downloadUrl(packageUrl);
        return undefined;
      }

      // Local download
      else {
        // The download location is needed for the extension

        if (!downloadLocation) {
          throw new Error('download location not provided');
        }

        // Bind a callback to receive progress updates
        // TODO cleaner to poll from the UI?

        (window as any).onDownloadProgress = (packageId: string, progress: PackageDownload) => {
          const parsed = PackageDownloadSchema.safeParse(progress);

          if (!parsed.success) {
            console.error('Invalid onDownloadProgress data', parsed.error, progress);
          } else {
            thunkAPI.dispatch(updateDownloadProgress({ packageId, progress }));
          }
        };

        const schema = sketchupSchema(ContentPackageSchema);

        const downloadResponse = await sketchupAsync({
          methodName: 'downloadPackage',
          args: [downloadLocation, contentName, contentId, packageId, packageUrl],
          schema
        });

        if (!downloadResponse.success) {
          throw downloadResponse.errors;
        }

        // Add the download location to the list of local folders
        // (except if it's in a folder that's already recorded)

        // TODO make it an option?

        const encodedLocation = Base64.encode(downloadLocation);

        const alreadyLocal = Object.keys(thunkAPI.getState().contents.branchesInfo).some(
          (folder) => {
            return encodedLocation.startsWith(folder);
          }
        );

        if (alreadyLocal) {
          console.log(`Download location ${downloadLocation} already part of local library`);
        } else {
          console.log(`Adding download location ${downloadLocation} to local library`);

          await thunkAPI.dispatch(addLocalFolder(downloadLocation)).then(unwrapResult);
        }

        return downloadResponse.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(toActionError('download packages', error));
    }
  }
);

// File import

export const updateImportStatus = createAction<
  { contentId: string; status: ImportStatus } | undefined
>('download/updateImportStatus');

// Step 1: load the file into SketchUp
export const importFileInSketchup = createAsyncThunk<
  void,
  { path: string; contentId: string },
  { state: RootState; rejectValue: ActionError }
>('download/importFileInSketchup', async ({ path, contentId }, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot import file out of the extension');
    }

    // Start the import

    thunkAPI.dispatch(updateImportStatus({ contentId, status: 'loading' }));

    const schema = sketchupSchema(zod.null());

    const response = await sketchupAsync({
      methodName: 'importFile',
      args: [path],
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    thunkAPI.dispatch(updateImportStatus({ contentId, status: 'ready' }));
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('import file', error));
  }
});

// Step 2: wait for the user to place the loaded asset
export const waitForImportEnd = createAsyncThunk<
  void,
  { timeout: number },
  { state: RootState; rejectValue: ActionError }
>('download/waitForImportEnd', async ({ timeout }, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot import file out of the extension');
    }

    // Once the model is loaded, wait for the user to place it
    //
    // Because the logic on the SketchUp side is a bit tricky, let's
    // not loop indefinitely and use a maximum timeout as a safety net.

    const step = 500;

    const schema = sketchupSchema(zod.boolean());

    for (let t = 0; t < timeout; t += step) {
      const response = await sketchupAsync({
        methodName: 'isImporting',
        schema
      });

      if (!response.success) {
        throw response.errors;
      }

      // Import completed or continue waiting?
      if (!response.data) {
        break;
      }

      await sleep(step);
    }

    thunkAPI.dispatch(updateImportStatus(undefined));
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('wait for import', error));
  }
});
