import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import * as MUI from '@material-ui/core';

import Button from '../lindaleui/components/Button';

import { dispatchAndNotify } from '../store';
import { selectAuthToken } from '../features/auth/authState';
import { acceptTerms, fetchTerms } from '../features/terms/termsActions';
import {
  selectTermsAccepted,
  selectTermsLoadingStatus,
  selectTermsContents,
  selectTermsLoadingFailed
} from '../features/terms/termsState';
import { useCustomSnackbar } from '../utils';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    root: {
      paddingTop: '0 !important' // Fixes large top padding
    },
    loading: {
      marginTop: '1rem'
    }
  })
);

interface Props {
  onClose: () => void;
}

export function TermsDialog(props: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useCustomSnackbar();

  const termsContents = useSelector(selectTermsContents);
  const termsLoadingStatus = useSelector(selectTermsLoadingStatus);
  const termsLoadingFailed = useSelector(selectTermsLoadingFailed);
  const termsAccepted = useSelector(selectTermsAccepted);
  const authToken = useSelector(selectAuthToken);

  const onAccept = React.useCallback(() => {
    dispatchAndNotify(acceptTerms(), enqueueSnackbar);
  }, [enqueueSnackbar]);

  // Load the terms if not loaded yet (eg. the user is not logged in)

  React.useEffect(() => {
    if (termsLoadingStatus === 'unloaded' && !termsLoadingFailed) {
      dispatchAndNotify(fetchTerms(), enqueueSnackbar);
    }
  }, [termsLoadingStatus, termsLoadingFailed, enqueueSnackbar]);

  // Render

  return (
    <MUI.Dialog open onClose={props.onClose} className='terms-overlay'>
      <MUI.DialogContent dividers={true} classes={{ root: classes.root }}>
        {termsContents ? (
          <MUI.DialogContentText>
            <ReactMarkdown
              source={termsContents.body}
              escapeHtml={false}
              renderers={{
                paragraph: (props) => <React.Fragment {...props} />
              }}
            />
            <p>Last updated: {termsContents.date}</p>
          </MUI.DialogContentText>
        ) : (
          <div className={classes.loading}>Loading...</div>
        )}
      </MUI.DialogContent>

      {termsContents && authToken && (
        <MUI.DialogActions>
          <MUI.Tooltip title={termsAccepted ? 'You already accepted those terms of use' : ''}>
            <div>
              {' '}
              {/* for disabled tooltip to work */}
              <Button
                color='primary'
                variant='contained'
                disabled={termsAccepted}
                loading={termsLoadingStatus !== 'loaded'}
                onClick={onAccept}
                className='accept-terms-button'
              >
                I Accept
              </Button>
            </div>
          </MUI.Tooltip>
        </MUI.DialogActions>
      )}
    </MUI.Dialog>
  );
}
