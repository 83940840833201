import React, { useState } from 'react';
import { BrowserRouter, HashRouter, useLocation, useHistory } from 'react-router-dom';
import { Provider as ReduxProvider, useDispatch, useSelector } from 'react-redux';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import useInterval from 'use-interval';
import * as MUI from '@material-ui/core';
import * as MUIIcons from '@material-ui/icons';

import Analytics from 'analytics';
//@ts-ignore - No type declarations
import { AnalyticsProvider, useAnalytics } from 'use-analytics';
//@ts-ignore - No type declarations
import googleAnalytics from '@analytics/google-analytics';

import Icon from './lindaleui/components/Icon';

import store, { AppDispatch, dispatchAndNotify } from './store';
import theme from './Theme';
import { AccountOverlay, AccountOverlayMessageType } from './components/AccountOverlay';
import BundleDownloadOverlay from './components/BundleDownloadOverlay';
import Dialog from './components/Dialog';
import Header from './components/Header';
import BranchContent from './components/BranchContent';
import LocalLibraryAd from './components/LocalLibraryAd';
import Maintenance from './components/Maintenance';
import NavigationDrawer from './components/NavigationDrawer';
import ContentOverlay from './components/ContentOverlay';
import { UpdateOverlay } from './components/UpdateOverlay';
import { CartOverlay } from './components/CartOverlay';
import { TermsDialog } from './components/TermsDialog';
import {
  createLocationString,
  extractJWTData,
  extractLocationString,
  isExtension,
  useCustomSnackbar,
  usePrevious
} from './utils';
import { refreshAuthToken } from './features/auth/authActions';
import {
  selectAuthToken,
  selectIsTokenRefreshing,
  selectRefreshToken
} from './features/auth/authState';
import { selectTermsAcceptance, selectTermsContents } from './features/terms/termsState';
import {
  selectCurrentPathNames,
  selectCurrentContent,
  isPathLocal,
  isPathMarket,
  selectCurrentLocation,
  selectCurrentVendor
} from './features/navigation/navigationState';
import { startup } from './features/app/appActions';
import { selectMaintenanceMode, selectReadOnly } from './features/app/appState';
import { selectIsUpdateAvailable, selectIsUpdateRequired } from './features/update/updateState';
import {
  fetchLatestLocation,
  saveLocation,
  setLocation
} from './features/navigation/navigationActions';
import { LOCAL_ID, selectAllBranchesInfos } from './features/contents/contentsState';
import { addLocalFolder } from './features/library/libraryActions';
import { selectCheckout } from './features/cart/cartState';
import { doCheckout } from './features/cart/cartActions';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    root: {
      display: 'flex'
    },
    dialog: {
      display: 'flex',
      flexGrow: 1,
      zIndex: 1
    },
    paddleCheckout: {
      zIndex: 5000,
      position: 'fixed',
      right: 0,
      bottom: 0,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      margin: 0,
      border: 0
    },
    paddleCheckoutLoading: {
      padding: '16px'
    },
    buttonProgressWrapper: {
      position: 'relative'
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
    contents: {
      width: '100%',
      minWidth: 0 // This is required so that contents deep down works correctly with flex/overflow combos
    }
  })
);

function Bazaar() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useCustomSnackbar();

  const dispatch: AppDispatch = useDispatch();
  const authToken = useSelector(selectAuthToken);
  const refreshToken = useSelector(selectRefreshToken);
  const tokenRefreshing = useSelector(selectIsTokenRefreshing);
  const currentLocation = useSelector(selectCurrentLocation);
  const currentPathNames = useSelector(selectCurrentPathNames);
  const currentContent = useSelector(selectCurrentContent);
  const currentVendor = useSelector(selectCurrentVendor);
  const updateIsAvailable = useSelector(selectIsUpdateAvailable);
  const updateIsRequired = useSelector(selectIsUpdateRequired);
  const checkout = useSelector(selectCheckout);
  const readOnly = useSelector(selectReadOnly);
  const maintenanceMode = useSelector(selectMaintenanceMode);
  const allBranches = useSelector(selectAllBranchesInfos);

  const isCurrentPathMarket = isPathMarket(currentLocation.branchPath);
  const isCurrentPathLocal = isPathLocal(currentLocation.branchPath);

  const localBranch = allBranches[LOCAL_ID];

  // Terms

  const [showTerms, setShowTerms] = React.useState(false);

  const onShowTerms = React.useCallback(() => {
    setShowTerms(true);
  }, []);

  const terms = useSelector(selectTermsContents);
  const termsAcceptances = useSelector(selectTermsAcceptance);

  const userMustAcceptTerms = terms && termsAcceptances && !termsAcceptances.includes(terms.id);

  // Navigation drawer

  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const [drawerWidth, resizeDrawer] = React.useState(280);

  const onToggleMobileDrawer = React.useCallback(() => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  }, [mobileDrawerOpen]);

  // Update overlay

  const [updateOverlayOpen, toggleUpdateOverlay] = React.useState(false);

  // Account overlay

  const [accountOverlayOpen, toggleAccountOverlay] = React.useState(false);
  const [accountOverlayMessage, setAccountOverlayMessage] = React.useState<{
    type: AccountOverlayMessageType;
    text: string;
  }>();

  const accountUsernameRef = React.useRef<HTMLInputElement>(null); // Ref to focus on the field

  const openAccountOverlay = React.useCallback(() => {
    toggleAccountOverlay(true);

    // Focus the username field for convenience
    setTimeout(() => accountUsernameRef.current?.focus(), 0);
  }, []);

  // Cart overlay

  const [cartOverlayOpen, setCartOverlayOpen] = React.useState(false);

  const openCartOverlay = React.useCallback(() => {
    setCartOverlayOpen(true);
  }, []);

  // Bundle download overlay

  const [bundleDownloadOverlay, setBundleDownloadOverlay] = React.useState<string>();

  // Startup the app

  React.useEffect(() => {
    const start = async () => {
      dispatchAndNotify(startup(history), enqueueSnackbar);

      // Extension: try to restore the latest location

      if (isExtension()) {
        const locationResult = await dispatchAndNotify(fetchLatestLocation(), enqueueSnackbar);

        if (locationResult.fulfilled && locationResult.data) {
          history.push(locationResult.data);
        }
      }

      // Web: start on the marketplace if not specified otherwise
      else if (history.location.pathname === '/') {
        history.push('market');
      }
    };

    start();
  }, [history, enqueueSnackbar]);

  // Sync the app state when the location changes (branch path, content path, vendor, keywords, filters, sorting)

  React.useEffect(() => {
    // Current location

    const {
      branchPath,
      contentPath,
      searchKeywords,
      searchFilters,
      sortCriteria,
      vendorId
    } = extractLocationString(location);

    dispatchAndNotify(
      setLocation({
        branchPath,
        contentPath,
        searchKeywords,
        searchFilters,
        sortCriteria,
        vendorId
      }),
      enqueueSnackbar
    );

    // Extension: save the location

    if (isExtension()) {
      dispatchAndNotify(saveLocation(location.pathname), enqueueSnackbar);
    }
  }, [location, dispatch, enqueueSnackbar]);

  // Show a notification when a new version is available

  React.useEffect(() => {
    if (updateIsAvailable && !updateIsRequired) {
      // Do not show a notification if the update is mandatory
      // (there's already a forced overlay that will appear)
      enqueueSnackbar(`A new version is available`, 'info', {
        action: (key) => (
          <>
            <MUI.Button
              variant='text'
              style={{ color: 'white' }}
              onClick={() => {
                closeSnackbar(key);
                toggleUpdateOverlay(true);
              }}
            >
              Install
            </MUI.Button>
            <MUI.Button
              variant='text'
              style={{ color: 'white' }}
              onClick={() => closeSnackbar(key)}
            >
              Later
            </MUI.Button>
          </>
        ),
        persist: true
      });
    }
  }, [updateIsAvailable, updateIsRequired, enqueueSnackbar, closeSnackbar]);

  // Refresh the authentication token when it's close to expiration

  useInterval(() => {
    if (authToken && refreshToken && !tokenRefreshing) {
      const exp = extractJWTData(authToken).exp;

      const now = Math.floor(Date.now() / 1000);
      const remainingTime = exp - now;

      // 2 minutes before expiry
      if (remainingTime < 120) {
        dispatchAndNotify(refreshAuthToken(remainingTime * 1000), enqueueSnackbar);
      }
    }
  }, 10_000);

  // Analytics: record page changes

  const { page } = useAnalytics();

  React.useEffect(() => {
    page({ path: location.pathname + location.search });
  }, [page, location.pathname, location.search]);

  // Update the page title to match the current path

  React.useEffect(() => {
    let title = `3D Bazaar by Lindalë ${isExtension() ? '(Extension)' : ''}`; // Add a suffix for the extension (useful for analytics)

    // Current path
    title += ` - ${currentPathNames
      .map((name, i) => name ?? currentLocation.branchPath[i])
      .join(' / ')}`;

    // Additional info (content, vendor)

    if (currentContent?.type === 'marketcontent' || currentContent?.type === 'localcontent') {
      title += ` / ${currentContent.name} by ${currentContent.vendor?.name}`;
    } else if (currentVendor) {
      title += ` / ${currentVendor.username}`;
    }

    document.title = title;
  }, [currentPathNames, currentContent, currentVendor, currentLocation.branchPath]);

  // Local

  const onAddLocalFolder = React.useCallback(() => {
    dispatchAndNotify(addLocalFolder(), enqueueSnackbar);
  }, [enqueueSnackbar]);

  // PURCHASE

  const onCheckoutCart = () => {
    // Open the account overlay if not logged in
    if (authToken === undefined) {
      setAccountOverlayMessage({ type: 'info', text: 'You need to log in before purchasing' });
      openAccountOverlay();
    }

    // Otherwise, start the checkout
    else {
      dispatchAndNotify(doCheckout(), enqueueSnackbar);
    }
  };

  // Paddle checkout

  const previousCheckoutStatus = usePrevious(checkout?.status);
  const [checkoutValidationNotifKey, setCheckoutValidationNotifKey] = useState<SnackbarKey>();

  React.useEffect(() => {
    // Show a "Validating..." notification when switching to the validation status.
    // Note: would be cleaner with a state machine?
    if (previousCheckoutStatus === 'inprogress' && checkout?.status === 'validating') {
      const notifKey = enqueueSnackbar('Validating purchase...', 'info', {
        persist: true,
        action: (key) => (
          <React.Fragment>
            <MUI.CircularProgress style={{ color: 'white' }} size={20} />
          </React.Fragment>
        )
      });
      setCheckoutValidationNotifKey(notifKey);

      setCartOverlayOpen(false);
    }
    // Replace with a "Success" notification when completing the checkout
    else if (previousCheckoutStatus === 'validating' && checkout === undefined) {
      closeSnackbar(checkoutValidationNotifKey);
      enqueueSnackbar('Purchase successful!', 'success');
    }
  }, [
    checkout,
    previousCheckoutStatus,
    checkoutValidationNotifKey,
    enqueueSnackbar,
    closeSnackbar
  ]);

  // Generate the current branch content

  let currentBranchContent = (
    <BranchContent
      onAddLocalFolder={onAddLocalFolder}
      onOpenCart={openCartOverlay}
      onOpenBundleDownloadDetails={(id) => setBundleDownloadOverlay(id)}
    />
  );

  // Marketplace: maintenance mode

  if (isCurrentPathMarket && maintenanceMode) {
    currentBranchContent = (
      <Maintenance
        onClickLocal={() =>
          history.push(createLocationString({ ...currentLocation, branchPath: [LOCAL_ID] }))
        }
      />
    );
  }

  // Local
  else if (isCurrentPathLocal) {
    // If we're not in local mode, display an 'ad' inviting the user to install the SketchUp extension

    if (!isExtension()) {
      currentBranchContent = <LocalLibraryAd />;
    }

    // If we're in local mode and there are no root folders, display a button to add a folder
    else if (!readOnly && localBranch && localBranch.childrenIds.length === 0) {
      currentBranchContent = (
        <MUI.Box
          position='fixed'
          top={theme.headerHeight}
          bottom={0}
          right={0}
          left={drawerWidth}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <MUI.Box>
            <MUI.Button
              variant='outlined'
              size='large'
              color='primary'
              startIcon={<Icon icon='mdi-folder-plus-outline' />}
              onClick={onAddLocalFolder}
            >
              Add Folder
            </MUI.Button>
          </MUI.Box>
        </MUI.Box>
      );
    }
  }

  return (
    <div className={classes.root}>
      {currentContent && (
        <ContentOverlay
          key={currentContent.id} // Use a unique key to ensure that successive content overlays (eg. bundle -> asset) do not mixup their states
          contentId={currentContent.id}
          onOpenCart={openCartOverlay}
          onOpenBundleDownloadDetails={setBundleDownloadOverlay}
          onClose={() =>
            history.push(createLocationString({ ...currentLocation, contentPath: [] }))
          }
        />
      )}

      {bundleDownloadOverlay && (
        <BundleDownloadOverlay
          contentId={bundleDownloadOverlay}
          onClose={() => setBundleDownloadOverlay(undefined)}
        />
      )}

      {checkout?.status === 'loading' && (
        <Dialog open classes={{ paper: classes.paddleCheckoutLoading }}>
          Loading...
        </Dialog>
      )}

      {/* Web version: load the Paddle UI when a checkout URL has been fetched */}
      {!isExtension() && checkout?.url && (
        <MUI.Modal open>
          <iframe
            src={
              'https://licensing.lindale.io/paddle_checkout_iframe_container.php?checkout_url=' +
              checkout.url
            }
            className={classes.paddleCheckout}
            title='Paddle Checkout'
          />
        </MUI.Modal>
      )}

      {(updateOverlayOpen || updateIsRequired) && (
        <UpdateOverlay onClose={() => toggleUpdateOverlay(false)} />
      )}

      {accountOverlayOpen && (
        <AccountOverlay
          message={accountOverlayMessage}
          usernameFieldRef={accountUsernameRef}
          onClose={() => {
            toggleAccountOverlay(false);
            setAccountOverlayMessage(undefined);
          }}
        />
      )}

      {cartOverlayOpen && (
        <CartOverlay onCheckoutCart={onCheckoutCart} onClose={() => setCartOverlayOpen(false)} />
      )}

      {(showTerms || userMustAcceptTerms) && <TermsDialog onClose={() => setShowTerms(false)} />}

      <NavigationDrawer
        width={drawerWidth}
        mobileDrawerOpen={mobileDrawerOpen}
        onResize={resizeDrawer}
        onAddLocalFolder={onAddLocalFolder}
        onMobileDrawerToggle={onToggleMobileDrawer}
        onOpenAccountOverlay={openAccountOverlay}
        onShowTerms={onShowTerms}
      />

      <Header
        drawerWidth={drawerWidth}
        logoWidth={drawerWidth}
        onMobileDrawerToggle={onToggleMobileDrawer}
        onOpenUpdateOverlay={updateIsAvailable ? () => toggleUpdateOverlay(true) : undefined}
        onOpenCartOverlay={openCartOverlay}
      />

      <div className={classes.contents}>
        <MUI.Toolbar />
        {currentBranchContent}
      </div>
    </div>
  );
}

// Setup analytics

const analytics = Analytics({
  app: '3dbazaar',
  plugins: [
    googleAnalytics({
      trackingId: 'UA-186554876-1',

      // For the local extension to collect data too (uses file:// protocol)
      // https://github.com/DavidWells/analytics/issues/77#issuecomment-674327142
      tasks: {
        checkProtocolTask: null,
        checkStorageTask: null,
        historyImportTask: null
      }
    })
  ]
});

// Snackbars use the "modern" CSS attribute margin-inline-end to tune spacing
// between the icon and the text in notifications but it's not supported by
// older browsers (hi, SketchUp HTMLDialog) so we use variants with the same
// icons but a backward compatible spacing attribute.

const snackbarIconStyle = { marginRight: '8px' };

const snackbarIconVariants = {
  success: <MUIIcons.CheckCircle style={snackbarIconStyle} />,
  info: <MUIIcons.Info style={snackbarIconStyle} />,
  error: <MUIIcons.Cancel style={snackbarIconStyle} />,
  warning: <MUIIcons.Warning style={snackbarIconStyle} />
};

// Wrapper extravanganza

const WrappedBazaar = () => (
  <AnalyticsProvider instance={analytics}>
    <MUI.ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={5} iconVariant={snackbarIconVariants}>
        {/* SnackbarProvider must be a child of MuiThemeProvider */}
        <ReduxProvider store={store}>
          <Bazaar />
        </ReduxProvider>
      </SnackbarProvider>
    </MUI.ThemeProvider>
  </AnalyticsProvider>
);

export default function RoutedBazaar() {
  // React Router's BrowserRouter doesn't work with local files due to CORS restrictions
  // so in local mode, we need to use HashRouter (it doesn't matter as the user doesn't see the url)
  return isExtension() ? (
    <HashRouter>
      <WrappedBazaar />
    </HashRouter>
  ) : (
    <BrowserRouter>
      <WrappedBazaar />
    </BrowserRouter>
  );
}
