import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import _ from 'lodash';
import * as zod from 'zod';

import { ActionError, RootState, toActionError } from '../../store';
import { isExtension, nullToUndefined, sketchupAsync, sketchupSchema } from '../../utils';
import { fetchMarketplaceContents, searchContentsInPath } from '../contents/contentsActions';
import {
  isPathLocal,
  isPathMarket,
  NavigationState,
  SearchFilters,
  SortCriteria
} from './navigationState';

export const updateBranchPath = createAction<string[]>('navigation/updateBranchPath');
export const updateContentPath = createAction<string[]>('navigation/updateContentPath');
export const updateSearchKeywords = createAction<string[]>('navigation/updateSearchKeywords');
export const updateSearchFilters = createAction<SearchFilters>('navigation/updateSearchFilters');
export const updateSortCriteria = createAction<SortCriteria>('navigation/updateSortCriteria');
export const updateVendor = createAction<string | undefined>('navigation/updateVendor');

// Sets the location
//
// The components of a location (branch path, content path, vendor) are driven by the
// page location stored in the URL. So the setLocation action has to be triggered when
// the URL updates so that the Redux state stays in sync.
export const setLocation = createAsyncThunk<
  void,
  NavigationState,
  { state: RootState; rejectValue: ActionError }
>('navigation/setLocation', async (newLocation, thunkAPI) => {
  try {
    // Avoid unnecessary actions when nothing changed

    const curLocation = thunkAPI.getState().navigation;

    const branchChanged = !_.isEqual(newLocation.branchPath, curLocation.branchPath);
    const contentChanged = !_.isEqual(newLocation.contentPath, curLocation.contentPath);
    const keywordsChanged = !_.isEqual(newLocation.searchKeywords, curLocation.searchKeywords);
    const filtersChanged = !_.isEqual(newLocation.searchFilters, curLocation.searchFilters);
    const sortCriteriaChanged = newLocation.sortCriteria !== curLocation.sortCriteria;
    const vendorChanged = newLocation.vendorId !== curLocation.vendorId;

    if (branchChanged) {
      thunkAPI.dispatch(updateBranchPath(newLocation.branchPath));
    }

    if (contentChanged) {
      thunkAPI.dispatch(updateContentPath(newLocation.contentPath));

      // Fetch the marketplace content if it's not loaded yet

      const contentId =
        newLocation.contentPath.length > 0
          ? newLocation.contentPath[newLocation.contentPath.length - 1]
          : undefined;

      if (
        contentId &&
        !thunkAPI.getState().contents.contents[contentId] &&
        isPathMarket(thunkAPI.getState().navigation.branchPath) // TODO possible bug if opening marketplace content in local path?
      ) {
        thunkAPI.dispatch(fetchMarketplaceContents([contentId]));
      }
    }

    if (keywordsChanged) {
      thunkAPI.dispatch(updateSearchKeywords(newLocation.searchKeywords));
    }

    if (filtersChanged) {
      thunkAPI.dispatch(updateSearchFilters(newLocation.searchFilters));
    }

    if (sortCriteriaChanged) {
      thunkAPI.dispatch(updateSortCriteria(newLocation.sortCriteria));
    }

    if (vendorChanged) {
      thunkAPI.dispatch(updateVendor(newLocation.vendorId));
    }

    if (
      (branchChanged ||
        keywordsChanged ||
        filtersChanged ||
        sortCriteriaChanged ||
        vendorChanged) &&
      // Do not fetch contents when going to the local branch in the web version (this is just an ad for the extension)
      (isExtension() || !isPathLocal(thunkAPI.getState().navigation.branchPath))
    ) {
      await thunkAPI
        .dispatch(
          searchContentsInPath({
            location: thunkAPI.getState().navigation,
            append: false
          })
        )
        .then(unwrapResult);
    }
  } catch (error) {
    console.log(error, 456);
    return thunkAPI.rejectWithValue(toActionError('set location', error));
  }
});

export const saveLocation = createAsyncThunk<
  void,
  string,
  { state: RootState; rejectValue: ActionError }
>('navigation/saveLocation', async (url, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot save the location out of the extension');
    }

    const schema = sketchupSchema(zod.null());

    const response = await sketchupAsync({
      methodName: 'saveLocation',
      args: [url],
      schema
    });

    if (!response.success) {
      throw response.errors;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('Save location', error));
  }
});

export const fetchLatestLocation = createAsyncThunk<
  string | undefined,
  void,
  { state: RootState; rejectValue: ActionError }
>('navigation/fetchLatestLocation', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot fetch the latest location out of the extension');
    }

    const schema = sketchupSchema(zod.string().nullable());

    const response = await sketchupAsync({
      methodName: 'getLatestLocation',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    return nullToUndefined(response.data);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch latest location', error));
  }
});
