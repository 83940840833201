import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import * as zod from 'zod';

import { RootState, collectAllPromiseErrors, ActionError, toActionError } from '../../store';
import {
  jsendSchema,
  webApiAsync,
  isExtension,
  sketchupSchema,
  sketchupAsync,
  nullToUndefined,
  getCookie,
  setCookie,
  deleteCookie,
  extractJsendError
} from '../../utils';
import { syncCartContents } from '../cart/cartActions';
import { fetchPurchasedContentIds } from '../contents/contentsActions';
import { fetchTerms } from '../terms/termsActions';

type LoginResult =
  | {
      // If successful
      success: true;
      authToken?: string;
      refreshToken?: string;
    }
  | {
      // If failed
      success: false;
      message?: string;
      email?: string;
      password?: string;
    };

// We split the raw login (auth and nothing else) from the master login (login + related data fetching)
export const loginAuth = createAsyncThunk<
  LoginResult,
  {
    email: string;
    password: string;
  },
  { state: RootState; rejectValue: ActionError }
>('auth/loginAuth', async ({ email, password }, thunkAPI) => {
  try {
    const schema = jsendSchema(
      {
        jwt: zod.string(),
        refreshToken: zod.string()
      },
      {
        message: zod.string().optional(),
        email: zod.string().optional(),
        password: zod.string().optional()
      }
    );

    const response = await webApiAsync({
      method: 'post',
      endpoint: 'auth/v1/login',
      data: {
        email,
        password,
        return: isExtension() ? 'bazaarextension' : encodeURI(window.location.href)
      },
      validStatuses: [400, 401],
      schema
    });

    // If the login failed (status = 400/401), we still produce a fulfilled promise to
    // simplify things (the user failing to log in is not an exception!).
    //
    // An alternative would be to reject a special type containing per-field errors but then
    // it makes error handling messy in the calling code (we would have to ask ourselves if a
    // rejection is a true error or a user error). Been there, done that.

    if (
      response.data.status === 'fail' &&
      (response.status === 400 || // Validation error
        response.status === 401) // Data error
    ) {
      return {
        success: false,
        message: response.data?.data?.message,
        email: response.data?.data?.email,
        password: response.data?.data?.password
      };
    }

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    const data = response.data.data;
    const authToken = data.jwt;
    const refreshToken = data.refreshToken;

    return {
      success: true,
      authToken,
      refreshToken
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('login (auth)', error));
  }
});

export const login = createAsyncThunk<
  LoginResult,
  {
    email: string;
    password: string;
  },
  { state: RootState; rejectValue: ActionError }
>('auth/login', async ({ email, password }, thunkAPI) => {
  try {
    // Do the actual login

    const loginResult = await thunkAPI.dispatch(loginAuth({ email, password })).then(unwrapResult);

    if (!loginResult.success) {
      return loginResult;
    }

    // If logged in, fetch all the user's data

    await thunkAPI.dispatch(saveRefreshToken(loginResult.refreshToken)).then(unwrapResult);

    const setupActions = Promise.all([
      thunkAPI.dispatch(fetchTerms()),
      thunkAPI.dispatch(fetchPurchasedContentIds()),
      thunkAPI.dispatch(syncCartContents())
    ]);

    const errors = collectAllPromiseErrors(await setupActions);

    if (errors.length > 0) {
      throw errors;
    }

    return loginResult;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('login', error));
  }
});

export const logout = createAsyncThunk<
  boolean,
  void,
  { state: RootState; rejectValue: ActionError }
>('auth/logout', async (_, thunkAPI) => {
  try {
    const refreshToken = thunkAPI.getState().auth.refreshToken;

    if (refreshToken === undefined) {
      throw new Error('Not logged in');
    }

    // Forget about the authentication

    await thunkAPI.dispatch(saveRefreshToken(undefined)).then(unwrapResult);

    // Log out

    const schema = jsendSchema({}, {});

    const response = await webApiAsync({
      method: 'post',
      endpoint: 'auth/v1/logout',
      jwt: thunkAPI.getState().auth.authToken,
      data: { refreshToken },
      schema
    });

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    return true;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('logout', error));
  }
});

export const register = createAsyncThunk<
  | {
      // If successful
      success: true;
      message?: string;
    }
  | {
      // If failed
      success: false;
      message?: string;
      username?: string;
      email?: string;
      password?: string;
    },
  {
    email: string;
    username: string;
    password: string;
  },
  { state: RootState; rejectValue: ActionError }
>('auth/register', async ({ email, username, password }, thunkAPI) => {
  try {
    const schema = jsendSchema(
      {
        message: zod.string().optional()
      },
      {
        message: zod.string().optional(),
        username: zod.string().optional(),
        email: zod.string().optional(),
        password: zod.string().optional()
      }
    );

    const response = await webApiAsync({
      method: 'post',
      endpoint: 'auth/v1/register',
      data: {
        email,
        username,
        password,
        return: isExtension() ? 'bazaarextension' : encodeURI(window.location.href)
      },
      validStatuses: [400, 401, 409],
      schema
    });

    // Same logic as login

    if (
      response.data.status === 'fail' &&
      (response.status === 400 || // Validation error
        response.status === 401 || // Data error
        response.status === 409) // Already used error
    ) {
      return {
        success: false,
        message: response.data.data.message,
        username: response.data.data.username,
        email: response.data.data.email,
        password: response.data.data.password
      };
    }

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    return {
      success: true,
      message: response.data.data.message
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('register', error));
  }
});

export const fetchAuthToken = createAsyncThunk<
  string | undefined,
  void,
  { state: RootState; rejectValue: ActionError }
>('auth/fetchAuthToken', async (_, thunkAPI) => {
  try {
    // Extension: load from options

    if (isExtension()) {
      const schema = sketchupSchema(zod.string().nullable());

      const response = await sketchupAsync({
        methodName: 'getRefreshToken',
        schema
      });

      if (!response.success) {
        throw response.errors;
      }

      return nullToUndefined(response.data);
    }

    // Web: from cookies
    else {
      return nullToUndefined(getCookie('refreshToken'));
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch auth token', error));
  }
});

const saveRefreshToken = createAsyncThunk<
  void,
  string | undefined,
  { state: RootState; rejectValue: ActionError }
>('auth/saveRefreshToken', async (token, thunkAPI) => {
  try {
    if (isExtension()) {
      const schema = sketchupSchema(zod.null());

      const response = await sketchupAsync({
        methodName: 'saveRefreshToken',
        args: [token],
        schema
      });

      if (!response.success) {
        throw response.errors;
      }
    } else {
      if (token) {
        setCookie('refreshToken', token, true);
      } else {
        deleteCookie('refreshToken');
      }
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('save refresh token', error));
  }
});

export const refreshAuthToken = createAsyncThunk<
  {
    authToken?: string;
    refreshToken?: string;
  },
  // Timeout before which retries are allowed to
  // better support possible network errors
  number | undefined,
  { state: RootState; rejectValue: ActionError }
>('auth/refreshAuthToken', async (timeout, thunkAPI) => {
  const refreshToken = thunkAPI.getState().auth.refreshToken;

  try {
    const schema = jsendSchema(
      {
        jwt: zod.string(),
        refreshToken: zod.string()
      },
      {
        message: zod.string()
      }
    );

    // Schedule retries if a timeout has been provided

    const delay = 10_000;
    const retry = timeout
      ? {
          times: Math.floor(timeout / delay),
          delay
        }
      : undefined;

    const response = await webApiAsync({
      method: 'post',
      endpoint: 'auth/v1/refreshtoken',
      data: { refreshToken },
      schema,
      validStatuses: [401],
      retry
    });

    // 401 = invalid token
    if (response.status === 401) {
      // Forget about this invalid token
      thunkAPI.dispatch(saveRefreshToken(undefined)).then(unwrapResult);

      return { authToken: undefined, refreshToken: undefined };
    }

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    const data = response.data.data;

    await thunkAPI.dispatch(saveRefreshToken(data.refreshToken)).then(unwrapResult);

    return { authToken: data.jwt, refreshToken: data.refreshToken };
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('refresh auth', error));
  }
});
