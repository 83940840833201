import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { extname } from 'path';
import * as zod from 'zod';

import { ActionError, AppDispatch, RootState, toActionError } from '../../store';
import { sketchupSchema, sketchupAsync, nullToUndefined, UMap, isExtension } from '../../utils';
import { searchContentsInPath } from '../contents/contentsActions';
import { LOCAL_ID, PAGINATION_SIZE } from '../contents/contentsState';
import {
  BranchInfo,
  Content,
  LocalFile,
  LocalFileSchema,
  localFilesToBranchContents,
  LocalFolderSchema,
  localFoldersToBranches
} from '../contents/contentsTypes';
import {
  getBranchId,
  isPathMarket,
  NavigationState,
  isSearchFiltered
} from '../navigation/navigationState';

export const updateLocalFolders = createAction<{ rootIds: string[]; branches: UMap<BranchInfo> }>(
  'library/updateLocalFolders'
);

// Intervals for the live update

const WATCH_INTERVAL = 5000;

let allFoldersWatchInterval: ReturnType<typeof setInterval> | undefined;
let currentFolderWatchInterval: ReturnType<typeof setInterval> | undefined;

function startWatchingFolders(dispatch: AppDispatch, getState: () => RootState) {
  if (allFoldersWatchInterval !== undefined) {
    return;
  }

  console.log('Start watching folders');

  allFoldersWatchInterval = setInterval(() => {
    // // Auto stop when there are no local folders anymore

    const localFolders = getState().contents.branchesInfo[LOCAL_ID]?.childrenIds ?? [];

    if (allFoldersWatchInterval !== undefined && localFolders.length === 0) {
      console.log('Stop watching folders');

      clearInterval(allFoldersWatchInterval);
      allFoldersWatchInterval = undefined;

      return;
    }

    dispatch(checkLocalFolderChanges());
  }, WATCH_INTERVAL);
}

function startWatchingSearchResults(dispatch: AppDispatch, getState: () => RootState) {
  if (currentFolderWatchInterval !== undefined) {
    return;
  }

  console.log('Start watching search results');

  currentFolderWatchInterval = setInterval(() => {
    // Auto stop when the current path is not local anymore

    const location = getState().navigation;

    if (
      currentFolderWatchInterval !== undefined &&
      (location.branchPath.length === 0 || location.branchPath[0] !== LOCAL_ID)
    ) {
      console.log('Stop watching search results');

      clearInterval(currentFolderWatchInterval);
      currentFolderWatchInterval = undefined;

      return;
    }

    dispatch(searchContentsInPath({ location, append: false, silent: true }));
  }, WATCH_INTERVAL);
}

// Adds a local folder

export const addLocalFolder = createAsyncThunk<
  string | undefined, // Added folder if successful
  string | undefined, // Folder to add, will ask the user if not provided
  { state: RootState; rejectValue: ActionError }
>('contents/addLocalFolder', async (folder, thunkAPI) => {
  try {
    // Add a new folder

    const schema = sketchupSchema(
      zod.string().nullable() // null if no file was selected
    );

    const response = await sketchupAsync({
      methodName: 'addLocalFolder',
      args: [folder],
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    // Start watching for folder changes

    if (response.data) {
      startWatchingFolders(thunkAPI.dispatch, thunkAPI.getState);
    }

    return nullToUndefined(response.data);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('add local folder', error));
  }
});

// Removes a local folder

export const removeLocalFolder = createAsyncThunk<
  void,
  { branchId: string },
  { state: RootState; rejectValue: ActionError }
>('contents/removeLocalFolder', async ({ branchId }, thunkAPI) => {
  try {
    // Remove the folder

    const schema = sketchupSchema(zod.null().optional());

    const response = await sketchupAsync({
      methodName: 'removeLocalFolder',
      args: [Base64.decode(branchId)],
      schema
    });

    if (!response.success) {
      throw response.errors;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('remove local folder', error));
  }
});

// Fetches the local folders

const foldersSchema = zod.object({
  roots: zod.array(zod.string()),
  folders: zod.record(LocalFolderSchema)
});

export const fetchLocalFolders = createAsyncThunk<
  { rootIds: string[]; branches: UMap<BranchInfo> },
  void,
  { state: RootState; rejectValue: ActionError }
>('contents/fetchLocalFolders', async (_, thunkAPI) => {
  try {
    const schema = sketchupSchema(
      zod.string() // JSON
    );

    const response = await sketchupAsync({
      methodName: 'getLocalFolders',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    const foldersData = foldersSchema.parse(JSON.parse(response.data));

    // Start watching for folder changes

    if (foldersData.roots.length > 0) {
      startWatchingFolders(thunkAPI.dispatch, thunkAPI.getState);
    }

    return localFoldersToBranches(foldersData.roots, foldersData.folders);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch local folder', error));
  }
});

// Checks local folders for changes

export const checkLocalFolderChanges = createAsyncThunk<
  void,
  void,
  { state: RootState; rejectValue: ActionError }
>('contents/checkLocalFolderChanges', async (_, thunkAPI) => {
  try {
    const schema = sketchupSchema(
      zod.object({
        folders: zod.string().optional()
      })
    );

    const response = await sketchupAsync({
      methodName: 'checkLocalFolderChanges',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    if (response.data.folders) {
      const foldersData = foldersSchema.parse(JSON.parse(response.data.folders));

      const branches = localFoldersToBranches(foldersData.roots, foldersData.folders);

      thunkAPI.dispatch(updateLocalFolders(branches));
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('check local folder', error));
  }
});

// Fetches the local folders contents

export const searchLocalFolderContents = createAsyncThunk<
  {
    contents: Content[];
    complete: boolean;
  },
  {
    location: NavigationState;
    append: boolean;
  },
  { state: RootState; rejectValue: ActionError }
>('contents/searchLocalFolderContents', async ({ location, append }, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('Cannot fetch local folders out of the extension');
    }

    if (isPathMarket(location.branchPath)) {
      throw new Error('Cannot fetch local contents out of local path');
    }

    const filtered = isSearchFiltered(location);

    // If we're just fetching an unfiltered category that is already loaded, just return it
    // TODO?
    /*const branchId = getBranchId(branchPath);
        const existingContents = getBranchContents(branchId, thunkAPI.getState());

        if (!silent && !append && !filtered && existingContents.length > 0)
        {
            const complete = thunkAPI.getState().contents.branchesContents[branchId]?.contentComplete ?? true

            return {
                contents: existingContents,
                complete
            };
        }*/

    // Setup the search

    const branchId = getBranchId(location.branchPath);
    const branchContents = thunkAPI.getState().contents.branchesContents[branchId];

    const currentContentCount =
      (filtered
        ? branchContents?.filtered.contentIds.length
        : branchContents?.all.contentIds.length) ?? 0;

    const offset = append ? currentContentCount : 0;

    const count = append
      ? PAGINATION_SIZE + 1 // Fetch PAGINATION_SIZE more (+1 for the completion check)
      : Math.max(currentContentCount, PAGINATION_SIZE) + 1; // Fetch as much as is already loaded for simple updates

    const folder =
      location.branchPath.length > 1 // Ignore the first level ("local")
        ? Base64.decode(branchId)
        : undefined;

    console.log(
      `Search in local folder ${folder}, keywords="${location.searchKeywords}", offset=${offset}, count=${count}, sort=${location.sortCriteria}, group=${location.searchFilters.groupPackagesIntoMarketplaceContent}`
    );

    const schema = sketchupSchema(
      zod.string() // JSON
    );

    const response = await sketchupAsync({
      methodName: 'searchLocalFiles',
      args: [
        folder,
        location.searchKeywords,
        offset,
        count,
        location.sortCriteria,
        location.searchFilters.groupPackagesIntoMarketplaceContent
      ],
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    // Start watching for changes in the results
    startWatchingSearchResults(thunkAPI.dispatch, thunkAPI.getState);

    // Convert to LocalFiles

    const filesData = JSON.parse(response.data);

    if (!Array.isArray(filesData)) {
      throw new Error('File search result is not an array');
    }

    const typedFilesData: LocalFile[] = filesData.map((fileData) => {
      const typedFileData = {
        ...fileData,

        // We must attach a "type" field that depends on the path's extension
        // in order to benefit from TS union discrimination.
        //
        // The core doesn't provide this type as it would be redundant with the path.
        type: extname(fileData.path).slice(1) // remove dot
      };

      return LocalFileSchema.parse(typedFileData);
    });

    const contents = localFilesToBranchContents(typedFilesData);

    return {
      // We queried PAGINATION_SIZE + 1 items to determine if there are still other
      // items to load after this call but we only use PAGINATION_SIZE of them
      contents: contents.slice(0, Math.min(contents.length, count - 1)),
      complete: contents.length < count
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('search local contents', error));
  }
});
