import React from 'react';
import { useSelector } from 'react-redux';
import * as MUI from '@material-ui/core';

import Icon from '../lindaleui/components/Icon';

import Dialog from './Dialog';
import { dispatchAndNotify } from '../store';
import { formatPrice, useCustomSnackbar } from '../utils';
import { removeContentFromCart } from '../features/cart/cartActions';
import { selectCartContents } from '../features/cart/cartState';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    dialog: {
      padding: theme.spacing(2)
    },
    grid: {
      flexWrap: 'nowrap',
      height: '100%'
    },
    cartIcon: {
      backgroundColor: theme.palette.primary.main,
      marginRight: theme.spacing(1)
    },
    itemCount: {
      color: theme.palette.primary.main
    },
    itemList: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      maxHeight: '60vh',
      overflowY: 'auto',
      flexWrap: 'nowrap'
    },
    item: {
      paddingBottom: theme.spacing(1),
      '&:last-child': {
        paddingBottom: 0
      }
    },
    empty: {
      marginTop: '2rem',
      fontStyle: 'italic',
      color: 'gray'
    }
  })
);

interface Props {
  onCheckoutCart: () => void;
  onClose: () => void;
}

export function CartOverlay(props: Props) {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useCustomSnackbar();

  const cartContents = useSelector(selectCartContents);

  // Render

  const totalPrice = cartContents.reduce((total, content) => total + content.price, 0);

  const items = cartContents.map((item) => {
    const image = item.images[0]?.urls['64'];

    const itemInfo = (
      <MUI.Grid container item direction='row' alignItems='center'>
        <MUI.Grid item>
          <MUI.Avatar src={image} style={{ marginRight: '1rem' }} />
        </MUI.Grid>

        <MUI.Grid item>
          <MUI.Grid container item direction='column'>
            <MUI.Typography variant='subtitle2'>{item.name}</MUI.Typography>
            <MUI.Typography variant='caption'>
              {formatPrice(item.price, item.currency)}
            </MUI.Typography>
          </MUI.Grid>
        </MUI.Grid>
      </MUI.Grid>
    );

    const onRemoveContentFromCart = async () => {
      dispatchAndNotify(removeContentFromCart(item), enqueueSnackbar);
    };

    const deleteButton = (
      <MUI.Grid item>
        <MUI.Tooltip title='Remove from cart'>
          <MUI.IconButton className='remove-button' onClick={onRemoveContentFromCart}>
            <Icon icon='mdi-delete-outline' color='lightgrey' />
          </MUI.IconButton>
        </MUI.Tooltip>
      </MUI.Grid>
    );

    return (
      <MUI.Grid
        key={item.id}
        container
        direction='row'
        alignItems='center'
        classes={{ root: classes.item }}
        className='cart-item'
      >
        <MUI.Box flexGrow={1}>{itemInfo}</MUI.Box>

        {deleteButton}
      </MUI.Grid>
    );
  });

  return (
    <Dialog
      open
      onClose={props.onClose}
      fullWidth
      maxWidth='sm'
      className='cart-overlay'
      classes={{ paper: classes.dialog }}
    >
      <MUI.Grid container direction='column' classes={{ root: classes.grid }}>
        <MUI.Box mb={2}>
          <MUI.Grid container direction='row' justify='center' alignItems='center'>
            <MUI.Avatar classes={{ root: classes.cartIcon }}>
              <Icon icon='mdi-cart-outline' color='primary' />
            </MUI.Avatar>

            <MUI.Typography variant='h6' align='center' color='textSecondary'>
              Your cart contains <span className={classes.itemCount}>{cartContents.length}</span>{' '}
              items
            </MUI.Typography>
          </MUI.Grid>
        </MUI.Box>

        <MUI.Divider />

        {cartContents.length === 0 && (
          <MUI.Typography classes={{ root: classes.empty }} align='center'>
            Empty
          </MUI.Typography>
        )}

        <MUI.Grid container direction='column' classes={{ root: classes.itemList }}>
          {items}
        </MUI.Grid>

        <MUI.Box mb={2}>
          <MUI.Divider />
        </MUI.Box>

        <MUI.Button
          color='primary'
          variant='contained'
          style={{ whiteSpace: 'nowrap' }}
          fullWidth
          disabled={cartContents.length === 0}
          onClick={props.onCheckoutCart}
        >
          Purchase for {formatPrice(totalPrice, 'USD') /* TODO what if other currencies? */}
        </MUI.Button>
      </MUI.Grid>
    </Dialog>
  );
}
