import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { MarketContent } from '../contents/contentsTypes';

export interface Checkout {
  // URL for the Paddle checkout
  url?: string;

  // Loading = waiting for the checkout URL
  // In progress = checkout ongoing, via the Paddle UI
  // Validating = waiting for the cart assets to be considered purchased
  status: 'loading' | 'inprogress' | 'validating';
}

export interface CartState {
  // Contained items
  contentIds: string[];

  // Online ID of the cart, for checkout
  onlineId?: string;

  // Ongoing checkout
  checkout?: Checkout;
}

export const initialState: CartState = {
  contentIds: [],
  checkout: undefined
};

export const selectCartContentIds = (root: RootState) => root.cart.contentIds;

export const selectCartContents = createSelector(
  [(root: RootState) => root.cart.contentIds, (root: RootState) => root.contents.contents],
  (contentIds, allContents) =>
    contentIds
      .map((id) => allContents[id])
      .filter((c): c is MarketContent => c !== undefined && c.type === 'marketcontent') // Remove missing contents (undefined)
);

export const selectCheckout = (root: RootState) => root.cart.checkout;
