import { createSelector } from '@reduxjs/toolkit';
import * as zod from 'zod';

import { RootState } from '../../store';

// Version

export const VersionSchema = zod.object({
  versionNumber: zod.string(),
  required: zod.boolean(),
  downloadLink: zod.string(),
  description: zod.string(),
  date: zod.string(),
  changelog: zod.object({
    new: zod.array(zod.string()).optional(),
    changes: zod.array(zod.string()).optional(),
    fixes: zod.array(zod.string()).optional()
  })
});

export type Version = zod.infer<typeof VersionSchema>;

// State

export const UpdateStatusSchema = zod.enum([
  'downloading',
  'downloadFailed',
  'installing',
  'installFailed',
  'complete'
]);

export type UpdateStatus = zod.infer<typeof UpdateStatusSchema>;

interface UpdateState {
  newVersions: Version[];

  status?: UpdateStatus;
  progress: number;
}

export const initialState: UpdateState = {
  newVersions: [],

  status: undefined,
  progress: 0
};

export const selectNewVersions = (root: RootState) => root.update.newVersions;

export const selectIsUpdateAvailable = (root: RootState) => root.update.newVersions.length > 0;

export const selectIsUpdateRequired = createSelector(
  [(root: RootState) => root.update.newVersions],
  (versions) => versions.some((v) => v.required)
);

export const selectDownload = createSelector(
  [(root: RootState) => root.update.status, (root: RootState) => root.update.progress],
  (downloadStatus, downloadProgress) => ({ downloadStatus, downloadProgress })
);
