import React from 'react';
import * as MUI from '@material-ui/core';

import { PackageDownload } from '../features/download/downloadState';

interface Props {
  progress: PackageDownload;
}

function ContentDownloadProgress(props: Props) {
  return (
    <MUI.Box position='relative' display='inline-flex'>
      <MUI.CircularProgress
        size={24}
        variant={
          props.progress.status && props.progress.progress > 0.01 ? 'determinate' : 'indeterminate'
        }
        value={props.progress.progress * 100}
      />

      <MUI.Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <MUI.Typography variant='caption' component='div' color='textSecondary'>
          {`${Math.round(props.progress.progress * 100)}`}
        </MUI.Typography>
      </MUI.Box>
    </MUI.Box>
  );
}

export default React.memo(ContentDownloadProgress);
