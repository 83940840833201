import React from 'react';
import { useSelector } from 'react-redux';
import * as MUI from '@material-ui/core';

import ContentPrimaryAction from './ContentPrimaryAction';
import Dialog from './Dialog';

import { dispatchAndNotify } from '../store';
import { getBestImageUrl, useCustomSnackbar } from '../utils';
import { fetchMarketplaceContents } from '../features/contents/contentsActions';
import { selectAllContents } from '../features/contents/contentsState';
import { MarketContent } from '../features/contents/contentsTypes';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    asset: {
      flexWrap: 'wrap',
      padding: '16px'
    },
    assetHeader: {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    assetThumb: {
      marginRight: '8px'
    },
    assetActionBar: {
      width: '100%',
      padding: '0',
      marginTop: '12px'
    },
    tooltipImage: {
      width: '256px'
    }
  })
);

interface Props {
  contentId: string;
  onClose: () => void;
}

export default function BundleDownloadOverlay(props: Props) {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useCustomSnackbar();

  const allContents = useSelector(selectAllContents);

  const bundle = allContents[props.contentId];

  React.useEffect(() => {
    if (bundle && bundle.type === 'marketcontent' && bundle.assets) {
      const missingAssets = bundle.assets
        .filter((a) => allContents[a.id] === undefined)
        .map((a) => a.id);

      dispatchAndNotify(fetchMarketplaceContents(missingAssets), enqueueSnackbar);
    }
  }, [allContents, bundle, enqueueSnackbar]);

  if (bundle === undefined) {
    console.error('Cannot render BundleDownloadOverlay, content cannot be found', props.contentId);
    return null;
  }

  if (bundle.type !== 'marketcontent') {
    console.error(
      'Cannot render BundleDownloadOverlay, content is not a marketcontent',
      props.contentId
    );
    return null;
  }

  if (!bundle.assets) {
    console.error('Cannot render BundleDownloadOverlay, content is not a bundle', props.contentId);
    return null;
  }

  const assets = bundle.assets.map((content) => {
    const fullContent = allContents[content.id];

    let thumbnail: JSX.Element | undefined = undefined;

    const thumbnailImageUrl = content.images?.[0]?.urls
      ? getBestImageUrl(content.images?.[0]?.urls, 64)
      : undefined;

    if (thumbnailImageUrl) {
      const tooltipImageUrl = content.images?.[0]?.urls
        ? getBestImageUrl(content.images?.[0]?.urls, 256)
        : undefined;

      thumbnail = (
        <MUI.Tooltip
          title={
            tooltipImageUrl ? (
              <div className={classes.tooltipImage}>
                <img src={tooltipImageUrl} alt={content.name} />
              </div>
            ) : (
              ''
            )
          }
        >
          <img src={thumbnailImageUrl} alt={content.name} className={classes.assetThumb} />
        </MUI.Tooltip>
      );
    }

    return (
      <MUI.ListItem className={classes.asset} key={content.id} divider>
        <div className={classes.assetHeader}>
          {thumbnail}
          <MUI.ListItemText
            primary={content.name}
            primaryTypographyProps={{ variant: 'subtitle2' }}
            secondary={`${content.description}`}
            secondaryTypographyProps={{ style: { whiteSpace: 'pre-line' } }}
          />
        </div>

        {fullContent ? (
          <ContentPrimaryAction
            component='bar'
            className={classes.assetActionBar}
            content={fullContent as MarketContent}
          />
        ) : (
          <MUI.CircularProgress style={{ height: '36px', width: '36px', marginTop: '12px' }} />
        )}
      </MUI.ListItem>
    );
  });

  return (
    <Dialog open onClose={props.onClose} fullWidth maxWidth='md'>
      <MUI.DialogTitle>Assets in {bundle.name}</MUI.DialogTitle>

      <MUI.List>{assets}</MUI.List>
    </Dialog>
  );
}
