import React from 'react';
import { useSelector } from 'react-redux';

import IconButton from '../lindaleui/components/IconButton';

import ContentPrimaryAction, { startImport } from './ContentPrimaryAction';
import { addContentToCart } from '../features/cart/cartActions';
import { selectPurchasedContentIds } from '../features/contents/contentsState';
import { Content } from '../features/contents/contentsTypes';
import { selectOngoingImport } from '../features/download/downloadState';
import { dispatchAndNotify } from '../store';
import { selectCartContentIds } from '../features/cart/cartState';
import { useCustomSnackbar } from '../utils';

interface Props {
  content: Content;
  onOpenCart: () => void;
  onOpenBundleDownloadDetails: (id: string) => void;
}

function ContentQuickAction(props: Props) {
  const { enqueueSnackbar, closeSnackbar } = useCustomSnackbar();

  const purchasedContentIds = useSelector(selectPurchasedContentIds);
  const cartContentIds = useSelector(selectCartContentIds);
  const ongoingImport = useSelector(selectOngoingImport);

  const isPurchased = purchasedContentIds.includes(props.content.id);
  const inCart = cartContentIds.includes(props.content.id);

  // Local .skp/.skatter: show an import button

  if (props.content.type === 'skp' || props.content.type === 'skatter') {
    return (
      <IconButton
        icon='mdi-import'
        tooltip='Import'
        disabled={ongoingImport !== undefined}
        onClick={() => {
          const path = Base64.decode(props.content.id);
          startImport(path, props.content, enqueueSnackbar, closeSnackbar);
        }}
      />
    );
  }

  // Not purchased and not in cart: show an "add to cart" button
  else if (props.content.type === 'marketcontent' && props.content.price > 0 && !isPurchased) {
    return (
      <IconButton
        icon={inCart ? 'mdi-cart' : 'mdi-cart-outline'}
        color={inCart ? 'primary' : 'default'}
        tooltip={inCart ? 'Click to open your cart' : 'Add to cart'}
        onClick={async () => {
          if (inCart) {
            props.onOpenCart();
          } else {
            dispatchAndNotify(addContentToCart(props.content), enqueueSnackbar);
          }
        }}
      />
    );
  }

  // Free or purchased: show a "download" button
  else if (!inCart) {
    const isBundle = props.content.contentTypes.includes('bundle');
    const isAsset = props.content.packages.length > 0;

    // Bundle: special interface to pick packages
    if (isBundle && props.content.type === 'marketcontent') {
      return (
        <IconButton
          icon={'mdi-download-multiple'}
          tooltip={'Download assets'}
          onClick={() => props.onOpenBundleDownloadDetails(props.content.id)}
        />
      );
    }
    // Standard primary action
    else if (isAsset) {
      return <ContentPrimaryAction component='icon' content={props.content} />;
    }
  }

  return null;
}

export default React.memo(ContentQuickAction);
