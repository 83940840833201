import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { UMap } from '../../utils';
import {
  MARKET_ID,
  LOCAL_ID,
  selectAllBranchesInfos,
  PURCHASED_ID,
  selectAllContents,
  selectAllBranchesContents
} from '../contents/contentsState';
import { Content, BranchInfo, Renderer } from '../contents/contentsTypes';

export type SearchFiltersContentType = 'model' | 'bundle' | 'composition';

export interface SearchFilters {
  free: boolean;
  priceRange: { min: number; max: number };
  contentTypes: SearchFiltersContentType[];
  renderers: Renderer[];

  // Packages in search results will be grouped together
  // as a single marketplace-like content
  groupPackagesIntoMarketplaceContent: boolean; // TODO is this really a "filter"?
}

export enum SortCriteria {
  DEFAULT = 'none',
  DATE_ASC = 'date_asc',
  DATE_DESC = 'date_desc',
  NAME_ASC = 'name_asc',
  NAME_DESC = 'name_desc',
  PRICE_ASC = 'price_asc',
  PRICE_DESC = 'price_desc'
}

export const DEFAULT_FILTERS = {
  free: false,
  priceRange: { min: 0, max: 100 },
  contentTypes: [],
  renderers: [],
  groupPackagesIntoMarketplaceContent: true
};

// Contains all the data stored in the URL.
// Each "location" leads to a different page.
//
// "Location" would have been a better name but it's already taken.
export interface NavigationState {
  // Path to the branch that is currently viewed
  branchPath: string[];

  // Path to the content that is currently viewed.
  //
  // Each element is the ID of a content, stored as a sequence so that
  // we can navigate between assets & bundles.
  contentPath: string[];

  // Search keywords
  searchKeywords: string[];

  // Search filters
  searchFilters: SearchFilters;

  // Sort criteria
  sortCriteria: SortCriteria;

  // Current vendor
  vendorId: string | undefined;
}

export const initialState: NavigationState = {
  branchPath: [],
  contentPath: [],
  searchKeywords: [],
  searchFilters: DEFAULT_FILTERS,
  sortCriteria: SortCriteria.DEFAULT,
  vendorId: undefined
};

// Complete location

export const selectCurrentLocation = (root: RootState) => root.navigation;

// Search filters

// Check if a set of search filters differs from the default ones
//
// WARNING: we compare each attribute instead of just using something like
// _.isEqual(filters, DEFAULT_FILTERS) because it's possible for this
// function to be called with an Immer's WritableDraft<SearchFilter> instead of
// a SearchFilter, which would always return false!
export function areFiltersEnabled(filters: SearchFilters, sortCriteria: SortCriteria | undefined) {
  return (
    filters.free !== DEFAULT_FILTERS.free ||
    filters.priceRange.min !== DEFAULT_FILTERS.priceRange.min ||
    filters.priceRange.max !== DEFAULT_FILTERS.priceRange.max ||
    filters.groupPackagesIntoMarketplaceContent !==
      DEFAULT_FILTERS.groupPackagesIntoMarketplaceContent ||
    filters.contentTypes.length !== DEFAULT_FILTERS.contentTypes.length ||
    filters.contentTypes.some((type, i) => type !== DEFAULT_FILTERS.contentTypes[i]) ||
    filters.renderers?.length !== DEFAULT_FILTERS.renderers.length ||
    filters.renderers.some((type, i) => type !== DEFAULT_FILTERS.renderers[i]) ||
    sortCriteria !== SortCriteria.DEFAULT
  );
}

export function isSearchFiltered(location: NavigationState) {
  return (
    areFiltersEnabled(location.searchFilters, location.sortCriteria) ||
    // Data stored in the navigation slice
    location.vendorId !== undefined ||
    location.searchKeywords.length > 0
  );
}

export const selectFiltersEnabled = createSelector(
  [
    (root: RootState) => root.navigation.searchFilters,
    (root: RootState) => root.navigation.sortCriteria
  ],
  (filters, sortCriteria) => areFiltersEnabled(filters, sortCriteria)
);

// A search is "filtered" not only if some filters are set but also if
// search keywords or a vendor are defined.
export const selectSearchFiltered = createSelector(
  [(root: RootState) => root.navigation],
  (location) => isSearchFiltered(location)
);

export const selectSearchFilters = (root: RootState) => root.navigation.searchFilters;

export const selectSortCriteria = (root: RootState) => root.navigation.sortCriteria;

// Branch path

export const selectCurrentBranchPath = (root: RootState) => root.navigation.branchPath;

export const isPathMarket = (path: string[]) => path.length > 0 && path[0] === MARKET_ID;
export const isPathMarketHome = (path: string[]) => path.length === 1 && path[0] === MARKET_ID;
export const isPathPurchased = (path: string[]) =>
  path.length > 0 && path[path.length - 1] === PURCHASED_ID;
export const isPathLocal = (path: string[]) => path.length > 0 && path[0] === LOCAL_ID;
export const isPathLocalHome = (path: string[]) => path.length === 1 && path[0] === LOCAL_ID;

export function getBranchId(branchPath: string[]) {
  return branchPath.length > 0 ? branchPath[branchPath.length - 1] : '/';
}

export const selectCurrentBranchId = createSelector([selectCurrentBranchPath], (branchPath) =>
  getBranchId(branchPath)
);

export const selectCurrentBranchInfo = createSelector(
  [selectCurrentBranchPath, (root: RootState) => root.contents.branchesInfo, selectSearchFiltered],
  (currentBranchPath, allBranchesInfo, searchFiltered) => {
    const branchId = getBranchId(currentBranchPath);
    return allBranchesInfo[branchId];
  }
);

export const selectCurrentBranchChildren = createSelector(
  [selectCurrentBranchInfo, selectAllBranchesInfos],
  (currentBranchInfo, allBranches) => {
    if (!currentBranchInfo) {
      return {};
    }

    const children: UMap<BranchInfo> = {};

    currentBranchInfo.childrenIds.forEach((childId) => {
      const child = allBranches[childId];
      if (child) {
        children[childId] = child;
      }
    });

    return children;
  }
);

export const selectCurrentBranchContents = createSelector(
  [
    selectCurrentBranchId,
    selectSearchFiltered,
    (root: RootState) => root.contents.branchesContents
  ],
  (currentBranchId, searchFiltered, allBranchesContents) => {
    const branch = allBranchesContents[currentBranchId];
    return searchFiltered ? branch?.filtered : branch?.all;
  }
);

export const selectContentsInCurrentBranch = createSelector(
  [selectAllContents, selectCurrentBranchPath, selectAllBranchesContents, selectSearchFiltered],
  (allContents, currentBranchPath, allBranchesContents, searchFiltered) => {
    const branchId = getBranchId(currentBranchPath);
    const branch = allBranchesContents[branchId];

    const ids = (searchFiltered ? branch?.filtered.contentIds : branch?.all.contentIds) ?? [];

    return ids
      .map((id) => allContents[id])
      .filter((content): content is Content => content !== undefined);
  }
);

// Returns a path-like array but with nice names.
// For instance [MARKET_ID, 'trees'] -> ['Marketplace', 'Trees']
export const selectCurrentPathNames = createSelector(
  [(root: RootState) => root.navigation.branchPath, selectAllBranchesInfos],
  (branchPath, branches) => branchPath.map((branchId) => branches[branchId]?.name ?? undefined)
);

// Content path

export const selectCurrentContentPath = (root: RootState) => root.navigation.contentPath;

export const selectCurrentContent = createSelector(
  [(root: RootState) => root.navigation.contentPath, (root: RootState) => root.contents.contents],
  (contentPath, allContents) =>
    contentPath.length > 0 ? allContents[contentPath[contentPath.length - 1]] : undefined
);

// Vendor

export const selectCurrentVendor = createSelector(
  [(root: RootState) => root.navigation.vendorId, (root: RootState) => root.contents.vendors],
  (vendorId, allVendors) => (vendorId ? allVendors[vendorId] : undefined)
);
