import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import RemarkBreaks from 'remark-breaks';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { basename } from 'path';
import prettyBytes from 'pretty-bytes';
import semver from 'semver';
import classnames from 'classnames';
import { Blurhash } from 'react-blurhash';
import * as MUI from '@material-ui/core';
import * as MUILab from '@material-ui/lab';

import Checkbox from '../lindaleui/components/Checkbox';
import DotProgress from '../lindaleui/components/DotProgress';
import Icon from '../lindaleui/components/Icon';
import RatioBox from '../lindaleui/components/RatioBox';

import ContentPrimaryAction, { startImport } from './ContentPrimaryAction';
import Dialog from './Dialog';

import { dispatchAndNotify } from '../store';
import {
  createLocationString,
  getBestImageRes,
  getBestImageUrl,
  preloadImage,
  getRendererIcons,
  formatPrice,
  useCustomSnackbar
} from '../utils';
import { addContentToCart } from '../features/cart/cartActions';
import { selectCartContents } from '../features/cart/cartState';
import { toggleFavorite } from '../features/favorites/favoritesActions';
import { selectFavoriteContentIds } from '../features/favorites/favoritesState';
import {
  MARKET_ID,
  selectAllContents,
  selectPurchasedContentIds
} from '../features/contents/contentsState';
import {
  DEFAULT_FILTERS,
  selectCurrentLocation,
  SortCriteria
} from '../features/navigation/navigationState';
import { isContentLocal, MarketContent, SubContent } from '../features/contents/contentsTypes';
import { selectSketchupVersion } from '../features/app/appState';

import missingImage from '../img/cube-outline.svg';
import { selectOngoingImport } from '../features/download/downloadState';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    root: {},

    scrollableContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto'
    },

    imageContainer: {
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: 'rgb(240, 240, 240)',
      minHeight: '300px'
    },
    // This is a hidden <img/> element used to give the wrapper its correct height
    imageSizer: {
      width: '100%',
      visibility: 'hidden',
      display: 'block' // disable descender
    },
    image: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      '& img': {
        height: '100%',
        display: 'block',
        margin: 'auto',
        // Center the image (useful if it overflows, eg on mobile)
        marginLeft: '50%',
        transform: 'translateX(-50%)'
      }
    },
    imageButtons: {
      background: 'none',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      boxShadow: 'inset 0 -3px 10px rgba(0,0,0,0.1)'
    },
    imageButton: {
      height: '100%',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.3)'
      }
    },
    imageButtonChevron: {
      textShadow: '0 0 3px white' // Bright shadow for dark images
    },
    backButton: {
      position: 'absolute',
      top: '0.5rem',
      left: '0.5rem',
      backgroundColor: 'white'
    },
    dotsContainer: {
      position: 'absolute',
      bottom: '1rem',
      left: '50%',
      transform: 'translateX(-50%)'
    },

    content: {
      flex: '0 0 auto',
      padding: '12px 16px'
    },
    text: {},
    name: {
      marginRight: '8px'
    },
    vendor: {
      '& a, & a:visited, & a:hover, & a:active, & a:focus': {
        color: theme.palette.text.primary
      }
    },
    price: {
      margin: '0 8px',
      color: theme.palette.primary.main,
      fontWeight: 500
    },
    error: {
      marginTop: '8px'
    },
    relatedContents: {
      display: 'flex',
      width: '100%',
      overflow: 'auto',
      padding: '8px 0'
    },
    relatedContent: {
      width: '64px',
      height: '64px',
      flexShrink: 0,
      overflow: 'hidden',
      borderRadius: '4px',
      marginRight: '6px',
      '& img': {
        height: '100%',
        margin: 'auto',
        display: 'block'
      }
    },

    tag: {
      marginRight: '8px',
      fontSize: '0.75rem'
    },
    tagChip: {
      marginTop: '4px'
    },
    favorite: {},

    actionBar: {
      borderTop: '1px solid #ddd',
      padding: '12px 16px',
      flexShrink: 0, // Always show the action bar fully
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap'
      }
    },
    actionBarItem: {
      fontWeight: 'bold'
    },
    purchaseButton: {
      // Small screen: more vertical spacing, full width
      [theme.breakpoints.down('xs')]: {
        marginTop: '0.5rem',
        marginLeft: '0 !important', // needed because of a marginLeft rule coming from somewhere else, but I don't know where!!
        width: '100%'
      }
    }
  })
);

/*function ContentMenu(props: MenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const close = () => {
        setAnchorEl(null);
    };

    let entries: React.ReactElement[] = [];
    if(props.content.actions) {
        entries = props.content.actions.map((action, i) =>
            <MenuItem key={i} dense size='medium' onClick={action.callback}>{action.label}</MenuItem>
        );
    }

    return (
        <>
            {entries.length > 0 &&
                <div>
                    <IconButton onClick={open} icon='mdi-dots-vertical' />
                    <MUI.Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={close}
                        MenuListProps={{dense: true}}
                        anchorOrigin={{horizontal: 'right', vertical: 'top'}}
                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                    >
                        { entries }
                    </MUI.Menu>
                </div>
            }
        </>
    );
}*/

interface Props {
  contentId: string;
  onOpenCart: () => void;
  onClose: () => void;
  onOpenBundleDownloadDetails: (id: string) => void;
}

const defaultImageRes = 512;

export default function ContentOverlay(props: Props) {
  const classes = useStyles(props);
  const { enqueueSnackbar, closeSnackbar } = useCustomSnackbar();
  const history = useHistory();

  const currentLocation = useSelector(selectCurrentLocation);
  const allContents = useSelector(selectAllContents);
  const cartContents = useSelector(selectCartContents);
  const purchasedContentIds = useSelector(selectPurchasedContentIds);
  const favoriteContentIds = useSelector(selectFavoriteContentIds);
  const ongoingImport = useSelector(selectOngoingImport);
  const sketchupVersion = useSelector(selectSketchupVersion);

  const onToggleFavorite = React.useCallback(
    (e, checked) => {
      dispatchAndNotify(toggleFavorite({ id: props.contentId, toggle: checked }), enqueueSnackbar);
    },
    [props.contentId, enqueueSnackbar]
  );

  // The content might not exist yet, or be loading, so the rest of
  // this component must handle those cases gracefully!
  const content = allContents[props.contentId];

  const { ref, width } = useResizeObserver<HTMLDivElement>();

  // Current image state

  const [activeImageIndex, setActiveImageIndex] = React.useState(0);

  // Pre-load images

  React.useEffect(() => {
    if (!content) return;

    if (content.type === 'skp' || content.type === 'skatter') {
      if (content.image) {
        preloadImage(content.image);
      }
    } else if (width && content.images.length > 0) {
      // Make sure we're not accessing invalid indices (already had a few bugs with that)
      if (activeImageIndex >= content.images.length) {
        console.error(`Cannot preload image ${activeImageIndex}/${content.images.length}`);
      } else {
        const res =
          getBestImageRes(content.images[activeImageIndex].urls, width) ?? defaultImageRes;

        content.images.forEach((image) => {
          const url = image.urls[res];
          if (url !== undefined) preloadImage(url);
        });
      }
    }
  }, [content, activeImageIndex, width]);

  const isBundle =
    (content?.type === 'marketcontent' || content?.type === 'localcontent') &&
    content?.contentTypes.includes('bundle');
  const isAsset =
    (content?.type === 'marketcontent' || content?.type === 'localcontent') &&
    content?.packages.length > 0;

  const isLocal = content !== undefined && isContentLocal(content);
  const isFavorite =
    content !== undefined && isLocal && favoriteContentIds.includes(props.contentId);

  const onImportFile = () => {
    if (content) {
      const path = Base64.decode(content.id);
      startImport(path, content, enqueueSnackbar, closeSnackbar);
    }
  };

  // Ref to check if the local path overflows to show a tooltip as it can be quite long

  const pathRef = React.useRef<HTMLHeadingElement>(null);

  const pathOverflown = (pathRef.current?.scrollWidth ?? 0) > (pathRef.current?.offsetWidth ?? 0);

  // Tags

  let tags =
    content?.type === 'marketcontent' || content?.type === 'localcontent' ? (
      <MUI.Box mt={1} display='flex' flexWrap='wrap'>
        {content.tags.map((tag, i) => (
          <MUI.Chip
            key={i}
            label={tag}
            size='small'
            variant='outlined'
            color='primary'
            classes={{ root: classes.tagChip }}
            className={classes.tag}
            onClick={() => {
              history.push(
                createLocationString({
                  ...currentLocation,
                  contentPath: [], // closes the content's overlay
                  searchKeywords: [tag]
                })
              );
            }}
          />
        ))}
      </MUI.Box>
    ) : null;

  // Price

  const isPurchased = content && purchasedContentIds.includes(content.id);

  // TODO use contentprice component?
  let price =
    content?.type === 'marketcontent' || content?.type === 'localcontent' ? (
      <MUI.Typography className={classes.price} gutterBottom component='span'>
        {
          isPurchased
            ? 'PURCHASED'
            : content.price > 0
            ? formatPrice(content.price, content.currency)
            : 'FREE' // Display "FREE" instead of the less sexy 0.00 price
        }
      </MUI.Typography>
    ) : null;

  // Images

  const imageCount =
    content === undefined
      ? 0
      : content.type === 'marketcontent' || content.type === 'localcontent'
      ? content.images.length
      : content.image
      ? 1
      : 0;

  const onNextImage = () => {
    setActiveImageIndex((prevIndex) => (activeImageIndex === imageCount - 1 ? 0 : prevIndex + 1));
  };

  const onBackImage = () => {
    setActiveImageIndex((prevIndex) => (activeImageIndex === 0 ? imageCount - 1 : prevIndex - 1));
  };

  const onSetImage = (index: number) => {
    setActiveImageIndex(index);
  };

  // Find the resolution closest to the image size

  const imageUrl =
    content === undefined // mmmh this is nasty
      ? undefined
      : content.type === 'marketcontent' || content.type === 'localcontent'
      ? content.images?.[activeImageIndex]?.urls && width
        ? getBestImageUrl(content.images[activeImageIndex].urls, width)
        : undefined
      : content.image;

  const imageLowresUrl =
    content === undefined
      ? undefined
      : content.type === 'marketcontent' || content.type === 'localcontent'
      ? content.images?.[activeImageIndex]?.urls
        ? getBestImageUrl(content.images?.[activeImageIndex]?.urls, 64)
        : undefined
      : content.image;

  const imageRatio =
    content && (content.type === 'marketcontent' || content.type === 'localcontent')
      ? content.images?.[activeImageIndex]?.ratio
      : undefined;

  const blurHash =
    content && (content.type === 'marketcontent' || content.type === 'localcontent')
      ? content.images?.[activeImageIndex]?.blurHash
      : undefined;

  const renderRelatedContentCard = (content: MarketContent | SubContent) => {
    const imageUrl = content.images?.[0]?.urls
      ? getBestImageUrl(content.images?.[0]?.urls, 128)
      : undefined;

    // Link to the next item
    // - if the next item is the same as the previous one, just go back (A B + A -> A)
    // - otherwise just append the next item ( A B + C -> A B C)
    const nextContentPath =
      currentLocation.contentPath.length > 1 &&
      currentLocation.contentPath[currentLocation.contentPath.length - 2] === content.id
        ? currentLocation.contentPath.slice(0, currentLocation.contentPath.length - 1)
        : [...currentLocation.contentPath, content.id];

    return (
      <MUI.Tooltip
        key={content.id}
        title={<span style={{ fontSize: '0.9rem' }}>{content.name ?? ''}</span>}
        arrow
      >
        <Link
          to={createLocationString({ ...currentLocation, contentPath: nextContentPath })}
          onClick={() => setActiveImageIndex(0)}
        >
          <div className={classes.relatedContent}>
            <img src={imageUrl ?? missingImage} loading='lazy' alt={content.name} />
          </div>
        </Link>
      </MUI.Tooltip>
    );
  };

  // Assets included in the current bundle

  const bundleAssets =
    (content?.type === 'marketcontent' || content?.type === 'localcontent') &&
    isBundle &&
    content.assets ? (
      <MUI.Box mt='16px'>
        <MUI.Typography variant='subtitle2'>Assets included in this bundle:</MUI.Typography>
        <div className={classes.relatedContents}>
          {content.assets.map(renderRelatedContentCard)}
        </div>
      </MUI.Box>
    ) : null;

  // Bundles including the current content

  const contentBundles =
    (content?.type === 'marketcontent' || content?.type === 'localcontent') &&
    isAsset &&
    content.bundles &&
    content.bundles.length > 0 ? (
      <MUI.Box mt='16px'>
        <MUI.Typography variant='subtitle2'>Bundles including this asset:</MUI.Typography>
        <div className={classes.relatedContents}>
          {content.bundles.map(renderRelatedContentCard)}
        </div>
      </MUI.Box>
    ) : null;

  // Local .skp or .skatter file: can only import it

  let actionBar: JSX.Element | undefined = undefined;

  if (content?.type === 'skp' || content?.type === 'skatter') {
    actionBar = (
      <MUI.DialogActions className={classes.actionBar}>
        <MUI.Button
          variant='contained'
          color='primary'
          disabled={content.status === 'loading' || ongoingImport !== undefined}
          onClick={onImportFile}
          startIcon={<Icon icon='mdi-import' />}
        >
          IMPORT
        </MUI.Button>
      </MUI.DialogActions>
    );
  }

  // Buyable asset: can only put it in the cart
  else if (content?.type === 'marketcontent' && content?.price > 0 && !isPurchased) {
    const inCart = cartContents.find((c) => c.id === content.id) !== undefined;

    const onAddContentToCart = async () => {
      dispatchAndNotify(addContentToCart(content), enqueueSnackbar);
    };

    actionBar = (
      <MUI.DialogActions className={classes.actionBar}>
        {content.proxyRenderers && content.proxyRenderers.length > 0 && (
          <MUI.Box flex={1} display='flex' alignItems='center'>
            <MUI.Typography variant='caption' className={classes.actionBarItem}>
              Proxies:&nbsp;
            </MUI.Typography>
            {getRendererIcons(content.proxyRenderers, 18)}
          </MUI.Box>
        )}
        {content.materialRenderers && content.materialRenderers.length > 0 && (
          <MUI.Box flex={1} display='flex' alignItems='center'>
            <MUI.Typography variant='caption' className={classes.actionBarItem}>
              Materials:&nbsp;
            </MUI.Typography>
            {getRendererIcons(content.materialRenderers, 18)}
          </MUI.Box>
        )}
        <MUI.Tooltip title={inCart ? 'Click to view your cart' : ''}>
          <MUI.Button
            variant='contained'
            color='primary'
            className={classnames(classes.purchaseButton, 'add-to-cart-button')}
            startIcon={<Icon icon={inCart ? 'mdi-cart' : 'mdi-cart-outline'} />}
            onClick={inCart ? props.onOpenCart : onAddContentToCart}
          >
            {inCart ? 'Added to your cart' : 'Add to cart'}
          </MUI.Button>
        </MUI.Tooltip>
      </MUI.DialogActions>
    );
  }

  // Purchased bundle: can download individual contents
  else if (isBundle) {
    actionBar = (
      <MUI.DialogActions className={classes.actionBar}>
        <MUI.Button
          variant='contained'
          color='primary'
          className={classes.purchaseButton}
          startIcon={<Icon icon='mdi-download-multiple' />}
          onClick={() => content && props.onOpenBundleDownloadDetails(content.id)}
        >
          Download Assets
        </MUI.Button>
      </MUI.DialogActions>
    );
  }

  // Purchased asset or local bazaar content: can select package/download/import
  else if (
    content &&
    isAsset &&
    (content.type === 'marketcontent' || content.type === 'localcontent')
  ) {
    actionBar = (
      <ContentPrimaryAction component='bar' className={classes.actionBar} content={content} />
    );
  }

  // For local packages: show missing files as errors

  const missingFiles: string[] = [];

  if (content?.type === 'localcontent') {
    Object.entries(content.availablePackages).forEach(([packId, pack]) => {
      missingFiles.push(
        ...(pack?.files.filter((file) => !file.exists).map((file) => file.name) ?? [])
      );
    });
  }

  const missingFilesErrors =
    missingFiles.length > 0 ? (
      <MUILab.Alert severity='warning'>
        <MUI.Typography variant='body1'>
          Some files are missing. Import might not work properly.
        </MUI.Typography>
        {missingFiles.map((file) => (
          <MUI.Typography variant='body2' style={{ fontFamily: 'monospace', fontSize: '1rem' }}>
            • {file}
          </MUI.Typography>
        ))}
      </MUILab.Alert>
    ) : null;

  // Render

  const name =
    content === undefined
      ? undefined
      : content.type === 'marketcontent' || content.type === 'localcontent'
      ? content.name ?? '[MISSING NAME]'
      : basename(Base64.decode(content.id)); // file name for local files

  const backButton =
    currentLocation.contentPath.length > 1 ? (
      <Link
        to={createLocationString({
          ...currentLocation,
          contentPath: currentLocation.contentPath.slice(0, currentLocation.contentPath.length - 1)
        })}
      >
        <MUI.Button
          classes={{ root: classes.backButton }}
          variant='outlined'
          color='primary'
          size='small'
        >
          Back to {isBundle ? 'asset' : 'bundle'}
        </MUI.Button>
      </Link>
    ) : null;

  // Skp files: check that the version is not too recent
  const isSkpVersionTooRecent =
    sketchupVersion !== undefined &&
    content?.type === 'skp' &&
    content.skpVersion &&
    semver.lt(sketchupVersion, content.skpVersion);

  return (
    <Dialog
      className={classnames(classes.root, 'content-overlay')}
      open
      onClose={props.onClose}
      fullWidth
      maxWidth='md'
    >
      <div className={classes.scrollableContent}>
        <div className={classes.imageContainer} ref={ref}>
          {/* Add a hidden element whose sole purpose is to set the correct height for the imageContainer */}
          {imageRatio ? (
            <MUI.Box width='100%' height={0} pb={`${100 / imageRatio}%`} visibility='hidden' />
          ) : (
            <img className={classes.imageSizer} src={imageLowresUrl} alt='' />
          )}

          {blurHash && (
            <RatioBox
              ratio={imageRatio}
              rootProps={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%'
              }}
            >
              <Blurhash hash={blurHash} width='100%' height='100%' />
            </RatioBox>
          )}

          <div className={classes.image}>
            {content === undefined || content.status === 'loading' ? (
              <MUILab.Skeleton variant='rect' animation='wave' width='100%' height='100%' />
            ) : (
              <img src={imageUrl ?? missingImage} key={imageUrl} alt={name} />
            )}
          </div>

          {imageCount > 1 && (
            <div className={classes.imageButtons}>
              <MUI.Tooltip title='Previous image' placement='left'>
                <MUI.Button size='small' onClick={onBackImage} className={classes.imageButton}>
                  <Icon icon='mdi-chevron-left' className={classes.imageButtonChevron} />
                </MUI.Button>
              </MUI.Tooltip>

              <MUI.Tooltip title='Next image' placement='right'>
                <MUI.Button size='small' onClick={onNextImage} className={classes.imageButton}>
                  <Icon icon='mdi-chevron-right' className={classes.imageButtonChevron} />
                </MUI.Button>
              </MUI.Tooltip>

              <div className={classes.dotsContainer}>
                <DotProgress
                  variant='single'
                  count={imageCount}
                  value={activeImageIndex}
                  onClick={onSetImage}
                />
              </div>
            </div>
          )}

          {backButton}
        </div>

        <MUI.Box className={classes.content}>
          <MUI.Box display='flex'>
            <MUI.Box alignItems='baseline' display='flex'>
              {isBundle && (
                <MUI.Box mr='8px' alignSelf='center'>
                  <MUI.Chip label='BUNDLE' size='small' color='primary' />
                </MUI.Box>
              )}
              <MUI.Typography className={classes.name} variant='h6' component='span'>
                {name}
              </MUI.Typography>

              {/* Same container to keep the vendor and price grouped on small screens */}
              <span>
                {(content?.type === 'marketcontent' || content?.type === 'localcontent') &&
                  content?.vendor && (
                    <MUI.Typography className={classes.vendor} variant='caption' component='span'>
                      by{' '}
                      <Link
                        // Link to vendor page: go back to the marketplace's root
                        to={createLocationString({
                          branchPath: [MARKET_ID],
                          contentPath: [],
                          searchKeywords: [],
                          searchFilters: DEFAULT_FILTERS,
                          sortCriteria: SortCriteria.DEFAULT,
                          vendorId: content.vendor.id
                        })}
                      >
                        {content.vendor.name}
                      </Link>
                    </MUI.Typography>
                  )}
                {price}
              </span>
            </MUI.Box>

            <MUI.Box display='flex' alignItems='center'>
              {isLocal && (
                <Checkbox
                  className={classes.favorite}
                  icon='mdi-heart-outline'
                  checkedIcon='mdi-heart'
                  checked={isFavorite}
                  tooltip={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                  onChange={onToggleFavorite}
                />
              )}
            </MUI.Box>
          </MUI.Box>

          {content && isLocal && (
            <MUI.Tooltip title={pathOverflown ? Base64.decode(content.id) : ''}>
              <MUI.Typography
                ref={pathRef}
                variant='caption'
                component='div'
                color='textSecondary'
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}
              >
                {Base64.decode(content.id)}
              </MUI.Typography>
            </MUI.Tooltip>
          )}

          {content && content.description && (
            <MUI.Box mt={1}>
              <ReactMarkdown
                source={content.description}
                escapeHtml={false}
                plugins={[RemarkBreaks]}
                renderers={{
                  paragraph: (props) => <React.Fragment {...props} />
                }}
              />
            </MUI.Box>
          )}

          {content && content.type === 'skp' && (
            <MUI.Grid container spacing={1}>
              {content.size && (
                <MUI.Grid item>
                  <MUI.Typography variant='caption' color='textSecondary'>
                    {`${content.size && prettyBytes(content.size)}`}
                  </MUI.Typography>
                </MUI.Grid>
              )}
              {content.size && content.skpVersion && (
                <MUI.Grid item>
                  <MUI.Typography variant='caption' color='textSecondary'>
                    —
                  </MUI.Typography>
                </MUI.Grid>
              )}
              {content.skpVersion && (
                <MUI.Grid item>
                  <MUI.Typography variant='caption' color='textSecondary'>
                    {`SketchUp ${content.skpVersion}`}
                  </MUI.Typography>
                </MUI.Grid>
              )}
            </MUI.Grid>
          )}

          {(content === undefined || content.status === 'loading') && (
            <MUI.Box mt={1}>
              <MUILab.Skeleton variant='text' animation='wave' width='60%' />
              <MUILab.Skeleton variant='text' animation='wave' width='40%' />
              <MUILab.Skeleton variant='text' animation='wave' width='50%' />
            </MUI.Box>
          )}

          {bundleAssets}

          {contentBundles}

          {tags}

          {isLocal && content?.status === 'loading' && (
            <MUILab.Alert severity='info'>
              This file is queued for scanning. Its details will update soon.
            </MUILab.Alert>
          )}

          {isSkpVersionTooRecent && content?.type === 'skp' && (
            <MUILab.Alert severity='warning'>
              This file has been created with a version of SketchUp (
              <span style={{ fontFamily: 'monospace', fontSize: '1rem' }}>
                {content.skpVersion}
              </span>
              ) that is more recent than yours (
              <span style={{ fontFamily: 'monospace', fontSize: '1rem' }}>{sketchupVersion}</span>).
              Importing it might fail.
            </MUILab.Alert>
          )}

          {content?.errors &&
            content.errors.map((error, index) => (
              <MUILab.Alert key={index} severity='error' className={classes.error}>
                {error}
              </MUILab.Alert>
            ))}

          {missingFilesErrors}
        </MUI.Box>
      </div>

      {actionBar}
    </Dialog>
  );
}
