import { createSlice } from '@reduxjs/toolkit';

import {
  fetchDownloadedPackages,
  downloadPackage,
  updateDownloadProgress,
  updateImportStatus,
  importFileInSketchup,
  waitForImportEnd
} from './downloadActions';
import { initialState } from './downloadState';
import { isExtension } from '../../utils';

export const downloadSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Download progress

    builder.addCase(updateDownloadProgress, (state, action) => {
      state.downloadingPackages[action.payload.packageId] = {
        status: action.payload.progress.status,
        progress: action.payload.progress.progress,
        errorMessage: action.payload.progress.errorMessage
      };
    });

    // Fetch downloaded packages

    builder.addCase(fetchDownloadedPackages.fulfilled, (state, action) => {
      action.payload.forEach((pack) => (state.downloadedPackages[pack.id] = pack));
    });

    builder.addCase(fetchDownloadedPackages.rejected, (state, action) => {
      state.downloadedPackages = {};
    });

    // Download package

    builder.addCase(downloadPackage.pending, (state, action) => {
      // Do nothing for the web version
      if (!isExtension()) {
        return;
      }

      // Add to the downloads in progress
      state.downloadingPackages[action.meta.arg.packageId] = {
        status: 'downloading',
        progress: 0
      };
    });

    builder.addCase(downloadPackage.fulfilled, (state, action) => {
      // Do nothing for the web version
      if (action.payload === undefined) {
        return;
      }

      // Remove from the downloads in progress
      delete state.downloadingPackages[action.meta.arg.packageId];

      // Add as a downloaded package
      state.downloadedPackages[action.payload.id] = action.payload;
    });

    builder.addCase(downloadPackage.rejected, (state, action) => {
      // Do nothing for the web version
      if (action.payload === undefined) {
        return;
      }

      delete state.downloadingPackages[action.meta.arg.packageId];
    });

    // Import file

    builder.addCase(updateImportStatus, (state, action) => {
      state.ongoingImport = action.payload;
    });

    builder.addCase(importFileInSketchup.rejected, (state, action) => {
      // Clear the ongoing import if something went wrong
      state.ongoingImport = undefined;
    });

    builder.addCase(waitForImportEnd.rejected, (state, action) => {
      state.ongoingImport = undefined;
    });
  }
});

export default downloadSlice.reducer;
