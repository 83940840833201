import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import * as MUI from '@material-ui/core';
import * as MUILab from '@material-ui/lab';

import Icon from '../lindaleui/components/Icon';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    tile: {
      overflow: 'initial'
    },

    paper: {
      position: 'relative',
      width: '100%',
      paddingTop: '100%',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      overflow: 'hidden'
    },

    folder: {
      paddingTop: 0
    },

    root: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      color: 'inherit',
      textDecoration: 'inherit',
      '$folder &': {
        position: 'initial'
      }
    },

    titleBar: {
      height: '40px',
      width: '100%',
      padding: '0 12px',
      display: 'flex',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.72)',
      fontSize: '13px',
      fontWeight: 500
    },
    titleBarAction: {
      marginRight: '8px',
      display: 'none',
      alignItems: 'center',
      '$paper:hover &': {
        display: 'inline-flex'
      },
      '&:last-of-type': {
        marginRight: 0
      }
    },

    icon: {
      marginRight: '8px',
      color: 'rgba(0, 0, 0, 0.54)'
    },

    name: {
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      marginRight: '8px'
    },

    info: {
      color: '#aaa'
    },

    menu: {
      marginRight: '-8px',
      visibility: 'hidden', // We don't use display:none because we need the element to exist to anchor the menu popover
      '$paper:hover &': {
        visibility: 'visible'
      }
    }
  })
);

type BranchCardProps = MUI.GridListTileProps & {
  name: string;
  info: number;
  href: string;
  icon?: string;
  iconColor?: string;
};

function BranchCard_(props: BranchCardProps) {
  const classes = useStyles(props);

  const [elevation, setElevation] = React.useState(0);

  // Ref to the name to display a tooltip when the name overflows

  const nameRef = React.useRef<HTMLHeadingElement>(null);

  const nameOverflown = (nameRef.current?.scrollWidth ?? 0) > (nameRef.current?.offsetWidth ?? 0);

  const contentElement = (
    <>
      <div className={classes.titleBar}>
        {props.icon && <Icon className={classes.icon} icon={props.icon} color={props.iconColor} />}

        <MUI.Tooltip title={nameOverflown ? props.name : ''}>
          <MUI.Typography variant='subtitle2' ref={nameRef} className={classes.name}>
            {props.name}
          </MUI.Typography>
        </MUI.Tooltip>

        <MUI.Typography variant='caption' className={classes.info}>
          {props.info}
        </MUI.Typography>
      </div>
    </>
  );

  return (
    <MUI.GridListTile style={props.style} classes={{ tile: classes.tile }} className='branch-card'>
      <MUI.Paper
        className={classnames(classes.paper, classes.folder, props.className)}
        elevation={elevation}
        onMouseOver={() => setElevation(2)}
        onMouseOut={() => setElevation(0)}
      >
        <Link to={props.href ? props.href : ''} className={classes.root}>
          {contentElement}
        </Link>
      </MUI.Paper>
    </MUI.GridListTile>
  );
}

export const BranchCard = React.memo(BranchCard_);

type LoadingBranchCardProps = MUI.GridListTileProps & {};

function LoadingBranchCard_(props: LoadingBranchCardProps) {
  const classes = useStyles(props);

  return (
    <MUI.GridListTile style={props.style} classes={{ tile: classes.tile }}>
      <MUI.Paper
        className={classnames(classes.paper, classes.folder, props.className)}
        elevation={0}
      >
        <div className={classes.root}>
          <div className={classes.titleBar}>
            <MUILab.Skeleton variant='text' width='60%' animation='wave' />
          </div>
        </div>
      </MUI.Paper>
    </MUI.GridListTile>
  );
}

export const LoadingBranchCard = React.memo(LoadingBranchCard_);
