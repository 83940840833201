import { configureStore, unwrapResult } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import appReducer from './features/app/appReducer';
import authReducer from './features/auth/authReducer';
import cartReducer from './features/cart/cartReducer';
import contentsReducer from './features/contents/contentsReducer';
import downloadReducer from './features/download/downloadReducer';
import favoritesReducer from './features/favorites/favoritesReducer';
import navigationReducer from './features/navigation/navigationReducer';
import termsReducer from './features/terms/termsReducer';
import updateReducer from './features/update/updateReducer';
import { useCustomSnackbar } from './utils';

const store = configureStore({
  reducer: {
    app: appReducer,
    cart: cartReducer,
    contents: contentsReducer,
    download: downloadReducer,
    favorites: favoritesReducer,
    navigation: navigationReducer,
    terms: termsReducer,
    update: updateReducer,
    auth: authReducer
  },

  middleware: (getDefaultMiddleware) => {
    let middlewares = getDefaultMiddleware({
      // Switch to false to get performance closer to a production build when developing.
      // If immutableCheck is enabled then updates are slower due to our large state objects.
      immutableCheck: true
    });

    // Dev build: add a logger to debug state changes
    // (Redux DevTools for Chrome are great but they cannot be used in the SketchUp extension)

    if (process.env.NODE_ENV !== 'production') {
      middlewares = middlewares.concat(
        createLogger({
          collapsed: true
        })
      );
    }

    return middlewares;
  }
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// TODO would be great if the data were typed

// dispatch() wrapper that catches errors and displays them as notifications
//
// Returns a "fulfilled" status to distinguish outcomes without leaking errors
// (it's easier to handle them all here).
export async function dispatchAndNotify(
  f: any,
  enqueueSnackbar: ReturnType<typeof useCustomSnackbar>['enqueueSnackbar']
): Promise<{ fulfilled: true; data: any } | { fulfilled: false }> {
  try {
    const promise = await store.dispatch(f);

    return { fulfilled: true, data: unwrapResult(promise) };
  } catch (error) {
    const allErrors = extractErrors(error);

    allErrors.forEach((e) => {
      console.error(e);
      enqueueSnackbar(e, 'error');
    });

    return { fulfilled: false };
  }
}

// Nested error type that stores errors that occurred at each action level
//
// We should only use this internally and convert external errors with toActionError().
export type ActionError = {
  scope: string;
  errors: (string | ActionError)[];
};

const isActionError = (x: any): x is ActionError => x.scope && x.errors;

// Converts an error-like object to an ActionError
export function toActionError(scope: string, error: any): ActionError {
  // The error is an ActionError itself: nest it
  if (isActionError(error)) {
    return {
      scope,
      errors: [error]
    };
  }

  // The error has a message (like standard Error objects)
  if (error.message) {
    return {
      scope,
      errors: [error.message]
    };
  }

  // The errors is a string
  if (typeof error === 'string') {
    return {
      scope,
      errors: [error]
    };
  }

  // The errors is an array
  if (Array.isArray(error)) {
    return {
      scope,
      errors: error
    };
  }

  // Not supposed to happen
  return { scope, errors: [] };
}

// Extract individual error messages from an error payload.
//
// We're supposed to only use ActionError but let's play it extra safe and support more shapes.
function extractErrors(errorPayload: any): string[] {
  const unexpected = 'An unexpected error occured, please contact 3dbazaar@lindale.io';

  // Best-case scenario, it's an ActionError: unstack and format errors and
  if (isActionError(errorPayload)) {
    let suberrors: string[] = [];
    errorPayload.errors.forEach((e) => suberrors.push(...extractErrors(e)));
    return suberrors.map((e) => `${errorPayload.scope} > ${e}`); // Prepend with the scope
  }

  // We stored an undefined/null/empty error, should not happen in practice but let's play it safe
  if (!errorPayload) return [unexpected];

  // Basic error shape with message (matches JS Errors, also the format used by Redux for uncaught exceptions)
  if (errorPayload.hasOwnProperty('message')) return extractErrors(errorPayload.message); // Call recursively to extract the value

  // Solo strings
  if (typeof errorPayload === 'string') return [errorPayload];

  // Last resort, this should definitely not happen!
  return [`${unexpected} : ${JSON.stringify(errorPayload)}`];
}

// Helper that unwraps an array of promises into an array of errors
// (will be empty if there are no errors)
//
// Used like so:
//
// const promises = Promise.all([
//    dispatch(a()),
//    dispatch(b())
// ]);
//
// const errors = collectAllPromiseErrors(promises);

export interface ResultShape {
  type: string;
  payload: any;
  meta: any;
}

export function collectAllPromiseErrors(results: (ResultShape | null)[]) {
  const errors: string[] = [];

  results
    .filter((p): p is ResultShape => p !== null) // Remove null promises
    .filter((p) => p.type.endsWith('rejected')) // Filter out successful promises
    .forEach((p) => {
      try {
        unwrapResult(p);
      } catch (e) {
        errors.push(e);
      }
    });

  return errors;
}
