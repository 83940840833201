import { RootState } from '../../store';
import { extractJWTData } from '../../utils';

interface AuthError {
  // Generic error
  message?: string;

  // Field-specific errors
  username?: string;
  email?: string;
  password?: string;
}

export interface UserState {
  authToken: string | undefined;
  refreshToken: string | undefined;

  isRefreshingToken: boolean;

  // needs for granularity? loginInProgress etc?
  operationInprogress: boolean;

  loginErrors: AuthError;

  registerErrors: AuthError;
  registerSuccessMessage?: string;
}

export const initialState: UserState = {
  authToken: undefined,
  refreshToken: undefined,
  isRefreshingToken: false,
  operationInprogress: false,
  loginErrors: {},
  registerErrors: {}
};

export const selectAuthToken = (root: RootState) => root.auth.authToken;
export const selectRefreshToken = (root: RootState) => root.auth.refreshToken;
export const selectIsTokenRefreshing = (root: RootState) => root.auth.isRefreshingToken;

export const selectIsLoggedIn = (root: RootState) => root.auth.authToken !== undefined;

export const selectUserId = (root: RootState): string | undefined => {
  try {
    return root.auth.authToken ? extractJWTData(root.auth.authToken).id : undefined;
  } catch {
    console.error('selectUserId: cannot extract from jwt');
    return undefined;
  }
};

export const selectUserEmail = (root: RootState): string | undefined => {
  try {
    return root.auth.authToken ? extractJWTData(root.auth.authToken).email : undefined;
  } catch {
    console.error('selectUserEmail: cannot extract from jwt');
    return undefined;
  }
};

export const selectAuthInProgress = (root: RootState) => root.auth.operationInprogress;

export const selectLoginResult = (root: RootState) => ({
  errors: root.auth.loginErrors
});

export const selectRegisterResult = (root: RootState) => ({
  errors: root.auth.registerErrors,
  successMessage: root.auth.registerSuccessMessage
});
