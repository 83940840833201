import React from 'react';
import { useSelector } from 'react-redux';
import * as MUI from '@material-ui/core';

import Icon from '../lindaleui/components/Icon';

import { Content } from '../features/contents/contentsTypes';
import { formatPrice } from '../utils';
import { selectPurchasedContentIds } from '../features/contents/contentsState';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    price: {
      color: theme.palette.primary.main,
      marginRight: '8px',
      display: 'inline',
      whiteSpace: 'nowrap'
    }
  })
);

interface Props {
  content: Content;
}

function ContentPrice(props: Props) {
  const classes = useStyles();

  const purchasedContentIds = useSelector(selectPurchasedContentIds);

  if (props.content.type !== 'marketcontent') {
    return null;
  }

  const isPurchased = purchasedContentIds.includes(props.content.id);

  return (
    <MUI.Typography variant='subtitle2' className={classes.price}>
      {isPurchased ? (
        <Icon icon='mdi-check-circle' size={18} tooltip='Purchased' />
      ) : props.content.price > 0 ? (
        formatPrice(props.content.price, props.content.currency)
      ) : (
        'FREE'
      )}
    </MUI.Typography>
  );
}

export default React.memo(ContentPrice);
