import { createSlice } from '@reduxjs/toolkit';

import { pickDownloadLocation } from '../download/downloadActions';
import {
  activateMaintenance,
  setPreferredPackageType,
  setPreferredFileType,
  setHideFullGeometryFiles,
  setHideTheaSubfolders,
  fetchPreferences,
  fetchReadOnly,
  fetchExtensionVersion,
  fetchSketchupVersion
} from './appActions';
import { initialState } from './appState';

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(activateMaintenance, (state, action) => {
      state.maintenance = true;
    });

    // Set individual preferences

    builder.addCase(setPreferredPackageType.fulfilled, (state, action) => {
      state.preferredPackageType = action.payload;
    });

    builder.addCase(setPreferredPackageType.rejected, (state, action) => {
      state.preferredPackageType = undefined;
    });

    builder.addCase(setPreferredFileType.fulfilled, (state, action) => {
      state.preferredFileType = action.payload;
    });

    builder.addCase(setPreferredFileType.rejected, (state, action) => {
      state.preferredFileType = undefined;
    });

    builder.addCase(setHideFullGeometryFiles.fulfilled, (state, action) => {
      state.hideFullGeometryFiles = action.payload;
    });

    builder.addCase(setHideFullGeometryFiles.rejected, (state, action) => {
      state.hideTheaSubfolders = undefined;
    });

    builder.addCase(setHideTheaSubfolders.fulfilled, (state, action) => {
      state.hideTheaSubfolders = action.payload;
    });

    builder.addCase(setHideTheaSubfolders.rejected, (state, action) => {
      state.hideTheaSubfolders = undefined;
    });

    // Pick download location (from the download slice)

    builder.addCase(pickDownloadLocation.fulfilled, (state, action) => {
      state.preferredDownloadLocation = action.payload;
    });

    builder.addCase(pickDownloadLocation.pending, (state, action) => {
      state.preferredDownloadLocation = undefined;
    });

    // Fetch preferences

    builder.addCase(fetchPreferences.fulfilled, (state, action) => {
      state.preferredPackageType = action.payload.preferredPackageType;
      state.preferredFileType = action.payload.preferredFileType;
      state.preferredDownloadLocation = action.payload.preferredDownloadLocation;
      state.hideFullGeometryFiles = action.payload.hideFullGeometryFiles;
      state.hideTheaSubfolders = action.payload.hideTheaSubfolders;
    });

    builder.addCase(fetchPreferences.rejected, (state, action) => {
      state.preferredPackageType = undefined;
      state.preferredFileType = undefined;
      state.preferredDownloadLocation = undefined;
      state.hideFullGeometryFiles = undefined;
      state.hideTheaSubfolders = undefined;
    });

    // Read-only mode

    builder.addCase(fetchReadOnly.fulfilled, (state, action) => {
      state.readOnly = action.payload;
    });

    builder.addCase(fetchReadOnly.rejected, (state, action) => {
      state.readOnly = true; // Set to true to be safe
    });

    // Extension version

    builder.addCase(fetchExtensionVersion.fulfilled, (state, action) => {
      state.extensionVersion = action.payload;
    });

    builder.addCase(fetchExtensionVersion.rejected, (state, action) => {
      state.extensionVersion = undefined;
    });

    // SketchUp version

    builder.addCase(fetchSketchupVersion.fulfilled, (state, action) => {
      state.sketchupVersion = action.payload;
    });

    builder.addCase(fetchSketchupVersion.rejected, (state, action) => {
      state.sketchupVersion = undefined;
    });
  }
});

export default appSlice.reducer;
