import { createSlice } from '@reduxjs/toolkit';

import { fetchTerms } from './termsActions';
import { initialState } from './termsState';

export const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch terms

    builder.addCase(fetchTerms.pending, (state, action) => {
      state.contents = undefined;
      state.loadingStatus = 'loading';
      state.loadingFailed = false;
    });

    builder.addCase(fetchTerms.fulfilled, (state, action) => {
      state.contents = action.payload;
      state.loadingStatus = 'loaded';
    });

    builder.addCase(fetchTerms.rejected, (state, action) => {
      state.loadingStatus = 'unloaded';
      state.loadingFailed = true;
    });
  }
});

export default termsSlice.reducer;
