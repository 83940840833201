import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { HideFullGeometryFiles } from './appTypes';
import { ContentPackageType, ContentType } from '../contents/contentsTypes';

interface AppState {
  maintenance: boolean;
  readOnly: boolean;
  extensionVersion?: string;
  sketchupVersion?: string;

  // Preferences
  preferredPackageType?: ContentPackageType;
  preferredFileType?: ContentType;
  preferredDownloadLocation?: string;

  hideFullGeometryFiles?: HideFullGeometryFiles;
  hideTheaSubfolders?: boolean;
}

export const initialState: AppState = {
  maintenance: false,
  readOnly: false
};

export const selectMaintenanceMode = (root: RootState) => root.app.maintenance;
export const selectReadOnly = (root: RootState) => root.app.readOnly;
export const selectExtensionVersion = (root: RootState) => root.app.extensionVersion;
export const selectSketchupVersion = (root: RootState) => root.app.sketchupVersion;

export const selectPreferences = createSelector(
  [
    (root: RootState) => root.app.preferredPackageType,
    (root: RootState) => root.app.preferredFileType,
    (root: RootState) => root.app.preferredDownloadLocation,
    (root: RootState) => root.app.hideFullGeometryFiles,
    (root: RootState) => root.app.hideTheaSubfolders
  ],
  (
    preferredPackageType,
    preferredFileType,
    preferredDownloadLocation,
    hideFullGeometryFiles,
    hideTheaSubfolders
  ) => ({
    preferredPackageType,
    preferredFileType,
    preferredDownloadLocation,
    hideFullGeometryFiles,
    hideTheaSubfolders
  })
);
