import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as MUI from '@material-ui/core';
import * as MUIIcons from '@material-ui/icons';
import ChipInput, { ChipRenderer } from 'material-ui-chip-input';

import Icon from '../lindaleui/components/Icon';
import IconButton from '../lindaleui/components/IconButton';
import { Tooltip } from '../lindaleui/components/Tooltip';

import {
  selectCurrentLocation,
  selectCurrentPathNames,
  selectCurrentVendor
} from '../features/navigation/navigationState';
import { selectCartContentIds } from '../features/cart/cartState';
import { createLocationString } from '../utils';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    header: {
      zIndex: 1199,

      [theme.breakpoints.up('sm')]: {
        paddingLeft: (props: Props) => (props.drawerWidth ?? 0) + 12 + 'px',
        padding: '0 12px' // Only add horizontal padding with larger screens
      }
    },
    searchBar: {
      flex: 1,
      height: theme.headerHeight - 24,
      background: 'rgba(0, 0, 0, 0.04)',
      borderRadius: '4px',
      padding: '0 12px',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',

      [theme.breakpoints.up('sm')]: {
        marginRight: '8px' // Bonus spacing with the cart icon with larger screens
      }
    },
    searchBarChipInput: {
      flex: 1
    },
    searchBarChipContainer: {
      alignItems: 'center',
      marginBottom: 0,
      flexWrap: 'nowrap',
      overflowX: 'auto'
    },
    searchBarChip: {
      margin: '0 8px 0 0'
    },
    specialChip: {
      backgroundColor: '#CCCCCC', // Slightly darker than keywords
      color: '#222222'
    }
  })
);

interface Props {
  drawerWidth?: number;
  logoWidth?: number;
  onMobileDrawerToggle: () => void;
  onOpenUpdateOverlay?: () => void;
  onOpenCartOverlay: () => void;
}

function Header(props: Props) {
  const classes = useStyles(props);
  const history = useHistory();

  const cartContentIds = useSelector(selectCartContentIds);
  const currentLocation = useSelector(selectCurrentLocation);
  const currentPathNames = useSelector(selectCurrentPathNames);
  const currentVendor = useSelector(selectCurrentVendor);

  // Create chips for the optional filters + keywords

  const chips = [...currentLocation.searchKeywords];

  const hasBranchChip = currentPathNames.length > 0;
  const hasVendorChip = currentVendor?.username;

  if (hasVendorChip) {
    chips.unshift('Vendor: ' + currentVendor?.username);
  }

  if (hasBranchChip) {
    chips.unshift(
      'In: ' + currentPathNames.map((name, i) => name ?? currentLocation.branchPath[i]).join(' / ') // Fallback to the ID
    );
  }

  const onRemovePathFilter = () => {
    // Go back to the current path's root (marketplace or local)
    const newBranchPath =
      currentLocation.branchPath.length > 0 ? [currentLocation.branchPath[0]] : [];
    history.push(createLocationString({ ...currentLocation, branchPath: newBranchPath }));
  };

  const onRemoveVendorFilter = () => {
    history.push(createLocationString({ ...currentLocation, vendorId: undefined }));
  };

  const chipRenderer: ChipRenderer = ({ text, handleDelete, className }, key) => {
    // Use special deletion callbacks for chips other than keywords

    let onDelete: any = handleDelete;

    if (hasBranchChip && key === 0) {
      // Branch name: only deletable if we're not at the root
      onDelete = currentLocation.branchPath.length > 1 ? onRemovePathFilter : undefined;
    }
    if (hasVendorChip && key === (hasBranchChip ? 1 : 0)) {
      onDelete = onRemoveVendorFilter;
    }

    // Render

    const isKeyword = onDelete === handleDelete;

    return (
      <MUI.Chip
        key={key}
        label={text}
        size='small'
        onDelete={onDelete}
        className={classnames(className, 'search-chip', { [classes.specialChip]: !isKeyword })} // add a special class for non-keyword chip
      />
    );
  };

  //

  const onAddSearchKeyword = (keyword: string) => {
    const newKeywords = [...currentLocation.searchKeywords, keyword];
    history.push(createLocationString({ ...currentLocation, searchKeywords: newKeywords }));
  };

  const onRemoveSearchKeyword = (keyword: string) => {
    const newKeywords = [...currentLocation.searchKeywords];

    const index = newKeywords.findIndex((k) => k === keyword);
    if (index >= 0) {
      newKeywords.splice(index, 1);
      history.push(createLocationString({ ...currentLocation, searchKeywords: newKeywords }));
    }
  };

  const isCartEmpty = cartContentIds.length === 0;

  return (
    <MUI.AppBar position='fixed' color='inherit' className={classes.header} elevation={3}>
      <MUI.Toolbar disableGutters>
        {/* Tight viewport: show mobile hamburger */}
        <MUI.Hidden smUp>
          <Tooltip title={'Show menu'}>
            <MUI.IconButton onClick={props.onMobileDrawerToggle}>
              <Icon icon={'mdi-menu'} />
            </MUI.IconButton>
          </Tooltip>
        </MUI.Hidden>

        <div className={classnames(classes.searchBar, 'search-bar')}>
          <ChipInput
            classes={{
              root: classes.searchBarChipInput,
              chipContainer: classes.searchBarChipContainer,
              chip: classes.searchBarChip
            }}
            chipRenderer={chipRenderer}
            placeholder='Search...'
            disableUnderline
            value={chips}
            onAdd={onAddSearchKeyword}
            onDelete={onRemoveSearchKeyword}
            newChipKeyCodes={[9, 13, 188] /*[tab, enter, comma]*/}
            blurBehavior='add'
          />

          {(currentPathNames.length > 1 || chips.length > 1) && (
            <IconButton
              icon='mdi-close'
              tooltip='Clear'
              onClick={() =>
                history.push(
                  createLocationString({
                    ...currentLocation,
                    searchKeywords: [],
                    vendorId: undefined
                  })
                )
              }
            />
          )}
        </div>

        {props.onOpenUpdateOverlay && (
          <Tooltip title='A new version is available'>
            <MUI.IconButton onClick={props.onOpenUpdateOverlay}>
              <MUIIcons.NewReleases color='primary' />
            </MUI.IconButton>
          </Tooltip>
        )}

        {/* <Tooltip title={'Settings'} >
                    <MUI.IconButton><Icon icon='mdi-cog-outline'/></MUI.IconButton>
                </Tooltip> */}

        <MUI.Tooltip
          title={
            isCartEmpty ? 'Your cart is empty' : `Your cart contains ${cartContentIds.length} items`
          }
        >
          <div>
            {' '}
            {/* div required for the tooltip to work with a disabled button */}
            <MUI.IconButton
              className='cart-button'
              disabled={isCartEmpty}
              onClick={props.onOpenCartOverlay}
            >
              <MUI.Badge badgeContent={cartContentIds.length} color='primary'>
                <Icon icon='mdi-cart-outline' />
              </MUI.Badge>
            </MUI.IconButton>
          </div>
        </MUI.Tooltip>
      </MUI.Toolbar>
    </MUI.AppBar>
  );
}

export default React.memo(Header);
