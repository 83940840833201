import axiosRetry from 'axios-retry';
import React from 'react';
import qs from 'qs';
import axios, { AxiosError, AxiosResponse } from 'axios';
import * as MUI from '@material-ui/core';
import * as History from 'history';
import * as zod from 'zod';
import { extname } from 'path';
import { OptionsObject, useSnackbar, VariantType } from 'notistack';
import { ZodRawShape } from 'zod';

//@ts-ignore
import Bridge from 'sketchup-bridge';

import IconButton from './lindaleui/components/IconButton';

import { RENDERERS_DATA } from './constants';
import store from './store';
import { ImageSet, isContentType, Renderer, isRenderer } from './features/contents/contentsTypes';
import { activateMaintenance } from './features/app/appActions';
import {
  NavigationState,
  SearchFiltersContentType,
  SortCriteria,
  DEFAULT_FILTERS
} from './features/navigation/navigationState';
import _ from 'lodash';

// Generates a validation schema that matches the JSend specifications
// https://github.com/omniti-labs/jsend
export function jsendSchema<SuccessType extends ZodRawShape, FailType extends ZodRawShape>(
  successSchema: SuccessType,
  failSchema: FailType
) {
  return zod.union([
    zod.object({
      status: zod.literal('success'),
      data: zod.object(successSchema)
    }),

    zod.object({
      status: zod.literal('fail'),
      data: zod.object(failSchema)
    }),

    zod.object({
      status: zod.literal('error'),
      message: zod.string(),
      data: zod.object({}).optional()
    })
  ]);
}

// Extracts the error message from a non-successful JSend result
export function extractJsendError(
  jsend: { status: 'error'; message: string } | { status: 'fail'; data?: any }
): string {
  return jsend.status === 'error' ? jsend.message : JSON.stringify(jsend.data);
}

let webApiCallIndex = 0; // Index to track API requests & responses

export async function webApiAsync<DataType>({
  method,
  endpoint,
  jwt,
  data,
  schema,
  validStatuses,
  retry
}: {
  method: 'get' | 'post';
  endpoint: string;
  schema: zod.Schema<DataType>;
  data?: any;
  jwt?: string;

  // Valid status overrides, to avoid some error-related codes being treated as request errors
  validStatuses?: number[];

  // Retry the request if it fails due to network errors
  retry?: {
    times: number;
    delay: number;
  };
}): Promise<AxiosResponse<DataType>> {
  const callIndex = webApiCallIndex++;

  console.log(`web API call #${callIndex}:`, endpoint, data ?? '(no arguments)');

  // Retrieve the API URL
  //
  // This should be used for local testing only. It can be defined in a .env.local file at the root of the project.
  //
  // Do NOT set this as an environment variable in Heroku:
  // The URL would be baked into the static JS when built on the "stage" Heroku app, then promoted as-is to the "prod" app.
  // So on Heroku, we let it fall back to the production API.
  //
  // The REACT_APP_ prefix is required by create-react-app
  //
  // https://devcenter.heroku.com/articles/config-vars
  // https://create-react-app.dev/docs/adding-custom-environment-variables/

  const apiUrl = process.env.REACT_APP_LINDALE_API_BASE_URL ?? 'https://api.lindale.io/'; // Fallback to the production API

  if (!apiUrl) {
    throw new Error('Lindalë API URL missing from the environment');
  }

  // Call

  try {
    // Override the default valid codes in order not to treat some status codes as errors

    const defaultValidation = (status: number) => status >= 200 && status < 300;

    const validateStatus = validStatuses
      ? (status: number) => defaultValidation(status) || validStatuses.includes(status)
      : defaultValidation;

    const axiosInstance = axios.create();

    // Retry failing requests if needed
    //
    // Note: we specify a custom retry condition because axios-retry
    // seems to have trouble detecting network errors in their own
    // implementation.
    if (retry) {
      axiosRetry(axiosInstance, {
        retries: retry.times,
        retryDelay: () => retry.delay,
        retryCondition: isNetworkError
      });
    }

    const response = await axiosInstance({
      method: method,
      url: apiUrl + endpoint,
      headers: jwt ? { Authorization: 'Bearer ' + jwt } : undefined,
      data,
      validateStatus,

      // Specify a reasonnable timeout to prevent the app hanging
      timeout: 30_000
    });

    console.log(`web API response #${callIndex}:`, endpoint, response);

    schema.parse(response.data);

    return response;
  } catch (error) {
    if (error instanceof zod.ZodError) {
      console.log(JSON.stringify(error.errors, null, 2));
    }

    // Check if a maintenance is in progress
    if (error.response?.status === 503) {
      store.dispatch(activateMaintenance());
      throw new Error('maintenance in progress');
    }

    console.error(`web API error #${callIndex}:`, endpoint, error, error.response);

    throw error;
  }
}

export function isNetworkError(error: AxiosError<any>) {
  return error.message.toLowerCase().includes('network error');
}

// Generates a validation schema that matches the data received from SketchUp
export function sketchupSchema<SucessType extends zod.ZodTypeAny>(successSchema: SucessType) {
  return zod.union([
    // success=true + data + optional errors
    zod.object({
      success: zod.literal(true),
      data: successSchema,
      errors: zod.array(zod.string()).optional()
    }),

    // success=false + errors
    zod.object({
      success: zod.literal(false),
      errors: zod.array(zod.string())
    })
  ]);
}

let sketchupCallIndex = 0; // Index to track API requests & responses

export async function sketchupAsync<DataType>({
  methodName,
  args,
  schema
}: {
  methodName: string;
  schema: zod.Schema<DataType>;
  args?: any;
}): Promise<DataType> {
  const callIndex = sketchupCallIndex++;

  console.log(`SketchUp call #${callIndex}: ${methodName}`, args);

  try {
    // Contrarily to the web API calls, we only throw when something unexpected happens!
    //
    // The calling code is responsible for checking the response's success.

    return await Bridge.get(methodName, ...(args ?? [])).then(
      (response: any) => {
        console.log(`SketchUp resolved response #${callIndex}: ${methodName}`, response);

        return schema.parse(response);
      },
      (response: any) => {
        console.log(`SketchUp rejected response #${callIndex}: ${methodName}`, response);

        return schema.parse(response);
      }
    );
  } catch (error) {
    console.error(`SketchUp error #${callIndex}: ${methodName}`, error);

    throw error;
  }
}

// Triggers downloading the given URL
export function downloadUrl(url: string) {
  const a = document.createElement('a');
  a.href = url;

  const fileName = url.split('/').pop();
  if (fileName) {
    a.download = fileName;
  }

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
  a.remove();
}

// Creates an URL from a location.
export function createLocationString(location: NavigationState) {
  const path = '/' + location.branchPath.join('/');

  // Use simpler names as they're visible in the URL.
  // Discard default-valued filters.
  const queryParams = {
    content: location.contentPath.length > 0 ? location.contentPath.join('-') : undefined,
    vendor: location.vendorId,
    search:
      location.searchKeywords.length > 0
        ? encodeURIComponent(location.searchKeywords.join('-')) // Encode to support any character entered by users
        : undefined,
    free:
      location.searchFilters.free !== DEFAULT_FILTERS.free
        ? location.searchFilters.free
        : undefined,
    minprice:
      location.searchFilters.priceRange.min !== DEFAULT_FILTERS.priceRange.min
        ? location.searchFilters.priceRange.min
        : undefined,
    maxprice:
      location.searchFilters.priceRange.max !== DEFAULT_FILTERS.priceRange.max
        ? location.searchFilters.priceRange.max
        : undefined,
    contenttypes:
      location.searchFilters.contentTypes !== DEFAULT_FILTERS.contentTypes &&
      location.searchFilters.contentTypes &&
      location.searchFilters.contentTypes.length > 0
        ? location.searchFilters.contentTypes.join('-')
        : undefined,
    renderers:
      location.searchFilters.renderers !== DEFAULT_FILTERS.renderers &&
      location.searchFilters.renderers &&
      location.searchFilters.renderers.length > 0
        ? location.searchFilters.renderers.join('-')
        : undefined,
    sort: location.sortCriteria !== SortCriteria.DEFAULT ? location.sortCriteria : undefined
  };

  const query = qs.stringify(queryParams);

  return `${path}${query.length > 0 ? `?${query}` : ''}`;
}

export function extractLocationString(location: History.Location): NavigationState {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  return {
    branchPath: location.pathname.split('/').filter(Boolean), // .filter(Boolean) to remove empty entries due to leading and trailing slashes
    contentPath: typeof params.content === 'string' ? params.content.split('-') : [],
    vendorId: typeof params.vendor === 'string' ? params.vendor : undefined,
    searchKeywords:
      typeof params.search === 'string' ? decodeURIComponent(params.search).split('-') : [],
    searchFilters: {
      free: params.free === 'true',
      priceRange: {
        min:
          typeof params.minprice === 'string'
            ? parseInt(params.minprice)
            : DEFAULT_FILTERS.priceRange.min,
        max:
          typeof params.maxprice === 'string'
            ? parseInt(params.maxprice)
            : DEFAULT_FILTERS.priceRange.max
      },
      contentTypes:
        typeof params.contenttypes === 'string'
          ? decodeURIComponent(params.contenttypes)
              .split('-')
              .filter(isContentType)
              .map((ct) => ct as SearchFiltersContentType)
          : DEFAULT_FILTERS.contentTypes,
      renderers:
        typeof params.renderers === 'string'
          ? decodeURIComponent(params.renderers)
              .split('-')
              .filter(isRenderer)
              .map((r) => r as Renderer)
          : DEFAULT_FILTERS.renderers,
      groupPackagesIntoMarketplaceContent: DEFAULT_FILTERS.groupPackagesIntoMarketplaceContent
    },
    sortCriteria:
      typeof params.sort === 'string' ? (params.sort as SortCriteria) : SortCriteria.DEFAULT
  };
}

export function getBestImageRes(
  imageUrls: ImageSet['urls'],
  size: number
): keyof ImageSet['urls'] | undefined {
  // Otherwise, use the closest one available (prefer bigger than smaller)

  const resolutions = Object.entries(imageUrls)
    .filter(([res, image]) => image !== undefined) // Remove missing sizes
    .map(([res, image]): [number, string | undefined] => [parseInt(res), image]); // Convert size to number

  let bestResolution = resolutions.find((res) => res[0] >= size);

  // If available images are too small, make do with the largest one

  if (bestResolution === undefined) {
    bestResolution = _.maxBy(resolutions, (res) => res[0]);
  }

  return bestResolution![0] as keyof ImageSet['urls'];
}

export function getBestImageUrl(imageUrls: ImageSet['urls'], size: number): string | undefined {
  const res = getBestImageRes(imageUrls, size);

  return res ? imageUrls[res] : undefined;
}

export function preloadImage(url: string) {
  const img = new Image();
  img.src = url;
}

export function getFileTypefromName(fileName: string): 'model' | 'composition' | undefined {
  if (fileName) {
    switch (extname(fileName)) {
      case '.skp':
        return 'model';
      case '.skatter':
        return 'composition';
    }
  }

  return undefined;
}

export function getRendererIcons(renderers: Renderer[], size: number) {
  return renderers.map((renderer) => {
    const data = RENDERERS_DATA[renderer];

    return (
      <MUI.Tooltip key={data.name} title={data.name} enterDelay={0}>
        <img
          src={data.img}
          style={{
            maxWidth: `${size}px`,
            maxHeight: `${size}px`,
            marginLeft: '4px'
          }}
          alt={data.name}
        />
      </MUI.Tooltip>
    );
  });
}

export function formatPrice(price: number, currency: string) {
  const p = price > 0 ? `${price.toFixed(2)}` : '0.00';

  switch (currency) {
    case 'USD':
      return `$ ${p}`;
    case 'EUR':
      return `${p} €`;
    default:
      return p;
  }
}

export function isExtension() {
  return process.env.REACT_APP_IS_LOCAL;
}

// Converts a null value to undefined or returns the same value.
//
// This is useful after a JSON conversion since it doesn't output undefined.
// Also when getting items from localStorage with keys that do not exist.
export function nullToUndefined<T>(x: T | null): T | undefined {
  return x === null ? undefined : x;
}

export function extractJWTData(
  jwt: string
): {
  id: string;
  username: string;
  email: string;
  exp: number;
  termsAcceptances: number[];
} {
  return JSON.parse(Base64.decode(jwt.split('.')[1]));
}

// Associative type that returns T or undefined for invalid keys
//
// Record<T> always returns T so we would lost some type safety.
// Map<T> is not serializable for Redux.
export type UMap<T> = { [key: string]: T | undefined };

export function setCookie(name: string, value: string, topDomain = false) {
  let d = new Date();
  d.setTime(d.getTime() + 10 * 365 * 24 * 60 * 60 * 1000); // 10 years
  const expires = d.toUTCString();
  const domain = topDomain
    ? window.location.hostname.split('.').slice(-2).join('.')
    : window.location.hostname;
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; domain=${domain}; path=/`;
}

export function getCookie(name: string) {
  name = name + '=';
  const allCookies = decodeURIComponent(document.cookie);
  const cookieArray = allCookies.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
}

export function deleteCookie(name: string, topDomain = false) {
  const domain = topDomain
    ? window.location.hostname.split('.').slice(-2).join('.')
    : window.location.hostname;
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
}

// Hook that provides a customized snackbar
export function useCustomSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const customEnqueueSnackbar = React.useCallback(
    (message: string, variant: VariantType, options: OptionsObject = {}) => {
      return enqueueSnackbar(message, {
        variant,

        // Add a close button
        action: (key) => (
          <IconButton
            icon='mdi-close'
            color='inherit'
            size={18}
            onClick={() => closeSnackbar(key)}
          />
        ),

        // Persist errors
        persist: variant === 'error' || variant === 'warning',

        // Passed options will override the defaults above
        ...options
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );

  return {
    enqueueSnackbar: customEnqueueSnackbar,
    closeSnackbar
  };
}

// Custom hook to track previous values
export function usePrevious(value: any) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
