import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import semver from 'semver';
import sleep from 'sleep-promise';
import * as zod from 'zod';

import { ActionError, RootState, toActionError } from '../../store';
import {
  isExtension,
  sketchupSchema,
  sketchupAsync,
  jsendSchema,
  webApiAsync,
  extractJsendError
} from '../../utils';
import { fetchExtensionVersion } from '../app/appActions';
import { VersionSchema, UpdateStatus, Version, UpdateStatusSchema } from './updateState';

export const updateVersionDownload = createAction<{ status: UpdateStatus; progress: number }>(
  'update/updateDownload'
);

export const checkNewVersion = createAsyncThunk<
  Version[],
  void,
  { state: RootState; rejectValue: ActionError }
>('update/checkNewVersion', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot check new versions out of the extension');
    }

    // First retrieve the current extension version
    // (might have already been fetched)

    const versionNumber: string =
      thunkAPI.getState().app.extensionVersion ??
      (await thunkAPI.dispatch(fetchExtensionVersion()).then(unwrapResult));

    // Then check if a new version is available

    const newVersionSchema = jsendSchema(
      {
        versions: zod.array(VersionSchema)
      },
      {}
    );

    const newVersionsResponse = await webApiAsync<zod.infer<typeof newVersionSchema>>({
      method: 'get',
      endpoint: 'bazaarextension/v1/versions/',
      schema: newVersionSchema
    });

    if (newVersionsResponse.data.status !== 'success') {
      throw extractJsendError(newVersionsResponse.data);
    }

    const versions = newVersionsResponse.data.data.versions;

    return versions
      .filter((v) => semver.gt(v.versionNumber, versionNumber))
      .sort((v1, v2) => semver.compareBuild(v1.versionNumber, v2.versionNumber));
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('check new version', error));
  }
});

export const installNewVersion = createAsyncThunk<
  void,
  void,
  { state: RootState; rejectValue: ActionError }
>('update/installNewVersion', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot install new versions out of the extension');
    }

    const newVersions = thunkAPI.getState().update.newVersions;

    if (newVersions.length === 0) {
      throw new Error('No new versions to install');
    }

    // Start the install

    const latestVersion = newVersions[newVersions.length - 1];

    const installSchema = sketchupSchema(zod.null());

    const installResponse = await sketchupAsync({
      methodName: 'installNewVersion',
      args: [latestVersion.downloadLink],
      schema: installSchema
    });

    if (!installResponse.success) {
      throw installResponse.errors;
    }

    // Poll SketchUp for the update progress

    const step = 200;

    const progressSchema = sketchupSchema(
      zod.object({
        status: UpdateStatusSchema,
        progress: zod.number()
      })
    );

    while (true) {
      const progressResponse = await sketchupAsync({
        methodName: 'getUpdateProgress',
        schema: progressSchema
      });

      if (!progressResponse.success) {
        throw progressResponse.errors;
      }

      const status = progressResponse.data.status;
      const progress = progressResponse.data.progress;

      thunkAPI.dispatch(updateVersionDownload({ status, progress }));

      if (status === 'downloadFailed') {
        throw new Error('download failed, please try again later');
      } else if (status === 'installFailed') {
        throw new Error('install failed, please try again later');
      } else if (status === 'downloading' || status === 'installing') {
        // Wait a bit before polling again
        await sleep(step);
      } else if (status === 'complete') {
        // Stop polling
        break;
      }
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('install new version', error));
  }
});
