import React from 'react';
import { Blurhash } from 'react-blurhash';
import * as MUI from '@material-ui/core';

import { Content } from '../features/contents/contentsTypes';
import { getBestImageUrl } from '../utils';

import backgroundCube from '../img/cube-outline.svg';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    avatar: {
      border: '1px solid rgba(0,0,0,0.12)', // Light border for images with white backgrounds
      backgroundColor: '#fff',
      position: 'relative'
    },
    avatarStackedBottom: {
      position: 'absolute',
      left: '12px'
    },
    avatarStackedMiddle: {
      position: 'absolute',
      left: '6px',
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
    },
    avatarStackedTop: {
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    },
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    blurHash: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    }
  })
);

interface Props {
  content: Content;
  stacked?: boolean;
}

function ContentAvatar(props: Props) {
  const { content, stacked } = props;
  const classes = useStyles();

  const imageUrl =
    content.type === 'marketcontent' || content.type === 'localcontent'
      ? content.images?.[0]?.urls
        ? getBestImageUrl(content.images?.[0]?.urls, 64)
        : undefined
      : content.image;

  const blurHash =
    (content.type === 'localcontent' || content.type === 'marketcontent') &&
    content.images[0]?.blurHash
      ? content.images[0].blurHash
      : undefined;

  return (
    <>
      {stacked && (
        <>
          <MUI.Avatar classes={{ root: `${classes.avatar} ${classes.avatarStackedBottom}` }}>
            <></>
          </MUI.Avatar>
          <MUI.Avatar classes={{ root: `${classes.avatar} ${classes.avatarStackedMiddle}` }}>
            <></>
          </MUI.Avatar>
        </>
      )}
      <MUI.Avatar classes={{ circle: `${classes.avatar} ${stacked && classes.avatarStackedTop}` }}>
        {blurHash && (
          <Blurhash hash={blurHash} width='100%' height='100%' className={classes.blurHash} />
        )}
        {imageUrl && <img src={imageUrl ?? backgroundCube} className={classes.image} alt='' />}
      </MUI.Avatar>
    </>
  );
}

export default React.memo(ContentAvatar);
