import { basename, dirname, join } from 'path';
import _ from 'lodash';
import * as zod from 'zod';

import { CONTENT_TYPES, CONTENT_TYPES_DATA, RENDERERS } from '../../constants';
import { Status } from '../../types';
import { UMap } from '../../utils';
import { createBranchInfo } from './contentsState';

// Image set

const ImageSetSchema = zod.object({
  blurHash: zod.string().optional(),
  ratio: zod.number().optional(),
  urls: zod.object({
    64: zod.string().optional(),
    128: zod.string().optional(),
    256: zod.string().optional(),
    512: zod.string().optional(),
    1024: zod.string().optional(),
    2048: zod.string().optional()
  })
});

export type ImageSet = zod.infer<typeof ImageSetSchema>;

// Vendor

export const VendorSchema = zod.object({
  id: zod.string(),
  username: zod.string(),
  avatar: ImageSetSchema.optional(),
  cover: ImageSetSchema.optional(),
  bio: zod.string().nullable(),
  website: zod.string().nullable(),
  publicEmail: zod.string().nullable(),
  contentCount: zod.number()
});

export type Vendor = zod.infer<typeof VendorSchema>;

// Renderers

const RendererSchema = zod.enum([...RENDERERS]);

export type Renderer = zod.infer<typeof RendererSchema>;

export function isRenderer(str: string) {
  return RENDERERS.includes(str as Renderer);
}

// Content type

export const ContentTypeSchema = zod.enum([...CONTENT_TYPES]);

export type ContentType = zod.infer<typeof ContentTypeSchema>;

export function isContentType(str: string) {
  return CONTENT_TYPES.includes(str as ContentType);
}

// Content package

export const ContentPackageTypeSchema = zod.enum(['default', 'highpoly', 'lowpoly', 'proxy']);

export type ContentPackageType = zod.infer<typeof ContentPackageTypeSchema>;

const ContentPackageFileSchema = zod.object({
  name: zod.string(),

  // Added by the extension
  path: zod.string().optional(),
  exists: zod.boolean().optional()
});

export type ContentPackageFile = zod.infer<typeof ContentPackageFileSchema>;

export const ContentPackageSchema = zod.object({
  id: zod.string(),
  packageType: ContentPackageTypeSchema,
  renderers: zod.array(RendererSchema),
  files: zod.array(ContentPackageFileSchema),
  size: zod.number()
});

export type ContentPackage = zod.infer<typeof ContentPackageSchema>;

// Marketplace category, will be converted to a branch

const MarketplaceCategorySchemaBase = zod.object({
  id: zod.string(),
  name: zod.string(),
  contentAmount: zod.number()
});

interface IMarketplaceCategory extends zod.infer<typeof MarketplaceCategorySchemaBase> {
  children: IMarketplaceCategory[];
}

export const MarketplaceCategorySchema: zod.ZodSchema<IMarketplaceCategory> = MarketplaceCategorySchemaBase.merge(
  zod.object({
    children: zod.lazy(() => zod.array(MarketplaceCategorySchema))
  })
);

export type MarketplaceCategory = zod.infer<typeof MarketplaceCategorySchema>;

export function marketplaceCategoriesToBranches(
  categories: MarketplaceCategory[]
): { rootIds: string[]; branches: UMap<BranchInfo> } {
  const rootIds = categories.map((c) => c.id);

  // Convert the categories recursively

  let branches: UMap<BranchInfo> = {};

  function toBranch(category: MarketplaceCategory) {
    // Add a special icon for the Skatter contents
    const icon = category.id === 'skatter' ? CONTENT_TYPES_DATA['composition'].icon : undefined;
    const iconColor =
      category.id === 'skatter' ? CONTENT_TYPES_DATA['composition'].iconColor : undefined;

    const branch = createBranchInfo({
      name: category.name,
      contentAmount: category.contentAmount,
      icon,
      iconColor,
      childrenIds: category.children.map((c) => c.id)
    });

    branches[category.id] = branch;

    // Add children

    category.children.forEach(toBranch);
  }

  categories.forEach(toBranch);

  return { rootIds, branches };
}

// Marketplace content coming from the API
// (see the BranchContent type for what's actually used in the state)
//
// A bit complicated due to recursiveness and the distinction between bundles & assets :/
// https://github.com/colinhacks/zod#recursive-types

const MarketplaceContentSchemaBase = zod.object({
  id: zod.string(),
  name: zod.string(),
  description: zod.string(),
  contentTypes: zod.array(ContentTypeSchema),
  vendor: zod.object({
    id: zod.string(),
    name: zod.string(),
    avatar: ImageSetSchema.optional()
  }),
  price: zod.number(),
  currency: zod.string(),
  tags: zod.array(zod.string()),
  renderers: zod.array(RendererSchema),
  materialRenderers: zod.array(RendererSchema),
  proxyRenderers: zod.array(RendererSchema),
  images: zod.array(ImageSetSchema),
  packages: zod.array(ContentPackageSchema).optional()
});

// Bundles contain assets and assets might reference bundles.
// The referenced bundles/assets are stripped down to save on bandwidth and because of Algolia's max-length

const MarketplaceSubContentSchema = zod.object({
  id: zod.string(),
  name: zod.string(),
  description: zod.string(),
  images: zod.array(ImageSetSchema)
});

export type SubContent = zod.infer<typeof MarketplaceSubContentSchema>;

// Top-level asset

interface IMarketplaceContentAsset extends zod.infer<typeof MarketplaceContentSchemaBase> {
  bundles?: SubContent[];
  assets?: undefined;
}

const MarketplaceContentAssetSchema: zod.ZodSchema<IMarketplaceContentAsset> = MarketplaceContentSchemaBase.merge(
  zod.object({
    bundles: zod.lazy(() => zod.array(MarketplaceSubContentSchema).optional()),
    assets: zod.undefined()
  })
);

// Top-level bundle

interface IMarketplaceContentBundle extends zod.infer<typeof MarketplaceContentSchemaBase> {
  assets: SubContent[];
}

const MarketplaceContentBundleSchema: zod.ZodSchema<IMarketplaceContentBundle> = MarketplaceContentSchemaBase.merge(
  zod.object({
    assets: zod.lazy(() => zod.array(MarketplaceSubContentSchema))
  })
);

// Final type

export const MarketplaceContentSchema = zod.union([
  MarketplaceContentAssetSchema,
  MarketplaceContentBundleSchema
]);

export type MarketplaceContent = zod.infer<typeof MarketplaceContentSchema>;

// Converts a MarketplaceContent coming from the API to a MarketContent usable within the app.
//
// This returns the converted content and an array of "side contents" because a single MarketplaceContent
// can contain other contents (eg. other assets if it's a bundle, some bundles if it's an asset) that can be
// useful to the caller depending on the context.
export function marketplaceContentToBranchContent(
  marketContent: MarketplaceContent
): MarketContent {
  return {
    type: 'marketcontent',
    id: marketContent.id,
    name: marketContent.name,
    description: marketContent.description,
    vendor: marketContent.vendor,
    price: marketContent.price,
    currency: marketContent.currency,
    tags: marketContent.tags,
    contentTypes: marketContent.contentTypes,
    renderers: marketContent.renderers,
    materialRenderers: marketContent.materialRenderers,
    proxyRenderers: marketContent.proxyRenderers,
    images: marketContent.images,
    packages: marketContent.packages ?? [],
    assets: (marketContent as any).contentTypes.includes('bundle')
      ? (marketContent as IMarketplaceContentBundle).assets
      : undefined,
    bundles: (marketContent as any).bundles
      ? (marketContent as IMarketplaceContentAsset).bundles
      : undefined,
    status: 'loaded',
    errors: []
  };
}

// Local folder, will be converted to a branch

export const LocalFolderSchema = zod.object({
  children: zod.array(zod.string()),
  fileCount: zod.number(),
  scanned: zod.boolean(),
  date: zod.number(),
  errors: zod.array(zod.string()).optional(), // undefined if there are no errors
  packageName: zod.string().optional() // If the folder is a package folder
});

export type LocalFolder = zod.infer<typeof LocalFolderSchema>;

export function localFoldersToBranches(
  roots: string[],
  folders: Record<string, LocalFolder>
): {
  rootIds: string[];
  branches: UMap<BranchInfo>;
} {
  // Encode the root IDs

  const rootIds = roots.map((f) => Base64.encode(f));

  // Convert to branches

  let branches: UMap<BranchInfo> = {};

  const ignoredBranches: string[] = [];

  Object.entries(folders).forEach(([folderPath, folder]) => {
    const folderId = Base64.encode(folderPath);

    // Ignore package folders + extracted .skatter files
    // TODO could be option, in that case this should be handled when rendering
    if (folder.packageName || folderPath.endsWith('.skatter_files')) {
      ignoredBranches.push(folderId);
      return;
    }

    branches[folderId] = createBranchInfo({
      name: basename(folderPath),
      // Encode children IDs too (must be reconstructed "parent path + child name")
      childrenIds: folder.children.map((f) => {
        let subFolderPath = join(folderPath, f);

        // join() removes double slashes. So we need to add it back to preserve network paths.
        if (folderPath.startsWith('//')) {
          subFolderPath = '/' + subFolderPath;
        }
        if (folderPath.startsWith('\\\\')) {
          subFolderPath = '\\' + subFolderPath;
        }

        return Base64.encode(subFolderPath);
      }),
      childrenStatus: folder.scanned ? 'loaded' : 'loading',
      contentAmount: folder.fileCount
    });
  });

  // Remove ignored branches from the other branches' children

  Object.entries(branches).forEach(([branchId, branch]) => {
    branch!.childrenIds = _.difference(branch!.childrenIds, ignoredBranches);
  });

  return { rootIds, branches };
}

// Local content

const LocalFileBaseSchema = zod.object({
  scanned: zod.boolean(),
  date: zod.number(),
  errors: zod.array(zod.string()).optional(), // undefined if there are no errors

  path: zod.string() // File path inside the object since results are returned as arrays instead of path/file records
});

const RegularFileSchema = LocalFileBaseSchema.merge(
  zod.object({
    packagePath: zod.string().optional(), // Only if part of a package
    size: zod.number().optional(), // most data pieces are missing until scanned
    name: zod.string().optional(),
    description: zod.string().optional(),
    thumbnail: zod.string().optional() // TODO do .skatter have that too?
  })
);

const LocalFileSkpSchema = RegularFileSchema.merge(
  zod.object({
    type: zod.literal('skp'),
    faces: zod.number().optional(), // missing until scanned // TODO not used right now
    skpVersion: zod.string().optional()
  })
);

const LocalFileSkatterSchema = RegularFileSchema.merge(
  zod.object({
    type: zod.literal('skatter') // TODO do skatter have names and descs?
  })
);

const LocalFileBazaarContentSchema = LocalFileBaseSchema.merge(
  zod.object({
    type: zod.literal('bazaarcontent'),
    manifest: zod.object({
      content: MarketplaceContentSchema,
      bazaarVersion: zod.string().optional() // only if downloaded by the extension
    }),
    packages: zod.record(
      zod.object({
        // Defined for "clean" packages self-contained in a subfolder.
        // Otherwise the files are scattered with other unrelated files next to the content.
        folder: zod.string().optional(),

        // File names and if they exist
        files: zod.record(zod.boolean())
      })
    )
  })
);

export const LocalFileSchema = zod.union([
  LocalFileSkpSchema,
  LocalFileSkatterSchema,
  LocalFileBazaarContentSchema
]);

export type LocalFile = zod.infer<typeof LocalFileSchema>;

export function localFilesToBranchContents(localFiles: LocalFile[]): Content[] {
  return localFiles.map((localFile) => {
    const path = localFile.path;

    if (localFile.type === 'bazaarcontent') {
      // Convert the core data (simple file existence record) to ContentPackages
      // so that it has the same shape as downloaded packages

      const availablePackages: UMap<ContentPackage> = {};

      Object.entries(localFile.packages).forEach(([packId, pack]) => {
        // Look for the packages data in the content
        const originalPackage = localFile.manifest.content.packages?.find((p) => p.id === packId);

        if (!originalPackage) {
          console.error('cannot find original package', packId);
          return;
        }

        const contentPackage: ContentPackage = {
          ...originalPackage,

          // Replace with the checked file data

          files: Object.entries(pack.files).map(([name, exists]) => ({
            name,
            exists,
            path: pack.folder ? join(dirname(path), pack.folder, name) : join(dirname(path), name)
          }))
        };

        availablePackages[packId] = contentPackage;
      });

      // Convert the content

      return {
        ...marketplaceContentToBranchContent(localFile.manifest.content),

        type: 'localcontent',
        id: Base64.encode(path), // special ID to distinguish the marketplace content and this local content
        marketplaceId: localFile.manifest.content.id,
        availablePackages
      };
    } else if (localFile.type === 'skp') {
      return {
        type: 'skp',
        id: Base64.encode(path),
        image: localFile.thumbnail,
        size: localFile.size,
        skpVersion: localFile.skpVersion,
        name: localFile.name,
        description: localFile.description,
        errors: localFile.errors ?? [],
        status: localFile.scanned ? 'loaded' : 'loading'
      };
    } // localFile.type === 'skatter'
    else {
      return {
        type: 'skatter',
        id: Base64.encode(path),
        image: localFile.thumbnail,
        name: localFile.name,
        description: localFile.description,
        errors: localFile.errors ?? [],
        status: localFile.scanned ? 'loaded' : 'loading'
      };
    }
  });
}

// Content

interface ContentBase {
  id: string;
  status: Status;
  errors: string[];

  // Common metadata
  name?: string;
  description?: string;
}

export interface MarketContent extends ContentBase {
  type: 'marketcontent';

  price: number;
  currency: string;
  vendor: { id: string; name: string; avatarUrl?: string };

  images: ImageSet[];
  tags: string[];
  contentTypes: ContentType[];
  renderers: Renderer[];
  materialRenderers: Renderer[];
  proxyRenderers: Renderer[];

  packages: ContentPackage[];

  // We store the whole bundles/assets here instead of only storing IDs
  // and then referring to the global data store.
  // This allows a content to be self-contained and to ensure that all
  // of its data is available as soon as it is loaded.

  assets?: SubContent[]; // Contained assets if this is a bundle
  bundles?: SubContent[]; // Associated bundles if this an asset
}

export interface LocalContent extends Omit<MarketContent, 'type'> {
  type: 'localcontent';

  // Downloaded packages, not the same as the complete package list in the original content's "packages"
  availablePackages: UMap<ContentPackage>;

  // ID of the original content for downloaded contents
  // whose ID is computed from their path but we still need
  // the original content ID to download packages
  marketplaceId: string;
  // TODO remove if download API does not need contentID anymore!
}

export interface LocalSkp extends ContentBase {
  type: 'skp';

  size?: number;
  skpVersion?: string;
  image?: string;
}

export interface LocalSkatter extends ContentBase {
  type: 'skatter';
  image?: string;
}

export type Content = ContentBase & (MarketContent | LocalSkp | LocalSkatter | LocalContent);

export function isContentLocal(content: Content) {
  return content.type !== 'marketcontent';
}

// A branch contains unified contents
// (branch = category for the marketplace / folder for the local library)
//
// we split the branch "info" and its "contents" to avoid unnecessary re-renders
// of UI parts that only care about one or the other (eg. the navigation drawer
// is only concerned about the branches' info, not their contents).

export interface BranchInfo {
  name: string;
  icon?: string;
  iconColor?: string;
  expandIcon?: string;
  collapseIcon?: string;
  errors?: string[];
  contentAmount: number;

  // Child branches
  childrenIds: string[];
  childrenStatus: Status; // TODO weird to have this? just have a status for the branch itself, and check in children when necessary
}

export interface BranchContentSlice {
  contentStatus: Status;
  contentComplete: boolean;
  contentIds: string[];
}

export interface BranchContents {
  // Distinguish all the branch contents, and the filtered version that changes with each search
  all: BranchContentSlice;
  filtered: BranchContentSlice;
}
