import React from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import * as MUI from '@material-ui/core';

import { downloadUrl } from '../utils';

interface Props {
  onClose: () => void;
}

function InstallInstructions(props: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const downloadLatestVersion = async () => {
    try {
      const latestVersionResponse = await axios.get(
        'https://api.lindale.io/bazaarextension/v1/latestversion/'
      );

      if (latestVersionResponse.data.status !== 'success') {
        enqueueSnackbar(`Cannot fetch latest version: ${latestVersionResponse.data.message}`, {
          variant: 'error'
        });
      } else if (!latestVersionResponse.data.data?.latestVersion?.downloadLink) {
        enqueueSnackbar('Cannot fetch latest version: missing download link in response', {
          variant: 'error'
        });
      } else {
        downloadUrl(latestVersionResponse.data.data.latestVersion.downloadLink);
      }
    } catch (error) {
      enqueueSnackbar(`Cannot fetch latest version: ${error}`, { variant: 'error' });
    }
  };

  return (
    <MUI.Dialog open onClose={props.onClose} maxWidth='md' className='install-instructions-dialog'>
      <MUI.DialogTitle style={{ textAlign: 'center' }}>
        Two ways to install the 3D Bazaar SketchUp Extension
      </MUI.DialogTitle>

      <MUI.DialogContent>
        <MUI.DialogContentText>
          <MUI.Box mb={3}>
            <MUI.Typography variant='caption'>
              System requirements: Windows 10 or MacOS 10.14+
            </MUI.Typography>
          </MUI.Box>

          <MUI.Typography variant='h5' color='primary'>
            Install manually
          </MUI.Typography>

          <ol>
            <li
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={downloadLatestVersion}
            >
              Click here to download the installer.
            </li>
            <li>
              In SketchUp, go to <b>Window &gt; Extension Manager</b> and click on the{' '}
              <b>Install Extension</b> button.
            </li>
            <li>
              Select the <b>.rbz</b> file that you just downloaded.
            </li>
            <li>
              You can now open the 3D Bazaar from <b>Extensions &gt; 3D Bazaar</b> or via the
              toolbar.
            </li>
          </ol>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '100%', borderBottom: '2px solid lightgray' }} />
            <div style={{ padding: '1.5rem' }}>
              <MUI.Typography variant='h5'>Or</MUI.Typography>
            </div>
            <div style={{ width: '100%', borderBottom: '2px solid lightgray' }} />
          </div>

          <MUI.Typography variant='h5' color='primary'>
            Install via the Extension Warehouse
          </MUI.Typography>

          <ol>
            <li>
              In SketchUp, go to <b>Window &gt; Extension Warehouse</b> and sign in to the Extension
              Warehouse.
            </li>
            <li>
              Search for <b>"3D Bazaar"</b> and click on <b>Install</b>.
            </li>
            <li>
              When SketchUp asks whether you trust the author and want to continue installing the
              extension, you need to click <b>Yes</b> to proceed.
            </li>
            <li>
              You can now open the 3D Bazaar from <b>Extensions &gt; 3D Bazaar</b> or via the
              toolbar.
            </li>
          </ol>
        </MUI.DialogContentText>
      </MUI.DialogContent>

      <MUI.DialogActions>
        <MUI.Button onClick={props.onClose} color='primary'>
          Close
        </MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
}

export default React.memo(InstallInstructions);
