import { createSelector } from 'reselect';
import * as zod from 'zod';

import { RootState } from '../../store';
import { Status } from '../../types';
import { extractJWTData } from '../../utils';

export const TermsSchema = zod.object({
  id: zod.number(),
  name: zod.string(),
  version: zod.string(),
  date: zod.string(),
  body: zod.string()
});

export type Terms = zod.infer<typeof TermsSchema>;

interface TermsState {
  contents?: Terms;
  loadingStatus: Status;
  loadingFailed: boolean;
}

export const initialState: TermsState = {
  contents: undefined,
  loadingStatus: 'unloaded',
  loadingFailed: false
};

export const selectTermsContents = (root: RootState) => root.terms.contents;
export const selectTermsLoadingStatus = (root: RootState) => root.terms.loadingStatus;
export const selectTermsLoadingFailed = (root: RootState) => root.terms.loadingFailed;

// Returns the term IDs accepted by the authenticated user
export const selectTermsAcceptance = createSelector(
  [(root: RootState) => root.auth.authToken],
  (authToken) => {
    try {
      // Extract the acceptance from the authentication token
      return authToken ? extractJWTData(authToken).termsAcceptances : undefined;
    } catch (error) {
      console.error('Cannot extract terms acceptance', error);
      return undefined;
    }
  }
);

export const selectTermsAccepted = createSelector(
  [(root: RootState) => root.auth.authToken, (root: RootState) => root.terms.contents],
  (authToken, termContents) => {
    return (
      authToken !== undefined &&
      termContents !== undefined &&
      areTermsAccepted(authToken, termContents.id)
    );
  }
);

// Helper to check if some terms have been accepted by the user
export function areTermsAccepted(authToken: string, termsId: number) {
  try {
    const acceptances = extractJWTData(authToken).termsAcceptances;
    return acceptances?.includes(termsId) ?? false;
  } catch (error) {
    console.error('Cannot check if terms have been accepted', error);
    return false;
  }
}
