import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import stripHtml from 'string-strip-html';
import { basename } from 'path';
import * as MUI from '@material-ui/core';
import * as MUILab from '@material-ui/lab';
// @ts-ignore
import * as removeMarkdown from 'remove-markdown';

import Checkbox from '../lindaleui/components/Checkbox';
import Icon from '../lindaleui/components/Icon';

import { createLocationString, useCustomSnackbar } from '../utils';
import ContentAvatar from './ContentAvatar';
import ContentPrice from './ContentPrice';
import ContentQuickAction from './ContentQuickAction';
import ContentDownloadProgress from './ContentDownloadProgress';
import { CONTENT_TYPES_DATA } from '../constants';
import { dispatchAndNotify } from '../store';
import { selectAllContents } from '../features/contents/contentsState';
import { toggleFavorite } from '../features/favorites/favoritesActions';
import { selectFavoriteContentIds } from '../features/favorites/favoritesState';
import { selectCurrentLocation } from '../features/navigation/navigationState';
import { isContentLocal } from '../features/contents/contentsTypes';
import {
  getContentPackagesBeingDownloaded,
  selectDownloadingPackages
} from '../features/download/downloadState';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    text: {
      display: 'flex',
      flex: 1,
      alignItems: 'center'
    },
    name: {
      marginRight: '8px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      flexShrink: 1 // Can shrink if not enough room but very low weight compared to the description so that this stays visible longer
    },
    descriptionContainer: {
      marginRight: '8px',
      color: '#aaa',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      flexShrink: 100000, // Can shrink if not enough room!
      flexGrow: 1 // Can grow to fill space and push the contents that follows to the right
    },
    description: {
      color: '#aaa',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    info: {
      marginRight: '8px',
      color: '#aaa'
    },
    contentTypes: {
      marginRight: '8px'
    },
    contentType: {
      height: '24px',
      width: '24px',
      backgroundColor: '#fff',
      color: '#888',
      border: '1px solid #ccc'
    },
    listItem: {
      [theme.breakpoints.down('xs')]: {
        // Small screens: thinner margins
        paddingLeft: 0,
        paddingRight: 0
      }
    }
  })
);

type ContentListItemProps = MUI.ListItemProps & {
  contentId: string;
  stacked?: boolean;
  onOpenCart: () => void;
  onOpenBundleDownloadDetails: (id: string) => void;
};

export function ContentListItem(props: ContentListItemProps) {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useCustomSnackbar();

  const currentLocation = useSelector(selectCurrentLocation);
  const allContents = useSelector(selectAllContents);
  const favoriteContentIds = useSelector(selectFavoriteContentIds);
  const downloadingPackages = useSelector(selectDownloadingPackages);

  const onToggleFavorite = React.useCallback(
    (e, checked) => {
      dispatchAndNotify(toggleFavorite({ id: props.contentId, toggle: checked }), enqueueSnackbar);
    },
    [props.contentId, enqueueSnackbar]
  );

  const content = allContents[props.contentId];

  if (content === undefined) {
    return null;
  }

  const loading = content.status === 'loading';

  const isFavorite = favoriteContentIds.includes(props.contentId);
  const isLocal = isContentLocal(content);

  // Render icons depending on the type of the content

  const contentTypes =
    content.type === 'marketcontent' || content.type === 'localcontent' ? (
      <MUILab.AvatarGroup className={classes.contentTypes}>
        {content.contentTypes.includes('bundle') && (
          <MUI.Tooltip title={CONTENT_TYPES_DATA['bundle'].name}>
            <MUI.Avatar className={classes.contentType}>
              <Icon icon={CONTENT_TYPES_DATA['bundle'].icon} size={18} />
            </MUI.Avatar>
          </MUI.Tooltip>
        )}
        {content.contentTypes.includes('composition') && (
          <MUI.Tooltip title={CONTENT_TYPES_DATA['composition'].name}>
            <MUI.Avatar className={classes.contentType}>
              <Icon
                icon={CONTENT_TYPES_DATA['composition'].icon}
                size={18}
                color={CONTENT_TYPES_DATA['composition'].iconColor}
              />
            </MUI.Avatar>
          </MUI.Tooltip>
        )}
        {content.contentTypes.includes('model') && (
          <MUI.Tooltip title={CONTENT_TYPES_DATA['model'].name}>
            <MUI.Avatar className={classes.contentType}>
              <Icon icon={CONTENT_TYPES_DATA['model'].icon} size={18} />
            </MUI.Avatar>
          </MUI.Tooltip>
        )}
      </MUILab.AvatarGroup>
    ) : null;

  // Remove Markdown and HTML from the description
  let strippedDescription = content.description
    ? removeMarkdown(stripHtml(content.description).result)
    : content.description;

  const name =
    content.type === 'marketcontent' || content.type === 'localcontent'
      ? content.name ?? '[MISSING NAME]'
      : basename(Base64.decode(content.id)); // file name for local files

  // Retrieve this content's download progress if it exists
  const packagesBeingDownloaded = getContentPackagesBeingDownloaded(
    content.id,
    downloadingPackages
  );
  const downloadProgress = packagesBeingDownloaded.length > 0 ? packagesBeingDownloaded[0] : null;

  return (
    <MUI.ListItem
      button
      component={Link}
      to={createLocationString({ ...currentLocation, contentPath: [props.contentId] })}
      classes={{ root: classes.listItem }}
    >
      {loading ? (
        <MUILab.Skeleton variant='text' width='60%' animation='wave' />
      ) : (
        <>
          <MUI.ListItemAvatar>
            <ContentAvatar content={content} stacked={props.stacked} />
          </MUI.ListItemAvatar>
          <MUI.ListItemText
            primary={
              <div className={classes.text}>
                <div className={classes.name}>{name}</div>

                {contentTypes}

                <MUI.Typography variant='caption' className={classes.descriptionContainer}>
                  {/* Tight viewport: no description (but we keep the parent container for spacing) */}
                  <MUI.Hidden xsDown implementation='js'>
                    <span className={classes.description}>{strippedDescription}</span>
                  </MUI.Hidden>
                </MUI.Typography>

                {content.type === 'marketcontent' && <ContentPrice content={content} />}
              </div>
            }
          />
        </>
      )}

      <MUI.ListItemSecondaryAction>
        {/* Download progress */}
        {downloadProgress && <ContentDownloadProgress progress={downloadProgress} />}

        {/* Add to cart, download, import */}
        <ContentQuickAction
          content={content}
          onOpenCart={props.onOpenCart}
          onOpenBundleDownloadDetails={props.onOpenBundleDownloadDetails}
        />
        {/* Favorite button */}
        {isLocal && (
          <Checkbox
            icon='mdi-heart-outline'
            checkedIcon='mdi-heart'
            checked={isFavorite !== undefined && isFavorite}
            tooltip={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
            onChange={onToggleFavorite}
          />
        )}
      </MUI.ListItemSecondaryAction>
    </MUI.ListItem>
  );
}

// Loading variant

type LoadingContentListItemprops = MUI.ListItemProps & {};

export function LoadingContentListItem(props: LoadingContentListItemprops) {
  const classes = useStyles(props);

  return (
    <MUI.ListItem style={props.style} classes={{ root: classes.listItem }} button>
      <MUILab.Skeleton variant='text' width='80%' animation='wave' />
    </MUI.ListItem>
  );
}
