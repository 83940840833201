import { createSlice } from '@reduxjs/toolkit';

import { fetchFavoriteContents, toggleFavorite } from './favoritesActions';
import { initialState } from './favoritesState';

export const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch favorites

    builder.addCase(fetchFavoriteContents.fulfilled, (state, action) => {
      state.ids = action.payload.map((content) => content.id);
    });

    builder.addCase(fetchFavoriteContents.rejected, (state, action) => {
      state.ids = [];
    });

    // Toggle favorite

    builder.addCase(toggleFavorite.pending, (state, action) => {
      // Immediately toggle to get quick feedback.
      // If something went wrong, the rejection handler will revert this.

      // Toggle on
      if (action.meta.arg.toggle) {
        state.ids.push(action.meta.arg.id);
      }
      // Toggle off
      else {
        const index = state.ids.indexOf(action.meta.arg.id);
        if (index >= 0) {
          state.ids.splice(index, 1);
        }
      }
    });

    builder.addCase(toggleFavorite.rejected, (state, action) => {
      // Revert back the preemptive state change from the pending case

      // Toggle back off
      if (action.meta.arg.toggle) {
        const index = state.ids.indexOf(action.meta.arg.id);
        if (index >= 0) {
          state.ids.splice(index, 1);
        }
      }
      // Toggle back on
      else {
        state.ids.push(action.meta.arg.id);
      }
    });
  }
});

export default favoritesSlice.reducer;
