import { createSlice } from '@reduxjs/toolkit';

import { checkNewVersion, installNewVersion, updateVersionDownload } from './updateActions';
import { initialState } from './updateState';

export const updateSlice = createSlice({
  name: 'update',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Update download

    builder.addCase(updateVersionDownload, (state, action) => {
      state.status = action.payload.status;
      state.progress = action.payload.progress;
    });

    // Check new version

    builder.addCase(checkNewVersion.fulfilled, (state, action) => {
      state.newVersions = action.payload;
    });

    builder.addCase(checkNewVersion.rejected, (state, action) => {
      state.newVersions = [];
    });

    // Install new version

    builder.addCase(installNewVersion.fulfilled, (state, action) => {});

    builder.addCase(installNewVersion.rejected, (state, action) => {});
  }
});

export default updateSlice.reducer;
