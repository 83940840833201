import React from 'react';
import * as MUI from '@material-ui/core';
import * as MUILab from '@material-ui/lab';
import { Link } from 'react-router-dom';

import Icon from '../lindaleui/components/Icon';
import IconButton from '../lindaleui/components/IconButton';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    root: {
      margin: '4px 0',
      fontWeight: 400,
      color: '#666',
      '&:hover, &$expanded, &$selected': {
        fontWeight: 500,
        color: theme.palette.primary.dark
      },
      '&:focus > $content $label, &$selected > $content $label, &$selected > $content $label:hover, &$selected:focus > $content $label': {
        backgroundColor: 'transparent'
      },
      '& > $content': {},
      '&$selected > $content': {
        backgroundColor: MUI.fade(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    },
    content: (props: Props) => ({
      position: 'relative',
      width: '100%',
      height: '32px',
      cursor: props.disabled ? 'not-allowed' : undefined,
      color: props.disabled ? 'rgba(0,0,0,0.3)' : undefined
    }),
    group: {
      margin: 0
    },
    expanded: {},
    selected: {},

    // There is some ugly hacks with absolute/relative positions and margins
    // due to limitations in MUI's TreeItem. It's the only way to have a
    // link cover the whole treeItem content.
    iconContainer: {
      marginLeft: 'calc(8px + 12px * var(--depth))',
      width: '24px'
    },
    label: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      padding: 0,
      fontWeight: 'inherit',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    labelSkeleton: {
      marginLeft: 'calc(16px + 12px * var(--depth))',
      marginRight: '8px'
    },
    labelLink: {
      height: '100%',
      width: '100%',
      display: 'block',
      color: 'inherit',
      textDecoration: 'inherit'
    },
    labelRoot: {
      height: '100%',
      marginLeft: 'calc(36px + 12px * var(--depth))',
      marginRight: '8px',
      display: 'flex',
      alignItems: 'center',
      padding: 0
    },
    name: {
      fontWeight: 'inherit',
      flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    info: {
      color: '#aaa'
    },
    tooltip: {
      whiteSpace: 'pre-line'
    }
  })
);

export type Action = {
  icon?: string;
  label?: string;
  callback: () => void;
};

type Props = MUILab.TreeItemProps & {
  action?: Action;
  bgColor?: string;
  color?: string;
  collapseIcon?: string;
  depth: number;
  disabled?: boolean;
  expandIcon?: string;
  endIcon?: string;
  href?: string;
  icon?: string;
  iconColor?: string;
  info?: string | number;
  loading?: boolean;
  name: string;
  tooltip?: string;
};

export default function TreeItem(props: Props) {
  const classes = useStyles(props);
  const { icon, collapseIcon, expandIcon, endIcon, name, info, depth } = props;

  const style = { '--depth': depth } as React.CSSProperties;

  const label = (
    <div className={classes.labelRoot}>
      <MUI.Typography variant='body1' className={classes.name}>
        {name}
      </MUI.Typography>

      <MUI.Typography variant='caption' color='inherit' className={classes.info}>
        {info}
      </MUI.Typography>

      {props.action && props.action.icon && (
        <IconButton
          tooltip={props.action.label}
          size={18}
          icon={props.action.icon}
          onClick={(e) => {
            e.stopPropagation();
            props.action?.callback();
          }}
        />
      )}
    </div>
  );

  return (
    <MUI.Tooltip title={props.tooltip ? props.tooltip : ''} classes={{ tooltip: classes.tooltip }}>
      <MUILab.TreeItem
        icon={icon && <Icon icon={icon} color={props.iconColor} />}
        collapseIcon={collapseIcon && <Icon icon={collapseIcon} />}
        expandIcon={expandIcon && <Icon icon={expandIcon} />}
        endIcon={endIcon && <Icon icon={endIcon} />}
        label={
          props.loading ? (
            <MUILab.Skeleton variant='text' animation='wave' className={classes.labelSkeleton} />
          ) : props.href ? (
            <Link to={props.href ? props.href : ''} className={classes.labelLink}>
              {label}
            </Link>
          ) : (
            label
          )
        }
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          iconContainer: classes.iconContainer,
          label: classes.label
        }}
        style={style}
        nodeId={props.nodeId}
        children={props.children}
        onIconClick={props.onIconClick}
        onLabelClick={props.onLabelClick}
        className={props.className}
      />
    </MUI.Tooltip>
  );
}
