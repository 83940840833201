import React from 'react';
import * as MUI from '@material-ui/core';

import localLibraryImage from '../img/local_library.svg';
import InstallInstructions from './InstallInstructions';

interface Props {}

export default function LocalLibraryAd(props: Props) {
  const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);

  return (
    <MUI.Box p={2} mt={6}>
      <MUI.Container maxWidth='sm'>
        <MUI.Box textAlign='center' mb={6}>
          <MUI.Typography variant='h4' color='primary' gutterBottom>
            Manage & search your local library
          </MUI.Typography>
          <MUI.Typography variant='subtitle1' gutterBottom>
            Search assets in your local library on disk, organize your library, import assets in
            your SketchUp models while preserving relative paths for maps and proxies, and much
            more.
          </MUI.Typography>
        </MUI.Box>

        <MUI.Box display='flex' justifyContent='center' mb={4}>
          <MUI.Button
            variant='contained'
            color='primary'
            disableElevation
            onClick={() => setDownloadDialogOpen(true)}
            className='download-extension-button'
          >
            Download the extension
          </MUI.Button>
        </MUI.Box>

        <object type='image/svg+xml' data={localLibraryImage}>
          Manage and search your local library
        </object>

        {downloadDialogOpen && <InstallInstructions onClose={() => setDownloadDialogOpen(false)} />}
      </MUI.Container>
    </MUI.Box>
  );
}
