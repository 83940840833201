import * as zod from 'zod';

import { RootState } from '../../store';
import { UMap } from '../../utils';
import { ContentPackage } from '../contents/contentsTypes';

// Download status

export const DownloadStatusSchema = zod.enum(['complete', 'downloading', 'extracting', 'error']);

export type DownloadStatus = zod.infer<typeof DownloadStatusSchema>;

// Package download

export const PackageDownloadSchema = zod.object({
  status: DownloadStatusSchema,
  progress: zod.number(),
  errorMessage: zod.string().nullable().optional() // TODO why nullable + optional?
});

export type PackageDownload = zod.infer<typeof PackageDownloadSchema>;

// Currently importing an asset into SketchUp?
//
// loading = SketchUp is loading the model
// ready = waiting for the user to place the model
export type ImportStatus = 'loading' | 'ready';

interface DownloadState {
  // Downloaded packages, their content is available
  downloadedPackages: UMap<ContentPackage>;

  // Packages being downloaded and their progress info
  // (will be transferred to downloadedPackages when complete)
  downloadingPackages: UMap<PackageDownload>;

  // Info about the ongoing import
  ongoingImport?: {
    contentId: string;
    status: ImportStatus;
  };
}

export const initialState: DownloadState = {
  downloadedPackages: {},
  downloadingPackages: {},
  ongoingImport: undefined
};

export const selectDownloadedPackages = (root: RootState) => root.download.downloadedPackages;
export const selectDownloadingPackages = (root: RootState) => root.download.downloadingPackages;
export const selectOngoingImport = (root: RootState) => root.download.ongoingImport;

// Returns the packages of the given content being currently downloaded
export function getContentPackagesBeingDownloaded(
  contentId: string,
  downloadingPackages: UMap<PackageDownload>
) {
  const packageIds = Object.keys(downloadingPackages).filter((packageId) =>
    packageId.startsWith(contentId + '_')
  );

  return packageIds.map((packageId) => downloadingPackages[packageId]);
}
