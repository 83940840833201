import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { selectAllContents } from '../contents/contentsState';
import { Content } from '../contents/contentsTypes';

interface FavoritesState {
  ids: string[];
}

export const initialState: FavoritesState = {
  ids: []
};

export const selectFavoriteContentIds = (root: RootState) => root.favorites.ids;

export const selectFavoriteContents = createSelector(
  [selectFavoriteContentIds, selectAllContents],
  (favoriteIds, allContents) =>
    favoriteIds
      .map((id) => allContents[id])
      // Remove missing contents
      .filter((c): c is Content => c !== undefined)
);
