import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import * as zod from 'zod';
import equal from 'fast-deep-equal';

import { ActionError, RootState, toActionError } from '../../store';
import { UMap, jsendSchema, webApiAsync, extractJsendError } from '../../utils';
import { selectUserId } from '../auth/authState';
import { searchLocalFolderContents } from '../library/libraryActions';
import {
  getBranchId,
  isPathMarket,
  NavigationState,
  DEFAULT_FILTERS,
  SortCriteria,
  isSearchFiltered,
  isPathPurchased
} from '../navigation/navigationState';
import {
  MARKET_ID,
  PURCHASED_ID,
  SKATTER_ID,
  selectPurchasedContentIds,
  PAGINATION_SIZE,
  getBranchContents
} from './contentsState';
import {
  BranchInfo,
  MarketplaceCategorySchema,
  marketplaceCategoriesToBranches,
  Content,
  MarketplaceContent,
  MarketplaceContentSchema,
  VendorSchema,
  Vendor,
  marketplaceContentToBranchContent,
  MarketContent
} from './contentsTypes';

// Fetches the materplace categories and converts them to branches
export const fetchMarketplaceCategories = createAsyncThunk<
  { rootIds: string[]; branches: UMap<BranchInfo> },
  void,
  { state: RootState; rejectValue: ActionError }
>('contents/fetchMarketplaceCategories', async (_, thunkAPI) => {
  try {
    const schema = jsendSchema(
      {
        categories: zod.array(MarketplaceCategorySchema)
      },
      {}
    );

    const response = await webApiAsync({
      method: 'get',
      endpoint: 'marketplace/v1/categories',
      schema
    });

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    const categories = response.data.data.categories;

    return marketplaceCategoriesToBranches(categories);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch marketplace categories', error));
  }
});

// Fetches the contents in the current path, be it from the marketplace or the local folders
export const searchContentsInPath = createAsyncThunk<
  {
    complete: boolean;
    contents: Content[];
  },
  {
    location: NavigationState;
    append: boolean; // append or replace?
    silent?: boolean; // will not change the loading status (useful for checking local files)
  },
  { state: RootState; rejectValue: ActionError }
>('contents/searchContentsInPath', async ({ location, append }, thunkAPI) => {
  try {
    if (location.branchPath.length === 0) {
      throw new Error('cannot fetch contents with empty path');
    }

    if (isPathMarket(location.branchPath)) {
      return await thunkAPI
        .dispatch(searchMarketplaceContents({ location, append }))
        .then(unwrapResult);
    } // Local
    else {
      return await thunkAPI
        .dispatch(searchLocalFolderContents({ location, append }))
        .then(unwrapResult);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('search contents in current path', error));
  }
});

// Searches the marketplace contents in the given path
export const searchMarketplaceContents = createAsyncThunk<
  {
    complete: boolean;
    contents: Content[];
  },
  {
    location: NavigationState;
    append: boolean;
  },
  { state: RootState; rejectValue: ActionError }
>('contents/searchMarketplaceContents', async ({ location, append }, thunkAPI) => {
  try {
    if (!isPathMarket(location.branchPath)) {
      throw new Error('cannot fetch marketplace contents out of market path');
    }

    const filtered = isSearchFiltered(location);

    // If we're just fetching an unfiltered category that is already loaded, just return it

    const branchId = getBranchId(location.branchPath);
    const branchContents = thunkAPI.getState().contents.branchesContents[branchId];
    const existingContents = getBranchContents(branchId, thunkAPI.getState());

    if (!append && !filtered && existingContents.length > 0) {
      const complete =
        thunkAPI.getState().contents.branchesContents[branchId]?.all.contentComplete ?? true;

      return {
        contents: existingContents,
        complete
      };
    }

    // Setup the search

    let query = [];

    const currentContentCount =
      (filtered
        ? branchContents?.filtered.contentIds.length
        : branchContents?.all.contentIds.length) ?? 0;

    const offset = append ? currentContentCount : 0;

    query.push(`length=${PAGINATION_SIZE + 1}`);
    query.push(`offset=${offset}`);

    // For the special Purchased branch, explicitly query the contents by ID

    if (branchId === PURCHASED_ID) {
      query.push('purchased=true');

      const purchasedContentIds = selectPurchasedContentIds(thunkAPI.getState());

      if (purchasedContentIds.length > 0) {
        query.push(`contentIds=${purchasedContentIds.join(',')}`);
      } else {
        return {
          contents: [],
          complete: true
        };
      }
    } else if (branchId !== MARKET_ID) {
      query.push(`categories=${branchId}`);
    }

    if (location.vendorId) {
      query.push(`vendorId=${location.vendorId}`);
    }

    if (location.searchFilters.free) {
      query.push(`isFree=true`);
    } else {
      if (location.searchFilters.priceRange.min > DEFAULT_FILTERS.priceRange.min) {
        query.push(`priceMin=${location.searchFilters.priceRange.min}`);
      }

      if (location.searchFilters.priceRange.max < DEFAULT_FILTERS.priceRange.max) {
        query.push(`priceMax=${location.searchFilters.priceRange.max}`);
      }
    }

    if (location.searchFilters.contentTypes.length > 0) {
      query.push(`contentTypes=${location.searchFilters.contentTypes.join(',')}`);
    }

    if (location.searchFilters.renderers.length > 0) {
      query.push(`renderers=${location.searchFilters.renderers.join(',')}`);
    }

    let sortCriteria = location.sortCriteria;
    if (
      branchId === SKATTER_ID &&
      sortCriteria === SortCriteria.DEFAULT &&
      equal(location.searchFilters, DEFAULT_FILTERS)
    ) {
      sortCriteria = SortCriteria.PRICE_ASC;
    }
    if (sortCriteria !== SortCriteria.DEFAULT) {
      query.push(`sortCriteria=${sortCriteria}`);
    }

    query.push(`keywords=${location.searchKeywords.join(',')}`);

    // Search

    const queryStr = query.join('&');

    console.log('Searching contents', queryStr);

    const schema = jsendSchema(
      {
        contents: zod.array(MarketplaceContentSchema)
      },
      {}
    );

    const response = await webApiAsync({
      method: 'get',
      endpoint: 'marketplace/v1/contents?' + queryStr,
      schema
    });

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    const receivedContents = response.data.data.contents;

    const contents = receivedContents.map(marketplaceContentToBranchContent);

    return {
      // We queried PAGINATION_SIZE + 1 items to determine if there are still other
      // items to load after this call but we only use PAGINATION_SIZE of them
      contents: contents.slice(0, Math.min(contents.length, PAGINATION_SIZE)),
      complete: contents.length < PAGINATION_SIZE + 1
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('search marketplace contents', error));
  }
});

export const fetchMarketplaceContents = createAsyncThunk<
  MarketContent[],
  string[], // IDs
  { state: RootState; rejectValue: ActionError }
>('contents/fetchMarketplaceContents', async (contentIds, thunkAPI) => {
  try {
    if (contentIds.length === 0) {
      console.warn('Called fetchMarketplaceContents with no IDs');
      return [];
    }

    // If some content already exists in a branch, warn about it as this is wasteful

    contentIds.forEach((id) => {
      const content = thunkAPI.getState().contents.contents[id];

      if (content) {
        console.warn(`Fetching content ${id} but it's already available`);
      }
    });

    // Search

    let query = [];

    query.push(`length=1000`);
    query.push(`offset=0`);
    query.push(`contentIds=${contentIds.join(',')}`);

    const queryStr = query.join('&');

    console.log('Fetching contents', queryStr);

    const schema = jsendSchema(
      {
        contents: zod.array(MarketplaceContentSchema)
      },
      {}
    );

    const response = await webApiAsync({
      method: 'get',
      endpoint: 'marketplace/v1/contents?' + queryStr,
      schema
    });

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    const receivedContents = response.data.data.contents;

    return receivedContents.map(marketplaceContentToBranchContent);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch marketplace contents', error));
  }
});

export const fetchFreeMarketplaceContents = createAsyncThunk<
  MarketContent[],
  void,
  { state: RootState; rejectValue: ActionError }
>('contents/fetchFreeMarketplaceContents', async (_, thunkAPI) => {
  try {
    const schema = jsendSchema(
      {
        contents: zod.array(MarketplaceContentSchema)
      },
      {}
    );

    const response = await webApiAsync({
      method: 'get',
      endpoint: 'marketplace/v1/contents/free',
      schema
    });

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    const receivedContents = response.data.data.contents as MarketplaceContent[];

    return receivedContents.map(marketplaceContentToBranchContent);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch free marketplace contents', error));
  }
});

export const fetchFeaturedMarketplaceContents = createAsyncThunk<
  MarketContent[],
  void,
  { state: RootState; rejectValue: ActionError }
>('contents/fetchFeaturedMarketplaceContents', async (_, thunkAPI) => {
  try {
    const schema = jsendSchema(
      {
        contents: zod.array(MarketplaceContentSchema)
      },
      {}
    );

    const response = await webApiAsync({
      method: 'get',
      endpoint: 'marketplace/v1/contents/featured',
      schema
    });

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    const receivedContents = response.data.data.contents as MarketplaceContent[];

    return receivedContents.map(marketplaceContentToBranchContent);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch featured marketplace contents', error));
  }
});

export const fetchPopularMarketplaceContents = createAsyncThunk<
  MarketContent[],
  void,
  { state: RootState; rejectValue: ActionError }
>('contents/fetchPopularMarketplaceContents', async (_, thunkAPI) => {
  try {
    const schema = jsendSchema(
      {
        contents: zod.array(MarketplaceContentSchema)
      },
      {}
    );

    const response = await webApiAsync({
      method: 'get',
      endpoint: 'marketplace/v1/contents/popular',
      schema
    });

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    const receivedContents = response.data.data.contents as MarketplaceContent[];

    return receivedContents.map(marketplaceContentToBranchContent);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch popular marketplace contents', error));
  }
});

export const fetchVendors = createAsyncThunk<
  UMap<Vendor>,
  void,
  { state: RootState; rejectValue: ActionError }
>('branches/fetchVendors', async (_, thunkAPI) => {
  try {
    const schema = jsendSchema(
      {
        vendors: zod.array(VendorSchema)
      },
      {}
    );

    const response = await webApiAsync({
      method: 'get',
      endpoint: 'marketplace/v1/vendors',
      schema
    });

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    const receivedVendors = response.data.data.vendors as Vendor[];

    const vendors: UMap<Vendor> = {};
    receivedVendors.forEach((v) => (vendors[v.id] = v));
    return vendors;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch vendors', error));
  }
});

// Fetches purchased content IDs only
export const fetchPurchasedContentIds = createAsyncThunk<
  string[],
  void,
  { state: RootState; rejectValue: ActionError }
>('contents/fetchPurchasedContentIds', async (_, thunkAPI) => {
  try {
    const userId = selectUserId(thunkAPI.getState());

    if (userId === undefined) {
      throw new Error('not authenticated');
    }

    const { authToken } = thunkAPI.getState().auth;

    const schema = jsendSchema(
      {
        purchases: zod.array(
          zod.object({
            id: zod.number(),
            contentId: zod.string()
          })
        )
      },
      {
        id: zod.string()
      }
    );

    const response = await webApiAsync({
      method: 'get',
      endpoint: 'marketplace/v1/purchases/byUser/' + userId,
      jwt: authToken,
      schema
    });

    if (response.data.status !== 'success') {
      throw extractJsendError(response.data);
    }

    const receivedPurchases = response.data.data.purchases.map((p) => p.contentId);

    return receivedPurchases;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch purchased contents IDs', error));
  }
});

export const fetchPurchasedContents = createAsyncThunk<
  void,
  void,
  { state: RootState; rejectValue: ActionError }
>('contents/fetchPurchasedContents', async (_, thunkAPI) => {
  try {
    // Fetch the purchased content IDs
    await thunkAPI.dispatch(fetchPurchasedContentIds());

    // If we are in the market/purchased branch, also fetch the contents themselves
    const location = thunkAPI.getState().navigation;
    if (isPathPurchased(location.branchPath)) {
      await thunkAPI.dispatch(
        searchContentsInPath({
          location: location,
          append: false
        })
      );
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetchPurchasedContents', error));
  }
});
