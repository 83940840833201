import React from 'react';
import * as MUI from '@material-ui/core';

import Icon from '../lindaleui/components/Icon';

const useStyles = MUI.makeStyles(({ palette, spacing, headerHeight }: MUI.Theme) =>
  MUI.createStyles({
    icon: {
      marginRight: '0.75rem',
      position: 'relative', // Align with text
      top: '0.1rem'
    },
    localLink: {
      cursor: 'pointer'
    }
  })
);

interface Props {
  onClickLocal: () => void;
}

export default function Maintenance(props: Props) {
  const classes = useStyles(props);

  return (
    <MUI.Box p={2} mt={6} color='#666666'>
      <MUI.Container maxWidth='sm'>
        <MUI.Box textAlign='center' mt={6} mb={6}>
          <MUI.Box display='flex' flexDirection='row' justifyContent='center'>
            <Icon icon='mdi-hammer-wrench' size={36} className={classes.icon} />
            <MUI.Typography variant='h4' gutterBottom>
              Maintenance in progress
            </MUI.Typography>
          </MUI.Box>

          <MUI.Typography variant='overline' gutterBottom>
            The marketplace is temporarily unavailable.
          </MUI.Typography>

          <MUI.Box mt={20}>
            <MUI.Typography variant='subtitle2' gutterBottom>
              In the meantime, you can still use our{' '}
              <MUI.Link onClick={props.onClickLocal} className={classes.localLink}>
                SketchUp&nbsp;Extension
              </MUI.Link>{' '}
              to manage your local library!
            </MUI.Typography>
          </MUI.Box>
        </MUI.Box>
      </MUI.Container>
    </MUI.Box>
  );
}
