import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    headerHeight: number;
  }
}

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        light: '#88c94f',
        main: '#6bbc24',
        dark: '#4a8319',
        contrastText: '#ffffff'
      },
      secondary: {
        light: '#88c94f',
        main: '#6bbc24',
        dark: '#4a8319',
        contrastText: '#ffffff'
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)'
      }
    },
    typography: {
      body1: {
        fontSize: '0.75rem'
      },
      body2: {
        fontSize: '0.75rem'
      },
      subtitle2: {
        fontSize: '0.75rem'
      }
    }
  },
  {
    headerHeight: 64
  }
);

export default theme;
