import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as MUI from '@material-ui/core';
import Alert, { AlertProps } from '@material-ui/lab/Alert';

import Button from '../lindaleui/components/Button';
import Dialog from './Dialog';

import { dispatchAndNotify } from '../store';
import { logout, register, login } from '../features/auth/authActions';
import {
  selectAuthInProgress,
  selectAuthToken,
  selectLoginResult,
  selectRegisterResult
} from '../features/auth/authState';
import { extractJWTData, useCustomSnackbar, createLocationString } from '../utils';
import { isPathPurchased, selectCurrentLocation } from '../features/navigation/navigationState';
import { MARKET_ID } from '../features/contents/contentsState';

const useStyles = MUI.makeStyles(({ palette, spacing }: MUI.Theme) =>
  MUI.createStyles({
    root: {
      padding: spacing(2)
    },
    alertMessage: {
      marginTop: '16px'
    },
    submitButton: {
      marginTop: spacing(1)
    }
  })
);

export type AccountOverlayMessageType = AlertProps['severity'];

interface Props {
  message?: { type: AlertProps['severity']; text: string };
  usernameFieldRef?: React.Ref<HTMLInputElement>;
  onClose: () => void;
}

export function AccountOverlay(props: Props) {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useCustomSnackbar();

  const authToken = useSelector(selectAuthToken);
  const loginStatus = useSelector(selectLoginResult);
  const registerStatus = useSelector(selectRegisterResult);
  const inProgress = useSelector(selectAuthInProgress);
  const history = useHistory();
  const currentLocation = useSelector(selectCurrentLocation);

  // Form

  const [email, setEmail] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [tab, setTab] = React.useState<'login' | 'register'>('login');

  const submit = async () => {
    if (tab === 'login') {
      const loginResult = await dispatchAndNotify(login({ email, password }), enqueueSnackbar);

      if (loginResult.fulfilled && loginResult.data.success) {
        enqueueSnackbar('You have been successfully logged in', 'success');

        // Close the overlay
        props.onClose();
      }
    } else {
      dispatchAndNotify(register({ email, username, password }), enqueueSnackbar);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (
      e.key === 'Enter' &&
      !inProgress &&
      email !== '' &&
      password !== '' &&
      (tab !== 'register' || username !== '')
    ) {
      submit();
    }
  };

  const userInfo = authToken ? extractJWTData(authToken) : undefined;

  const status = tab === 'login' ? loginStatus : registerStatus;

  return (
    <Dialog
      open
      onClose={inProgress ? undefined : props.onClose}
      fullWidth
      maxWidth='xs'
      classes={{ paper: classes.root }}
    >
      {userInfo ? (
        // Account info

        <>
          <MUI.Box justifyContent='center' textAlign='center' mb={2}>
            <MUI.Typography variant='h5'>
              You are logged in as{' '}
              <MUI.Typography
                variant='h5'
                display='inline'
                color='primary'
                style={{ fontWeight: 'bold' }}
              >
                {userInfo.username}
              </MUI.Typography>
            </MUI.Typography>
            <MUI.Typography variant='h6' color='textSecondary' style={{ fontSize: '1rem' }}>
              ({userInfo.email})
            </MUI.Typography>
          </MUI.Box>

          <MUI.Button
            className={classes.submitButton}
            variant='outlined'
            fullWidth
            disabled={inProgress}
            onClick={() => window.open('https://lindale.io/account/manage', '_blank')}
          >
            Manage account
          </MUI.Button>

          <Button
            className={classes.submitButton}
            variant='outlined'
            fullWidth
            loading={inProgress}
            onClick={() => {
              dispatchAndNotify(logout(), enqueueSnackbar).then((loggedOut) => {
                if (loggedOut) {
                  enqueueSnackbar('You have been successfully logged out', 'success');

                  // Close the overlay
                  props.onClose();

                  // We were in the Purchased category?
                  // It'll be deleted so move to the marketplace root.
                  if (isPathPurchased(currentLocation.branchPath)) {
                    history.push(
                      createLocationString({ ...currentLocation, branchPath: [MARKET_ID] })
                    );
                  }
                }
              });
            }}
          >
            Log out
          </Button>
        </>
      ) : (
        // Login/Registration form

        <>
          <MUI.Tabs value={tab} onChange={(e, value) => setTab(value)} variant='fullWidth'>
            <MUI.Tab disabled={inProgress} label='Login' value='login' />
            <MUI.Tab disabled={inProgress} label='Register' value='register' />
          </MUI.Tabs>

          {props.message && (
            <Alert severity={props.message.type} className={classes.alertMessage}>
              {props.message.text}
            </Alert>
          )}

          {tab === 'login' && loginStatus.errors.message && (
            <Alert severity='error' className={classes.alertMessage}>
              {loginStatus.errors.message}
            </Alert>
          )}

          {tab === 'register' && registerStatus.successMessage && (
            <Alert severity='success' className={classes.alertMessage}>
              {registerStatus.successMessage}
            </Alert>
          )}

          <form onKeyDown={onKeyDown}>
            <div>
              <MUI.TextField
                inputRef={props.usernameFieldRef}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                label='Email'
                name='email'
                size='small'
                fullWidth
                margin='normal'
                error={status.errors.email !== undefined}
                helperText={status.errors.email}
                inputProps={{
                  spellCheck: false
                }}
                disabled={inProgress}
                required
              />
              {tab === 'register' && (
                <MUI.TextField
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  label='Username'
                  name='username'
                  size='small'
                  fullWidth
                  margin='normal'
                  error={status.errors.username !== undefined}
                  helperText={status.errors.username}
                  inputProps={{
                    spellCheck: false
                  }}
                  disabled={inProgress}
                  required
                />
              )}
              <MUI.TextField
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                label='Password'
                name='password'
                type='password'
                size='small'
                fullWidth
                margin='normal'
                error={status.errors.password !== undefined}
                helperText={status.errors.password}
                inputProps={{
                  spellCheck: false
                }}
                disabled={inProgress}
                required
              />
              <Button
                className={classes.submitButton}
                variant='contained'
                color='primary'
                fullWidth
                onClick={() => {
                  submit();
                }}
                disabled={
                  inProgress ||
                  email === '' ||
                  password === '' ||
                  (tab === 'register' && username === '')
                }
                loading={inProgress}
              >
                {tab === 'login' ? 'Log in' : 'Register'}
              </Button>
              {tab === 'login' && (
                <MUI.Link
                  href='https://lindale.io/account/forgotpassword'
                  target='_blank'
                  variant='body2'
                >
                  Forgot password?
                </MUI.Link>
              )}
            </div>
          </form>
        </>
      )}
    </Dialog>
  );
}
