import React from 'react';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import * as MUI from '@material-ui/core';
import * as zod from 'zod';

import Dialog from './Dialog';
import PopupOverlay from '../lindaleui/components/PopupOverlay';

import {
  selectNewVersions,
  selectIsUpdateRequired,
  selectDownload
} from '../features/update/updateState';
import { installNewVersion } from '../features/update/updateActions';
import { isExtension, sketchupAsync, sketchupSchema, useCustomSnackbar } from '../utils';
import { dispatchAndNotify } from '../store';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    dialogTitle: {
      color: theme.palette.primary.main
    }
  })
);

function Markdown(props: any) {
  return (
    <ReactMarkdown
      source={props.source}
      escapeHtml={false}
      renderers={{
        paragraph: (props) => <React.Fragment {...props} />
      }}
    />
  );
}

interface Props {
  onClose: () => void;
}

export function UpdateOverlay(props: Props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useCustomSnackbar();

  const versions = useSelector(selectNewVersions);
  const mandatory = useSelector(selectIsUpdateRequired);
  const { downloadStatus, downloadProgress } = useSelector(selectDownload);

  const onInstall = React.useCallback(() => {
    dispatchAndNotify(installNewVersion(), enqueueSnackbar);
  }, [enqueueSnackbar]);

  const noClose = React.useCallback(() => {}, []);

  const onCloseExtension = React.useCallback(() => {
    const schema = sketchupSchema(zod.null());

    if (isExtension()) {
      sketchupAsync({
        methodName: 'close',
        schema
      }).catch((error) => enqueueSnackbar(error.message, 'error'));
    }
  }, [enqueueSnackbar]);

  // Render

  let installOverlay: React.ReactNode = null;

  if (/*installOverlayOpen*/ true) {
    if (downloadStatus === 'downloading') {
      installOverlay = (
        <PopupOverlay
          open={true}
          onClose={noClose}
          title='Downloading'
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth='sm'
          fullWidth
        >
          <MUI.LinearProgress
            variant={downloadProgress > 0 ? 'determinate' : 'indeterminate'}
            value={downloadProgress * 100}
          />
        </PopupOverlay>
      );
    } else if (downloadStatus === 'installing') {
      installOverlay = (
        <PopupOverlay
          open={true}
          onClose={noClose}
          title='Installing'
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth='sm'
          fullWidth
        >
          <MUI.LinearProgress variant='indeterminate' />
        </PopupOverlay>
      );
    } else if (downloadStatus === 'complete') {
      installOverlay = (
        <PopupOverlay
          open={true}
          onClose={onCloseExtension}
          title='Success'
          actions={[{ label: 'Close', action: onCloseExtension, size: 'small', color: 'primary' }]}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth='sm'
          fullWidth
        >
          <MUI.Typography>The update has been successfully installed.</MUI.Typography>
          <MUI.Typography>Please restart SketchUp to complete the installation.</MUI.Typography>
        </PopupOverlay>
      );
    }
  }

  const versionElements = [...versions].reverse().map((version) => {
    const newFeatures = version.changelog.new?.map((entry, i) => (
      <li key={'f' + i}>
        <MUI.Typography variant='body1'>
          <Markdown source={entry} />
        </MUI.Typography>
      </li>
    ));

    const changes = version.changelog.changes?.map((entry, i) => (
      <li key={'c' + i}>
        <MUI.Typography variant='body1'>
          <Markdown source={entry} />
        </MUI.Typography>
      </li>
    ));

    const bugFixes = version.changelog.fixes?.map((entry, i) => (
      <li key={'b' + i}>
        <MUI.Typography variant='body1'>
          <Markdown source={entry} />
        </MUI.Typography>
      </li>
    ));

    return (
      <MUI.Grid item container direction='column' spacing={2} key={version.versionNumber}>
        {/* Version & date */}
        <MUI.Grid item container direction='row' justify='space-between' alignItems='baseline'>
          <MUI.Grid item>
            <MUI.Typography variant='h6'>{version.versionNumber}</MUI.Typography>
          </MUI.Grid>
          <MUI.Grid item>
            <MUI.Typography variant='subtitle1' align='right'>
              {version.date}
            </MUI.Typography>
          </MUI.Grid>
        </MUI.Grid>

        {/* Content */}

        {version.description && (
          <MUI.Grid item>
            <MUI.Typography variant='body1'>
              <Markdown source={version.description} />
            </MUI.Typography>
          </MUI.Grid>
        )}

        <MUI.Grid item>
          {newFeatures && newFeatures.length > 0 && (
            <>
              <MUI.Typography variant='subtitle2'>New features</MUI.Typography>
              <ul>{newFeatures}</ul>
            </>
          )}

          {changes && changes.length > 0 && (
            <>
              <MUI.Typography variant='subtitle2'>Changes</MUI.Typography>
              <ul>{changes}</ul>
            </>
          )}

          {bugFixes && bugFixes.length > 0 && (
            <>
              <MUI.Typography variant='subtitle2'>Bug Fixes</MUI.Typography>
              <ul>{bugFixes}</ul>
            </>
          )}
        </MUI.Grid>
      </MUI.Grid>
    );
  });

  return (
    <Dialog
      open
      onClose={mandatory ? undefined : props.onClose} // Mandatory = no way to close
      maxWidth='sm'
    >
      {installOverlay}

      <MUI.DialogTitle classes={{ root: classes.dialogTitle }}>
        A new version is available!
      </MUI.DialogTitle>

      <MUI.DialogContent dividers>
        <MUI.Grid item container spacing={3}>
          {versionElements}
        </MUI.Grid>
      </MUI.DialogContent>

      <MUI.DialogActions>
        <MUI.Button variant='contained' color='primary' onClick={onInstall}>
          Install
        </MUI.Button>

        {!mandatory && (
          <MUI.Button variant='outlined' color='primary' onClick={props.onClose}>
            Maybe later
          </MUI.Button>
        )}
      </MUI.DialogActions>
    </Dialog>
  );
}
