import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { basename } from 'path';

import { logout } from '../auth/authActions';
import { syncCartContents } from '../cart/cartActions';
import {
  fetchLocalFolders,
  addLocalFolder,
  removeLocalFolder,
  updateLocalFolders
} from '../library/libraryActions';
import { getBranchId, isSearchFiltered } from '../navigation/navigationState';
import {
  fetchMarketplaceCategories,
  fetchPurchasedContentIds,
  fetchVendors,
  searchContentsInPath,
  fetchMarketplaceContents,
  fetchFreeMarketplaceContents,
  fetchFeaturedMarketplaceContents,
  fetchPopularMarketplaceContents
} from './contentsActions';
import { fetchFavoriteContents } from '../favorites/favoritesActions';
import {
  MARKET_ID,
  initialState,
  PURCHASED_ID,
  createBranchInfo,
  LOCAL_ID,
  createBranchContents
} from './contentsState';

export const contentsSlice = createSlice({
  name: 'contents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch marketplace categories

    builder.addCase(fetchMarketplaceCategories.pending, (state, action) => {
      const marketBranch = state.branchesInfo[MARKET_ID];

      if (!marketBranch) {
        console.error('Cannot retrieve the marketplace branch');
        return;
      }

      marketBranch.childrenStatus = 'loading';
    });

    builder.addCase(fetchMarketplaceCategories.fulfilled, (state, action) => {
      const marketBranch = state.branchesInfo[MARKET_ID];

      if (!marketBranch) {
        console.error('Cannot retrieve the marketplace branch');
        return;
      }

      Object.keys(action.payload.branches).forEach((id) => {
        state.branchesInfo[id] = action.payload.branches[id];

        // Only create the content if it doesn't exist yet
        // (it's possible for a branch's content to be fetched before the categories)
        if (!state.branchesContents[id]) {
          state.branchesContents[id] = createBranchContents();
        }
      });

      marketBranch.childrenIds = _.uniq([...marketBranch.childrenIds, ...action.payload.rootIds]);
      marketBranch.childrenStatus = 'loaded';
    });

    builder.addCase(fetchMarketplaceCategories.rejected, (state, action) => {
      // Reset

      const defaultBranch = initialState.branchesInfo[MARKET_ID];
      if (!defaultBranch) {
        console.error('Cannot retrieve the default marketplace branch');
      } else {
        state.branchesInfo[MARKET_ID] = _.cloneDeep(defaultBranch);
      }
    });

    // Search contents

    builder.addCase(searchContentsInPath.pending, (state, action) => {
      const appending = action.meta.arg.append;
      const filtered = isSearchFiltered(action.meta.arg.location);

      const path = action.meta.arg.location.branchPath;

      const branchId = getBranchId(path);
      let branch = state.branchesContents[branchId];

      if (!branch) {
        branch = state.branchesContents[branchId] = createBranchContents();
      }

      const slice = filtered ? branch.filtered : branch.all;

      const silent = action.meta.arg.silent;

      if (!silent) {
        slice.contentStatus = 'loading';
      }

      // Filtered & not silent: clear the previous results
      if (filtered && !appending && !silent) {
        slice.contentIds = [];
      }
    });

    builder.addCase(searchContentsInPath.fulfilled, (state, action) => {
      const appending = action.meta.arg.append;
      const branchPath = action.meta.arg.location.branchPath;
      const filtered = isSearchFiltered(action.meta.arg.location);

      // Merge new contents

      action.payload.contents.forEach((c) => (state.contents[c.id] = c));

      // Fill the search results or the branch

      const branch = state.branchesContents[getBranchId(branchPath)];

      if (!branch) {
        console.error(`Cannot retrieve branch "${branchPath}"`);
        return;
      }

      const slice = filtered ? branch.filtered : branch.all;

      if (appending) {
        // uniq() to ensure that we don't have duplicates due to packages for the same content
        slice.contentIds = _.uniq([
          ...slice.contentIds,
          ...action.payload.contents.map((c) => c.id)
        ]);
      } else {
        slice.contentIds = action.payload.contents.map((c) => c.id);
      }

      slice.contentComplete = action.payload.complete;
      slice.contentStatus = 'loaded';
    });

    builder.addCase(searchContentsInPath.rejected, (state, action) => {
      const branchPath = action.meta.arg.location.branchPath;
      const filtered = isSearchFiltered(action.meta.arg.location);

      // Fill the search results or the branch

      const branch = state.branchesContents[getBranchId(branchPath)];

      if (!branch) {
        console.error(`Cannot retrieve branch "${branchPath}"`);
        return;
      }

      const slice = filtered ? branch.filtered : branch.all;

      if (filtered) {
        slice.contentStatus = 'unloaded';
      }
    });

    // Fetch marketplace contents

    builder.addCase(fetchMarketplaceContents.fulfilled, (state, action) => {
      // Add the new contents
      const contents = action.payload;
      contents.forEach((content) => (state.contents[content.id] = content));
    });

    builder.addCase(fetchMarketplaceContents.rejected, (state, action) => {
      // Clear the contents
      const contentIds = action.meta.arg;
      contentIds.forEach((contentId) => delete state.contents[contentId]);
    });

    // Fetch purchased contents

    builder.addCase(fetchPurchasedContentIds.pending, (state, action) => {
      // Create the purchased branch if it doesn't exist yet

      const branchInfo = state.branchesInfo[PURCHASED_ID];

      if (!branchInfo) {
        state.branchesInfo[PURCHASED_ID] = createBranchInfo({
          name: 'Purchased',
          icon: 'mdi-cart-check',
          collapseIcon: 'mdi-cart-check',
          expandIcon: 'mdi-cart-check',
          contentAmount: 0
        });
      }

      state.branchesContents[PURCHASED_ID] = createBranchContents();
    });

    builder.addCase(fetchPurchasedContentIds.fulfilled, (state, action) => {
      // Add content IDs to the Purchased branch

      const branchInfo = state.branchesInfo[PURCHASED_ID];

      if (branchInfo) {
        branchInfo.contentAmount = action.payload.length;
      } else {
        console.error('Cannot retrieve the purchased branch');
      }

      state.purchasedContentIds = action.payload;

      // Add the Purchased branch as a child to the Marketplace branch

      const marketBranchInfo = state.branchesInfo[MARKET_ID];

      if (marketBranchInfo && !marketBranchInfo.childrenIds.includes(PURCHASED_ID)) {
        marketBranchInfo.childrenIds.unshift(PURCHASED_ID); // Put it first!
      }
    });

    // Fetch free marketplace contents

    builder.addCase(fetchFreeMarketplaceContents.fulfilled, (state, action) => {
      const contents = action.payload;

      // Add the contents to the "all contents" collection
      contents.forEach((content) => (state.contents[content.id] = content));

      // Save content IDs
      state.freeContentIds = contents.map((c) => c.id);
    });

    // Fetch featured marketplace contents

    builder.addCase(fetchFeaturedMarketplaceContents.fulfilled, (state, action) => {
      const contents = action.payload;

      // Add the contents to the "all contents" collection
      contents.forEach((content) => (state.contents[content.id] = content));

      // Save content IDs
      state.featuredContentIds = contents.map((c) => c.id);
    });

    // Fetch popular marketplace contents

    builder.addCase(fetchPopularMarketplaceContents.fulfilled, (state, action) => {
      const contents = action.payload;

      // Add the contents to the "all contents" collection
      contents.forEach((content) => (state.contents[content.id] = content));

      // Save content IDs
      state.popularContentIds = contents.map((c) => c.id);
    });

    // Fetch favorite contents

    builder.addCase(fetchFavoriteContents.fulfilled, (state, action) => {
      // Merge new contents
      action.payload.forEach((c) => (state.contents[c.id] = c));
    });

    // Logout: remove the Purchased category

    builder.addCase(logout.fulfilled, (state, action) => {
      delete state.branchesContents[PURCHASED_ID];
      delete state.branchesInfo[PURCHASED_ID];
      state.purchasedContentIds = [];

      const marketBranch = state.branchesInfo[MARKET_ID];
      if (marketBranch) {
        const childIndex = marketBranch.childrenIds.findIndex((id) => id === PURCHASED_ID);
        if (childIndex > -1) {
          marketBranch.childrenIds.splice(childIndex, 1);
        }
      } else {
        console.error('Cannot retrieve the marketplace branch');
      }
    });

    // Fetch vendors

    builder.addCase(fetchVendors.pending, (state, action) => {
      state.vendors = {};
    });

    builder.addCase(fetchVendors.fulfilled, (state, action) => {
      Object.keys(action.payload).forEach((id) => (state.vendors[id] = action.payload[id]));
    });

    // Fetch local folders

    builder.addCase(fetchLocalFolders.pending, (state, action) => {
      const branchInfo = state.branchesInfo[LOCAL_ID];

      if (branchInfo) {
        branchInfo.childrenStatus = 'loading';
        branchInfo.childrenIds = [];
      } else {
        console.error('Cannot retrieve the local branch');
      }
    });

    builder.addCase(fetchLocalFolders.fulfilled, (state, action) => {
      const branchInfo = state.branchesInfo[LOCAL_ID];

      if (branchInfo) {
        branchInfo.childrenIds = action.payload.rootIds;
        branchInfo.childrenStatus = 'loaded';

        // Child branches
        Object.keys(action.payload.branches).forEach(
          (id) => (state.branchesInfo[id] = action.payload.branches[id])
        );
      } else {
        console.error('Cannot retrieve the local branch');
      }

      // Child branches contents
      Object.keys(action.payload.branches).forEach(
        (id) => (state.branchesContents[id] = createBranchContents())
      );
    });

    builder.addCase(fetchLocalFolders.rejected, (state, action) => {
      // Reset

      const defaultBranch = initialState.branchesInfo[LOCAL_ID];
      if (!defaultBranch) {
        console.error('Cannot retrieve the default local branch');
      } else {
        state.branchesInfo[LOCAL_ID] = _.cloneDeep(defaultBranch);
      }
    });

    // Update local folders

    builder.addCase(updateLocalFolders, (state, action) => {
      const localBranchInfo = state.branchesInfo[LOCAL_ID];

      if (!localBranchInfo) {
        console.error('Cannot retrieve the local branch');
        return;
      }

      // Make sure not to add folders currently being removed
      // (can happen if this is called during the removeLocalFolder thunk)

      localBranchInfo.childrenIds = _.difference(
        action.payload.rootIds,
        state.localBranchesBeingRemoved
      );
      localBranchInfo.childrenStatus = 'loaded';

      Object.keys(action.payload.branches)
        .filter((branchId) => !state.localBranchesBeingRemoved.includes(branchId))
        .forEach((branchId) => {
          // Overwrite the branch info

          state.branchesInfo[branchId] = action.payload.branches[branchId];

          // Create the branch's contents if needed

          if (state.branchesContents[branchId] === undefined) {
            state.branchesContents[branchId] = createBranchContents();
          }
        });

      // TODO remove disappeared folders?
    });

    // Add local folder

    builder.addCase(addLocalFolder.fulfilled, (state, action) => {
      const folderPath = action.payload;

      // Ignore the case where no folders were added

      if (folderPath === undefined) {
        return;
      }

      // Add the new folder as a loading branch

      const newBranchId = Base64.encode(folderPath);

      state.branchesInfo[newBranchId] = createBranchInfo({
        name: basename(folderPath),
        childrenStatus: 'loading'
      });

      state.branchesContents[newBranchId] = createBranchContents({
        contentStatus: 'loading'
      });

      // Add to the root local branch

      const localBranchInfo = state.branchesInfo[LOCAL_ID];

      if (localBranchInfo) {
        // Make sure children IDs are unique
        // (it's possible than the branch has already been registered through folder watching)
        localBranchInfo.childrenIds = _.uniq([...localBranchInfo.childrenIds, newBranchId]);
      } else {
        console.error('Cannot retrieve the local branch');
      }
    });

    // TODO rejected case? remove folder data?

    // Remove local folder

    builder.addCase(removeLocalFolder.pending, (state, action) => {
      // Mark the branch as being removed
      state.localBranchesBeingRemoved.push(action.meta.arg.branchId);

      const localBranch = state.branchesInfo[LOCAL_ID];

      if (!localBranch) {
        console.error('Cannot retrieve the local branch');
        return;
      }

      // Remove the root folder from the local branch's children

      const index = localBranch.childrenIds.findIndex((i) => i === action.meta.arg.branchId);
      if (index > -1) {
        localBranch.childrenIds.splice(index, 1);
      }

      // Remove the root branch and its children
      // (traverse the tree from the root folder to collect all the branches to delete)

      // TODO might not work for package subfolders!
      // because children are not stored on the UI side.
      // just loop through all branches and check prefix?

      const branchesToRemove = [];
      const branchesToVisit = [action.meta.arg.branchId];

      while (branchesToVisit.length > 0) {
        const branchId = branchesToVisit.shift()!;

        const branch = state.branchesInfo[branchId];
        if (branch) {
          branchesToVisit.push(...branch.childrenIds);
        }

        branchesToRemove.push(branchId);
      }

      for (let branchId of branchesToRemove) {
        delete state.branchesInfo[branchId];
        delete state.branchesContents[branchId];
      }
    });

    builder.addCase(removeLocalFolder.fulfilled, (state, action) => {
      // The branch has now been removed
      const index = state.localBranchesBeingRemoved.indexOf(action.meta.arg.branchId);
      if (index !== -1) {
        state.localBranchesBeingRemoved.splice(index, 1);
      }
    });

    builder.addCase(removeLocalFolder.rejected, (state, action) => {
      const index = state.localBranchesBeingRemoved.indexOf(action.meta.arg.branchId);
      if (index !== -1) {
        state.localBranchesBeingRemoved.splice(index, 1);
      }
    });

    // Sync cart contents

    builder.addCase(syncCartContents.fulfilled, (state, action) => {
      const contents = action.payload.cartContents;
      contents?.forEach((c) => (state.contents[c.id] = c));
    });
  }
});

export default contentsSlice.reducer;
