// TODO move to lindaleUI (merge with PopupOverlay)

import React from 'react';
import classnames from 'classnames';
import * as MUI from '@material-ui/core';

import IconButton from '../lindaleui/components/IconButton';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  })
);

type Props = MUI.DialogProps & {
  onClose?: () => void;
};

export default function Dialog(props: Props) {
  const classes = useStyles();

  return (
    <MUI.Dialog {...props}>
      {props.children}

      {props.onClose && (
        <IconButton
          icon='mdi-close'
          size={18}
          className={classnames(classes.closeButton, 'close-button')}
          onClick={props.onClose}
        />
      )}
    </MUI.Dialog>
  );
}
