import { createSlice } from '@reduxjs/toolkit';
import {
  updateBranchPath,
  updateContentPath,
  updateSearchKeywords,
  updateSearchFilters,
  updateSortCriteria,
  updateVendor
} from './navigationActions';

import { initialState } from './navigationState';

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateBranchPath, (state, action) => {
      // Note: do not assign the whole array of Immer won't work! We need to mutate it
      state.branchPath.splice(0, state.branchPath.length, ...action.payload);
    });

    builder.addCase(updateContentPath, (state, action) => {
      state.contentPath = action.payload;
    });

    builder.addCase(updateSearchKeywords, (state, action) => {
      state.searchKeywords = action.payload;
    });

    builder.addCase(updateSearchFilters, (state, action) => {
      state.searchFilters = action.payload;
    });

    builder.addCase(updateSortCriteria, (state, action) => {
      state.sortCriteria = action.payload;
    });

    builder.addCase(updateVendor, (state, action) => {
      state.vendorId = action.payload;
    });
  }
});

export default navigationSlice.reducer;
