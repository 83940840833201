import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer/polyfilled';
import ReactMarkdown from 'react-markdown';
import RemarkBreaks from 'remark-breaks';
import classnames from 'classnames';
import equal from 'fast-deep-equal';
import * as MUI from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Button from '../lindaleui/components/Button';
import Checkbox from '../lindaleui/components/Checkbox';
import Icon from '../lindaleui/components/Icon';
import IconButton from '../lindaleui/components/IconButton';
import Slider from '../lindaleui/components/Slider';

import { BranchCard, LoadingBranchCard } from './BranchCard';
import { ContentCard, LoadingContentCard } from './ContentCard';
import { ContentListItem, LoadingContentListItem } from './ContentListItem';

import { RENDERERS_DATA, CONTENT_TYPES_DATA } from '../constants';
import { dispatchAndNotify } from '../store';
import { Status } from '../types';
import { getBestImageUrl, UMap, createLocationString, useCustomSnackbar } from '../utils';
import {
  LOCAL_ID,
  MARKET_ID,
  selectAllContents,
  selectFreeContents,
  selectFeaturedContents,
  selectPopularContents
} from '../features/contents/contentsState';
import { selectFavoriteContents } from '../features/favorites/favoritesState';
import { HideFullGeometryFiles } from '../features/app/appTypes';
import { selectReadOnly, selectPreferences } from '../features/app/appState';
import { setHideFullGeometryFiles, setHideTheaSubfolders } from '../features/app/appActions';
import {
  selectCurrentLocation,
  selectCurrentBranchChildren,
  selectCurrentBranchInfo,
  selectContentsInCurrentBranch,
  selectCurrentBranchContents,
  selectCurrentVendor,
  isPathLocal,
  isPathLocalHome,
  isPathMarket,
  isPathMarketHome,
  DEFAULT_FILTERS,
  selectSearchFilters,
  selectSortCriteria,
  SearchFiltersContentType,
  SortCriteria,
  selectFiltersEnabled
} from '../features/navigation/navigationState';
import { searchContentsInPath } from '../features/contents/contentsActions';
import { BranchInfo, Content, Renderer, Vendor } from '../features/contents/contentsTypes';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    root: {
      top: theme.headerHeight,
      bottom: 0,
      right: 0,
      flexGrow: 1,
      overflow: 'auto',
      padding: '24px'
    },
    content: {},
    alert: {
      marginBottom: '8px'
    },
    section: {
      marginBottom: '24px'
    },
    sectionTitle: {
      color: '#888',
      margin: '0 0 12px 0',
      fontSize: '0.75rem'
    },

    vendorContainer: {
      [theme.breakpoints.down('sm')]: {
        // Small screens: vertical vendor info
        flexDirection: 'column'
      }
    },
    vendorBio: {
      [theme.breakpoints.up('md')]: {
        // Large screens: keep the bio compact
        maxWidth: '400px'
      }
    },
    priceBlock: {
      display: 'flex',
      flexWrap: 'nowrap'
    },
    contents: {},
    addFolderPlaceholder: {
      height: '40px',
      padding: '0 12px',
      display: 'flex',
      alignItems: 'center',
      border: 'solid 1px #ddd',
      borderRadius: 4,
      color: 'rgba(0,0,0,0.35)',
      fontSize: '0.75rem',
      fontStyle: 'italic',
      cursor: 'pointer',
      boxSizing: 'content-box'
    },
    addFolderPlaceholderReadOnly: {
      color: 'rgba(0,0,0,0.2)',
      cursor: 'not-allowed'
    },
    addFolderPlaceholderIcon: {
      marginRight: '8px'
    },

    carouselContainer: {
      marginRight: '-24px',
      marginLeft: '-4px',
      '& .react-multiple-carousel__arrow--left': {
        left: '16px !important'
      },
      '& .react-multiple-carousel__arrow--right': {
        right: '8px !important'
      }
    }
  })
);

interface Props {
  staffPicks?: Content[];
  trending?: Content[];
  onAddLocalFolder: () => void;
  onOpenCart: () => void;
  onOpenBundleDownloadDetails: (id: string) => void;
}

export default function BranchContent_(props: Props) {
  const classes = useStyles(props);
  const history = useHistory();
  const { enqueueSnackbar } = useCustomSnackbar();

  const [gridCols, setGridCols] = React.useState(3);
  const [contentColWidth, setContentColWidth] = React.useState(200);
  const contentSizeRef = useResizeObserver();

  const [listView, setListView] = React.useState(false);

  const allContents = useSelector(selectAllContents);
  const freeContents = useSelector(selectFreeContents);
  const featuredContents = useSelector(selectFeaturedContents);
  const popularContents = useSelector(selectPopularContents);
  const branchInfo = useSelector(selectCurrentBranchInfo);
  const branchContents = useSelector(selectContentsInCurrentBranch);
  const favoriteContents = useSelector(selectFavoriteContents);
  const branchContentStatus = useSelector(selectCurrentBranchContents);
  const currentLocation = useSelector(selectCurrentLocation);
  const branchChildren = useSelector(selectCurrentBranchChildren);
  const areFiltersEnabled = useSelector(selectFiltersEnabled);
  const sortCriteria = useSelector(selectSortCriteria);
  const currentVendor = useSelector(selectCurrentVendor);
  const readOnly = useSelector(selectReadOnly);

  // Adapt the column count to the available width

  React.useEffect(() => {
    if (contentSizeRef.width) {
      const gridColsCount = Math.floor(contentSizeRef.width / 200);
      setGridCols(gridColsCount);
      setContentColWidth(contentSizeRef.width / gridColsCount);
    }
  }, [contentSizeRef.width]);

  if (branchInfo === undefined || branchContentStatus === undefined) {
    return null;
  }

  const handleFiltersClear = () => {
    history.push(createLocationString({ ...currentLocation, searchFilters: DEFAULT_FILTERS }));
  };

  const handleSortCriteriaChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    history.push(
      createLocationString({
        ...currentLocation,
        sortCriteria: e.target.value as SortCriteria
      })
    );
  };

  const onLoadMoreContents = () => {
    dispatchAndNotify(
      searchContentsInPath({
        location: currentLocation,
        append: true
      }),
      enqueueSnackbar
    );
  };

  // Render a sub-branch cart

  const getBranchCards = (branches: UMap<BranchInfo>, status: Status) => {
    // Show placeholder cards if loading

    if (status === 'loading') {
      return [
        <LoadingBranchCard key={1} />,
        <LoadingBranchCard key={2} />,
        <LoadingBranchCard key={3} />
      ];
    }

    // Or the real thing

    const branchCards = Object.keys(branches).map((id) => {
      const branch = branches[id];

      return branch ? (
        <BranchCard
          key={id}
          name={branch.name}
          // Go to the target branch and clear the rest of the location
          href={createLocationString({
            ...currentLocation,
            branchPath: [...currentLocation.branchPath, id],
            contentPath: [],
            vendorId: undefined
            // Keep the keywords (different behavior than the navigation drawer)
          })}
          info={branch.contentAmount}
          icon={branch.icon}
          iconColor={branch.iconColor}
        />
      ) : null;
    });

    // Local folder: add an "Add folder" button

    if (isPathLocalHome(currentLocation.branchPath)) {
      branchCards.push(
        <MUI.Tooltip title={readOnly ? 'You cannot add a folder in Read-Only mode' : ''}>
          <MUI.GridListTile
            onClick={readOnly ? undefined : props.onAddLocalFolder}
            classes={{
              tile:
                classes.addFolderPlaceholder +
                (readOnly ? ' ' + classes.addFolderPlaceholderReadOnly : '')
            }}
          >
            <div className={classes.addFolderPlaceholderIcon}>
              <Icon icon='mdi-folder-plus-outline' />
            </div>
            <div>Add Folder</div>
          </MUI.GridListTile>
        </MUI.Tooltip>
      );
    }

    return branchCards;
  };

  // Render a content card

  const getLoadingContentCards = (amount: number, list: boolean = false) => {
    const Component = list ? LoadingContentListItem : LoadingContentCard;
    return [...Array(amount)].map((i) => <Component key={i} />);
  };

  const getContentCards = (contents: Content[], status: Status, list: boolean = false) => {
    const cards = contents.map((content, i) => {
      // Find the resolution closest to the column width

      const isBundle =
        (content.type === 'marketcontent' || content.type === 'localcontent') &&
        content.contentTypes.includes('bundle');

      const Component = list ? ContentListItem : ContentCard;

      return (
        <Component
          key={'list' + content.id}
          contentId={content.id}
          columnWidth={contentColWidth}
          onOpenCart={props.onOpenCart}
          onOpenBundleDownloadDetails={props.onOpenBundleDownloadDetails}
          stacked={isBundle}
        />
      );
    });

    if (status === 'loading') {
      const contentAmount = isPathMarketHome(currentLocation.branchPath)
        ? 40
        : Math.min(20, branchInfo.contentAmount || 3);

      cards.push(...getLoadingContentCards(contentAmount, list));
    }

    return cards;
  };

  const getBranchesTitle = () => {
    switch (currentLocation.branchPath[0]) {
      case MARKET_ID:
        return 'Categories';
      case LOCAL_ID:
        return 'Folders';
    }
  };

  const getContentsTitle = () => {
    switch (currentLocation.branchPath[0]) {
      case MARKET_ID:
        if (currentLocation.branchPath.length > 1) return 'Assets';
        else return 'All Assets';
      case LOCAL_ID:
        if (currentLocation.branchPath.length > 1) return 'Files';
        else return 'All Files';
    }
  };

  const getVendorAvatar = (vendor: Vendor, size: number) => {
    const imageUrl = vendor.avatar?.urls ? getBestImageUrl(vendor.avatar?.urls, size) : undefined;

    const style = { width: size, height: size, border: '1px solid rgba(0,0,0,0.1)' };

    return imageUrl ? (
      <MUI.Avatar src={imageUrl} style={style} />
    ) : (
      <MUI.Avatar style={style}>
        <Icon icon='mdi-account-outline' size={48} />
      </MUI.Avatar>
    );
  };

  // No results: say it explicitly and propose to clear filters
  const noResults = branchContents.length === 0 && branchContentStatus.contentStatus === 'loaded' && (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <MUI.Typography
        align='center'
        style={{ marginTop: '8rem', fontStyle: 'italic', color: 'gray' }}
      >
        No results
      </MUI.Typography>

      {/* "Clear filters" / "Clear search" shortcuts */}

      <MUI.Grid container spacing={1} justify='center'>
        {areFiltersEnabled && (
          <MUI.Grid item>
            <MUI.Typography
              display='inline'
              align='center'
              color='primary'
              style={{
                marginTop: '0.5rem',
                textTransform: 'uppercase',
                cursor: 'pointer'
              }}
              onClick={handleFiltersClear}
            >
              Clear filters
            </MUI.Typography>
          </MUI.Grid>
        )}

        {areFiltersEnabled && currentLocation.searchKeywords.length > 0 && (
          <MUI.Grid item>
            <MUI.Typography display='inline' align='center' color='textSecondary'>
              /
            </MUI.Typography>
          </MUI.Grid>
        )}

        {currentLocation.searchKeywords.length > 0 && (
          <MUI.Grid item>
            <MUI.Typography
              display='inline'
              align='center'
              color='primary'
              style={{
                marginTop: '0.5rem',
                textTransform: 'uppercase',
                cursor: 'pointer'
              }}
              onClick={() =>
                history.push(createLocationString({ ...currentLocation, searchKeywords: [] }))
              }
            >
              Clear search
            </MUI.Typography>
          </MUI.Grid>
        )}
      </MUI.Grid>
    </div>
  );

  const showChildren =
    (Object.keys(branchChildren).length > 0 ||
      isPathLocalHome(currentLocation.branchPath) ||
      isPathMarketHome(currentLocation.branchPath)) &&
    !currentVendor;

  const isMarket = isPathMarket(currentLocation.branchPath);
  const isLocal = isPathLocal(currentLocation.branchPath);

  return (
    <main className={classes.root}>
      <div className={classes.content} ref={contentSizeRef.ref}>
        {/*props.branchStructure.errors &&
                    props.branchStructure.errors.map((error, index) => { return <Alert key={index} severity='error' className={classes.alert}>{ error }</Alert> })
                */}

        {currentLocation.branchPath[0] === LOCAL_ID && readOnly && (
          <Alert severity='warning' className={classes.alert}>
            Because another instance of the 3D Bazaar is open, you are currently in <b>Read-Only</b>{' '}
            mode. This means that you can access your local library, but you cannot add/remove
            folders.
          </Alert>
        )}

        {/* Vendor info */}

        {currentVendor && (
          <MUI.Grid container spacing={2} justify='center' className={classes.vendorContainer}>
            <MUI.Grid item>
              <MUI.Box display='flex' justifyContent='center' mb='12px'>
                {getVendorAvatar(currentVendor, 128)}
              </MUI.Box>
              <MUI.Box display='flex' justifyContent='center' mb='8px'>
                <MUI.Typography variant='h6'>{currentVendor.username}</MUI.Typography>
              </MUI.Box>
              <MUI.Box display='flex' justifyContent='center' mb='8px'>
                {currentVendor.website && (
                  <MUI.Box mr='8px'>
                    <IconButton
                      icon='mdi-web'
                      size={24}
                      onClick={() => window.open(currentVendor.website ?? undefined, '_blank')}
                    />
                  </MUI.Box>
                )}
                {currentVendor.publicEmail && (
                  <MUI.Box>
                    <IconButton
                      icon='mdi-email-outline'
                      size={24}
                      onClick={() => window.open(`mailto:${currentVendor.publicEmail}`, '_blank')}
                    />
                  </MUI.Box>
                )}
              </MUI.Box>
            </MUI.Grid>

            {currentVendor?.bio && (
              <MUI.Grid
                item
                container
                justify='center'
                alignItems='center'
                className={classes.vendorBio}
              >
                <MUI.Grid item>
                  <MUI.Typography
                    variant='body2'
                    style={{
                      maxWidth: '600px',
                      textAlign: 'justify',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    <ReactMarkdown
                      source={currentVendor.bio}
                      escapeHtml={false}
                      plugins={[RemarkBreaks]}
                      renderers={{
                        paragraph: (props) => <React.Fragment {...props} />
                      }}
                    />
                  </MUI.Typography>
                </MUI.Grid>
              </MUI.Grid>
            )}
          </MUI.Grid>
        )}

        {showChildren && (
          <div className={classnames(classes.section, 'branch-cards')}>
            <h4 className={classes.sectionTitle}>{getBranchesTitle()}</h4>
            <MUI.GridList cols={gridCols} spacing={8} cellHeight='auto'>
              {getBranchCards(branchChildren, branchInfo.childrenStatus)}
            </MUI.GridList>
          </div>
        )}

        {/* Marketplace free content */}
        {isPathMarketHome(currentLocation.branchPath) &&
          currentLocation.searchKeywords.length === 0 &&
          allContents &&
          !currentVendor && (
            <div className={classes.section}>
              <h4 className={classes.sectionTitle}>
                <Icon icon='mdi-gift-outline' size={18} /> Free
              </h4>

              <Carousel
                swipeable={false}
                draggable={false}
                partialVisible
                responsive={{
                  a: {
                    breakpoint: { max: 4000, min: 0 },
                    items: gridCols,
                    partialVisibilityGutter: 24 / gridCols
                  }
                }}
                slidesToSlide={gridCols}
                containerClass={classes.carouselContainer}
              >
                {getContentCards(
                  freeContents,
                  freeContents && freeContents.length > 0 ? 'loaded' : 'loading'
                ).map((c) => {
                  return <MUI.Box p='4px'>{c}</MUI.Box>;
                })}
              </Carousel>
            </div>
          )}

        {/* Marketplace featured content */}
        {isPathMarketHome(currentLocation.branchPath) &&
          currentLocation.searchKeywords.length === 0 &&
          allContents &&
          !currentVendor && (
            <div className={classes.section}>
              <h4 className={classes.sectionTitle}>
                <Icon icon='mdi-star-circle-outline' size={18} /> Featured
              </h4>

              <Carousel
                swipeable={false}
                draggable={false}
                partialVisible
                responsive={{
                  a: {
                    breakpoint: { max: 4000, min: 0 },
                    items: gridCols,
                    partialVisibilityGutter: 24 / gridCols
                  }
                }}
                slidesToSlide={gridCols}
                containerClass={classes.carouselContainer}
              >
                {getContentCards(
                  featuredContents,
                  featuredContents && featuredContents.length > 0 ? 'loaded' : 'loading'
                ).map((c) => {
                  return <MUI.Box p='4px'>{c}</MUI.Box>;
                })}
              </Carousel>
            </div>
          )}

        {/* Marketplace popular content */}
        {isPathMarketHome(currentLocation.branchPath) &&
          currentLocation.searchKeywords.length === 0 &&
          allContents &&
          !currentVendor && (
            <div className={classes.section}>
              <h4 className={classes.sectionTitle}>
                <Icon icon='mdi-fire' size={18} /> Popular
              </h4>

              <Carousel
                swipeable={false}
                draggable={false}
                partialVisible
                responsive={{
                  a: {
                    breakpoint: { max: 4000, min: 0 },
                    items: gridCols,
                    partialVisibilityGutter: 24 / gridCols
                  }
                }}
                slidesToSlide={gridCols}
                containerClass={classes.carouselContainer}
              >
                {getContentCards(
                  popularContents,
                  popularContents && popularContents.length > 0 ? 'loaded' : 'loading'
                ).map((c) => {
                  return <MUI.Box p='4px'>{c}</MUI.Box>;
                })}
              </Carousel>
            </div>
          )}

        {isPathLocalHome(currentLocation.branchPath) &&
          allContents &&
          favoriteContents &&
          favoriteContents.length > 0 && (
            <div className={classes.section}>
              <h4 className={classes.sectionTitle}>
                <Icon icon='mdi-heart-outline' size={18} /> Favorites
              </h4>

              <MUI.GridList cols={gridCols} spacing={8} cellHeight='auto'>
                {getContentCards(favoriteContents, branchContentStatus.contentStatus)}
              </MUI.GridList>
            </div>
          )}

        {branchContents && (
          <div className={classes.section}>
            <h4 className={classes.sectionTitle}>{getContentsTitle()}</h4>

            <MUI.Box display='flex' mb='16px' alignItems='center' flexWrap='wrap'>
              <Filters />

              <MUI.Box mb={0.5} display='flex' alignItems='center' flexWrap='wrap'>
                <MUI.Select
                  input={<MUI.OutlinedInput margin='dense' />}
                  variant='outlined'
                  IconComponent={() => (
                    <Icon
                      icon='mdi-sort-variant'
                      color='#aaa'
                      style={{
                        position: 'absolute',
                        right: '7px',
                        pointerEvents: 'none'
                      }}
                    />
                  )}
                  value={sortCriteria}
                  onChange={handleSortCriteriaChange}
                  style={{ marginRight: '8px' }}
                >
                  <MUI.MenuItem value={SortCriteria.DEFAULT}>Most Relevant</MUI.MenuItem>
                  {/* Some sorting orders are for the marketplace only */}
                  {isMarket && (
                    <MUI.MenuItem value={SortCriteria.PRICE_ASC}>Lower Price</MUI.MenuItem>
                  )}
                  {isMarket && (
                    <MUI.MenuItem value={SortCriteria.PRICE_DESC}>Higher Price</MUI.MenuItem>
                  )}
                  {isMarket && <MUI.MenuItem value={SortCriteria.DATE_DESC}>Newest</MUI.MenuItem>}
                  {isMarket && <MUI.MenuItem value={SortCriteria.DATE_ASC}>Oldest</MUI.MenuItem>}
                  <MUI.MenuItem value={SortCriteria.NAME_ASC}>A-Z</MUI.MenuItem>
                  <MUI.MenuItem value={SortCriteria.NAME_DESC}>Z-A</MUI.MenuItem>
                </MUI.Select>

                <MUI.Box>
                  <MUI.IconButton onClick={() => setListView(!listView)}>
                    <Icon icon={listView ? 'mdi-view-grid' : 'mdi-view-list'} color='#888' />
                  </MUI.IconButton>
                </MUI.Box>

                {isLocal && <LocalOptions />}
              </MUI.Box>
            </MUI.Box>

            {listView ? (
              <MUI.List dense>
                {getContentCards(branchContents, branchContentStatus.contentStatus, true)}
              </MUI.List>
            ) : (
              <MUI.GridList cols={gridCols} spacing={8} cellHeight='auto'>
                {getContentCards(branchContents, branchContentStatus.contentStatus)}
              </MUI.GridList>
            )}

            {branchContents.length > 0 && !branchContentStatus.contentComplete && (
              <MUI.Box width='100%' mt={2} display='flex' justifyContent='center'>
                <Button
                  variant='contained'
                  color='primary'
                  loading={branchContentStatus.contentStatus === 'loading'}
                  disabled={branchContentStatus.contentStatus === 'loading'}
                  onClick={onLoadMoreContents}
                >
                  Load more
                </Button>
              </MUI.Box>
            )}
          </div>
        )}

        {noResults}
      </div>
    </main>
  );
}

// Search filters

const useStyles2 = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    searchFilter: {
      marginRight: '16px',
      marginBottom: '4px',
      display: 'flex',
      alignItems: 'center'
    },
    searchFilterCheckbox: {
      marginRight: '4px'
    },
    priceBlock: {
      display: 'flex',
      flexWrap: 'nowrap'
    }
  })
);

interface SearchFiltersProps {}

function Filters_(props: SearchFiltersProps) {
  const classes = useStyles2();

  const history = useHistory();
  const currentLocation = useSelector(selectCurrentLocation);
  const searchFilters = useSelector(selectSearchFilters);

  const isMarket = isPathMarket(currentLocation.branchPath);

  // Events

  const handleFilterFreeChange = (e: React.ChangeEvent, checked: boolean) => {
    history.push(
      createLocationString({
        ...currentLocation,
        searchFilters: { ...currentLocation.searchFilters, free: checked }
      })
    );
  };

  const handleFilterPriceChange = (range: number | number[]) => {
    if (Array.isArray(range) && range.length === 2) {
      history.push(
        createLocationString({
          ...currentLocation,
          searchFilters: {
            ...currentLocation.searchFilters,
            priceRange: {
              min: range[0],
              max: range[1]
            }
          }
        })
      );
    }
  };

  const handleFilterContentTypesChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    history.push(
      createLocationString({
        ...currentLocation,
        searchFilters: {
          ...currentLocation.searchFilters,
          contentTypes: e.target.value as SearchFiltersContentType[]
        }
      })
    );
  };

  const handleSearchFilterRenderersChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    history.push(
      createLocationString({
        ...currentLocation,
        searchFilters: { ...currentLocation.searchFilters, renderers: e.target.value as Renderer[] }
      })
    );
  };

  const handleFiltersClear = () => {
    history.push(createLocationString({ ...currentLocation, searchFilters: DEFAULT_FILTERS }));
  };

  // Render

  return (
    <MUI.Box flex={1} display='flex' flexWrap='wrap' alignItems='center'>
      {isMarket && (
        <>
          {/* Free + price range in the same container to prevent them getting separated on small screens */}
          <MUI.Box className={classes.priceBlock}>
            <MUI.Box className={classes.searchFilter}>
              <MUI.Checkbox
                size='small'
                id='freeFilter'
                checked={searchFilters.free}
                onChange={handleFilterFreeChange}
              />
              <MUI.Box ml='-4px'>
                <label htmlFor='freeFilter'>Free</label>
              </MUI.Box>
            </MUI.Box>

            <MUI.Box className={classes.searchFilter}>
              <div style={searchFilters.free ? { color: '#888' } : undefined}>
                ${DEFAULT_FILTERS.priceRange.min}
              </div>
              <MUI.Box width='100px' display='flex' ml='8px' mr='8px'>
                <Slider
                  value={[searchFilters.priceRange.min, searchFilters.priceRange.max]}
                  min={DEFAULT_FILTERS.priceRange.min}
                  max={DEFAULT_FILTERS.priceRange.max}
                  step={1}
                  onChange={handleFilterPriceChange}
                  valueLabelDisplay='auto'
                  disabled={searchFilters.free}
                  sliderStyle={
                    searchFilters.priceRange.min === DEFAULT_FILTERS.priceRange.min &&
                    searchFilters.priceRange.max === DEFAULT_FILTERS.priceRange.max
                      ? { color: '#888' }
                      : undefined
                  }
                />
              </MUI.Box>
              <div style={searchFilters.free ? { color: '#888' } : undefined}>
                ${DEFAULT_FILTERS.priceRange.max}+
              </div>
            </MUI.Box>
          </MUI.Box>

          <MUI.Box className={classes.searchFilter}>
            <MUI.Select
              multiple
              variant='outlined'
              input={<MUI.OutlinedInput margin='dense' />}
              value={searchFilters.contentTypes}
              onChange={handleFilterContentTypesChange}
              renderValue={(selected) => {
                const selectedTypes = selected as SearchFiltersContentType[];
                if (selectedTypes.length > 0) {
                  return (
                    <span style={{ fontWeight: 'bold' }}>
                      {selectedTypes.map((type) => CONTENT_TYPES_DATA[type].name).join(', ')}
                    </span>
                  );
                } else {
                  return 'All Types';
                }
              }}
              displayEmpty
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
                // @ts-ignore
                getContentAnchorEl: () => null
              }}
            >
              <MUI.MenuItem key='model' value='model'>
                <Checkbox
                  size={18}
                  checked={searchFilters.contentTypes.includes('model')}
                  className={classes.searchFilterCheckbox}
                />
                {CONTENT_TYPES_DATA['model'].name}
              </MUI.MenuItem>
              <MUI.MenuItem key={'composition'} value={'composition'}>
                <Checkbox
                  size={18}
                  checked={searchFilters.contentTypes.includes('composition')}
                  className={classes.searchFilterCheckbox}
                />
                {CONTENT_TYPES_DATA['composition'].name}
              </MUI.MenuItem>
              <MUI.MenuItem key={'bundle'} value={'bundle'}>
                <Checkbox
                  size={18}
                  checked={searchFilters.contentTypes.includes('bundle')}
                  className={classes.searchFilterCheckbox}
                />
                {CONTENT_TYPES_DATA['bundle'].name}
              </MUI.MenuItem>
            </MUI.Select>
          </MUI.Box>

          <MUI.Box className={classes.searchFilter}>
            <MUI.Select
              multiple
              variant='outlined'
              input={<MUI.OutlinedInput margin='dense' />}
              value={searchFilters.renderers}
              onChange={handleSearchFilterRenderersChange}
              renderValue={(selected) => {
                const selectedRenderers = selected as Renderer[];
                if (selectedRenderers.length > 0) {
                  return (
                    <span style={{ fontWeight: 'bold' }}>
                      {selectedRenderers
                        .map((renderer) => RENDERERS_DATA[renderer].name)
                        .join(', ')}
                    </span>
                  );
                } else {
                  return 'All Render Engines';
                }
              }}
              displayEmpty
              MenuProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
                // @ts-ignore
                getContentAnchorEl: () => null
              }}
            >
              <MUI.MenuItem key='vray' value='vray'>
                <Checkbox
                  size={18}
                  checked={searchFilters.renderers.includes('vray')}
                  className={classes.searchFilterCheckbox}
                />
                {RENDERERS_DATA['vray'].name}
              </MUI.MenuItem>
              <MUI.MenuItem key='enscape' value='enscape'>
                <Checkbox
                  size={18}
                  checked={searchFilters.renderers.includes('enscape')}
                  className={classes.searchFilterCheckbox}
                />
                {RENDERERS_DATA['enscape'].name}
              </MUI.MenuItem>
              <MUI.MenuItem key='thea' value='thea'>
                <Checkbox
                  size={18}
                  checked={searchFilters.renderers.includes('thea')}
                  className={classes.searchFilterCheckbox}
                />
                {RENDERERS_DATA['thea'].name}
              </MUI.MenuItem>
            </MUI.Select>
          </MUI.Box>

          {!equal(searchFilters, DEFAULT_FILTERS) && (
            <MUI.Box className={classes.searchFilter}>
              <MUI.Button size='small' color='primary' onClick={handleFiltersClear}>
                Clear
              </MUI.Button>
            </MUI.Box>
          )}
        </>
      )}
    </MUI.Box>
  );
}

const Filters = React.memo(Filters_);

// Local options

interface LocalOptionsProps {}

function LocalOptions_(props: LocalOptionsProps) {
  const { enqueueSnackbar } = useCustomSnackbar();
  const { hideFullGeometryFiles, hideTheaSubfolders } = useSelector(selectPreferences);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!Boolean(anchorEl)) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeHideFullGeometryFiles = (event: React.ChangeEvent<{ value: unknown }>) => {
    console.log(`handleChangeHideFullGeometryFiles: ${event.target.value}`);
    dispatchAndNotify(
      setHideFullGeometryFiles(event.target.value as HideFullGeometryFiles),
      enqueueSnackbar
    );
  };

  const handleChangeHideTheaSubfolders = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`handleChangeHideTheaSubfolders: ${event.target.checked}`);
    dispatchAndNotify(setHideTheaSubfolders(event.target.checked), enqueueSnackbar);
  };

  return (
    <MUI.Box>
      <MUI.IconButton onClick={handleOpen}>
        <Icon icon='mdi-cog' color='#888' />
        <MUI.Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            style: { minWidth: '300px' }
          }}
        >
          <MUI.ListItem style={{ width: '100%', outline: 'none' }}>
            <MUI.Box width='100%'>
              <MUI.ListItemText
                primary={
                  <MUI.Typography variant='body1' style={{ fontWeight: 'bold' }}>
                    Hide <em>..._fullGeometry.skp</em>&nbsp;&nbsp;files
                  </MUI.Typography>
                }
              />
              <MUI.Box mt='8px' mb='8px'>
                <MUI.Select
                  fullWidth
                  variant='outlined'
                  margin='dense'
                  value={hideFullGeometryFiles || ''}
                  onChange={handleChangeHideFullGeometryFiles}
                >
                  <MUI.MenuItem value={HideFullGeometryFiles.NEVER}>Never</MUI.MenuItem>
                  <MUI.MenuItem value={HideFullGeometryFiles.SIBLINGEXISTS}>
                    If non-fullGeometry file exists
                  </MUI.MenuItem>
                  <MUI.MenuItem value={HideFullGeometryFiles.ALWAYS}>Always</MUI.MenuItem>
                </MUI.Select>
              </MUI.Box>
              {hideFullGeometryFiles === HideFullGeometryFiles.SIBLINGEXISTS && (
                <MUI.Typography variant='caption' color='textSecondary'>
                  For example, if <em>tree.skp</em> exists, <em>tree_fullGeometry.skp</em> will be
                  hidden
                </MUI.Typography>
              )}
            </MUI.Box>
          </MUI.ListItem>

          <MUI.Divider variant='middle' style={{ marginTop: '8px', marginBottom: '8px' }} />

          <MUI.ListItem style={{ width: '100%' }}>
            <MUI.ListItemText
              primary={
                <MUI.Typography variant='body1' style={{ fontWeight: 'bold' }}>
                  Hide <em>..._thea</em>&nbsp;&nbsp;subfolders
                </MUI.Typography>
              }
            />
            <MUI.ListItemSecondaryAction>
              <MUI.Switch
                size='small'
                onChange={handleChangeHideTheaSubfolders}
                checked={hideTheaSubfolders !== undefined ? hideTheaSubfolders : false}
              />
            </MUI.ListItemSecondaryAction>
          </MUI.ListItem>
        </MUI.Menu>
      </MUI.IconButton>
    </MUI.Box>
  );
}

const LocalOptions = React.memo(LocalOptions_);
