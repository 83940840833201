import { createAsyncThunk } from '@reduxjs/toolkit';
import { extname } from 'path';
import * as zod from 'zod';
import { ActionError, RootState, toActionError } from '../../store';

import { isExtension, sketchupSchema, sketchupAsync } from '../../utils';
import {
  Content,
  LocalFile,
  LocalFileSchema,
  localFilesToBranchContents
} from '../contents/contentsTypes';

export const fetchFavoriteContents = createAsyncThunk<
  Content[],
  void,
  { state: RootState; rejectValue: ActionError }
>('favorites/fetchFavoriteContents', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot fetch favorite contents out of extension');
    }

    const schema = sketchupSchema(
      zod.string() // JSON
    );

    const response = await sketchupAsync({
      methodName: 'getFavoriteContents',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    // Convert to LocalFiles

    const filesData = JSON.parse(response.data);

    if (!Array.isArray(filesData)) {
      throw new Error('get favorite contents result is not an array');
    }

    const typedFilesData: LocalFile[] = filesData
      .map((fileData) => {
        // It's possible to receive null entries for missing files
        if (!fileData) {
          return null;
        }

        const typedFileData = {
          ...fileData,

          // We must attach a "type" field that depends on the path's extension
          // in order to benefit from TS union discrimination.
          //
          // The core doesn't provide this type as it would be redundant with the path.
          type: extname(fileData.path).slice(1) // remove dot
        };

        return LocalFileSchema.parse(typedFileData);
      })
      .filter((x): x is LocalFile => x !== null); // Filter null entries

    const contents = localFilesToBranchContents(typedFilesData);

    return contents;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch favorite contents', error));
  }
});

export const toggleFavorite = createAsyncThunk<
  string[],
  { id: string; toggle: boolean },
  { state: RootState; rejectValue: ActionError }
>('favorites/toggle', async ({ id, toggle }, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot toggle favorite out of extension');
    }

    const schema = sketchupSchema(zod.array(zod.string()));

    const response = await sketchupAsync({
      methodName: 'favoriteContent',
      args: [id, toggle],
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('toggle favorite', error));
  }
});
