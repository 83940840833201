import { createSlice } from '@reduxjs/toolkit';

import {
  login,
  logout,
  register,
  fetchAuthToken,
  refreshAuthToken,
  loginAuth
} from './authActions';
import { initialState } from './authState';

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Login

    builder.addCase(loginAuth.pending, (state, action) => {
      state.loginErrors = {};
    });

    builder.addCase(loginAuth.fulfilled, (state, action) => {
      // The login request is fulfilled != login successful.
      // We handle both cases here!

      if (action.payload.success) {
        state.authToken = action.payload.authToken;
        state.refreshToken = action.payload.refreshToken;
      } else {
        state.loginErrors.message = action.payload.message;
        state.loginErrors.email = action.payload.email;
        state.loginErrors.password = action.payload.password;
      }
    });

    builder.addCase(loginAuth.rejected, (state, action) => {
      state.authToken = undefined;
      state.refreshToken = undefined;
    });

    builder.addCase(login.pending, (state, action) => {
      state.operationInprogress = true;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      state.operationInprogress = false;
    });

    builder.addCase(login.rejected, (state, action) => {
      state.operationInprogress = false;
    });

    // Logout

    builder.addCase(logout.pending, (state, action) => {
      state.operationInprogress = true;
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      state.authToken = undefined;
      state.refreshToken = undefined;
      state.operationInprogress = false;
    });

    builder.addCase(logout.rejected, (state, action) => {
      state.authToken = undefined;
      state.refreshToken = undefined;
      state.operationInprogress = false;
    });

    // Register

    builder.addCase(register.pending, (state, action) => {
      state.operationInprogress = true;
      state.registerErrors = {};
      state.registerSuccessMessage = undefined;
    });

    builder.addCase(register.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.registerSuccessMessage = action.payload.message;
      } else {
        state.registerErrors.message = action.payload.message;
        state.registerErrors.username = action.payload.username;
        state.registerErrors.email = action.payload.email;
        state.registerErrors.password = action.payload.password;
      }

      state.operationInprogress = false;
    });

    builder.addCase(register.rejected, (state, action) => {
      state.operationInprogress = false;
    });

    // Fetch auth token

    builder.addCase(fetchAuthToken.fulfilled, (state, action) => {
      state.refreshToken = action.payload;
    });

    builder.addCase(fetchAuthToken.rejected, (state, action) => {
      state.refreshToken = undefined;
    });

    // Refresh auth token

    builder.addCase(refreshAuthToken.pending, (state, action) => {
      state.isRefreshingToken = true;
    });

    builder.addCase(refreshAuthToken.fulfilled, (state, action) => {
      state.authToken = action.payload.authToken;
      state.refreshToken = action.payload.refreshToken;
      state.isRefreshingToken = false;
    });

    builder.addCase(refreshAuthToken.rejected, (state, action) => {
      state.authToken = undefined;
      state.refreshToken = undefined;
      state.isRefreshingToken = false;
    });
  }
});

export default userSlice.reducer;
