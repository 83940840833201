import * as zod from 'zod';

// Hide '_fullGeometry.skp' files

export enum HideFullGeometryFiles {
  NEVER = 'never',
  SIBLINGEXISTS = 'siblingexists',
  ALWAYS = 'always'
}
export const HideFullGeometryFilesSchema = zod.nativeEnum(HideFullGeometryFiles);
