import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';
import { SnackbarKey } from 'notistack';
import * as MUI from '@material-ui/core';
import { basename, dirname, extname } from 'path';
import prettyBytes from 'pretty-bytes';
import sleep from 'sleep-promise';

import Button from '../lindaleui/components/Button';
import Icon from '../lindaleui/components/Icon';
import IconButton from '../lindaleui/components/IconButton';
import MiddleEllipsis from '../lindaleui/components/MiddleEllipsis';

import { PACKAGE_TYPES_DATA, CONTENT_TYPES_DATA } from '../constants';
import { dispatchAndNotify } from '../store';
import {
  createLocationString,
  getFileTypefromName,
  getRendererIcons,
  isExtension,
  useCustomSnackbar
} from '../utils';
import { setPreferredFileType, setPreferredPackageType } from '../features/app/appActions';
import { selectMaintenanceMode, selectPreferences } from '../features/app/appState';
import {
  downloadPackage,
  importFileInSketchup,
  waitForImportEnd,
  pickDownloadLocation
} from '../features/download/downloadActions';
import {
  selectDownloadedPackages,
  selectDownloadingPackages,
  selectOngoingImport
} from '../features/download/downloadState';
import {
  LocalContent,
  MarketContent,
  ContentPackage,
  ContentPackageType,
  ContentType,
  ContentPackageFile,
  Content
} from '../features/contents/contentsTypes';
import { selectCurrentLocation } from '../features/navigation/navigationState';

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    root: {
      display: 'flex',
      '& > :not(:first-child)': {
        [theme.breakpoints.up('sm')]: {
          marginLeft: '8px'
        }
      },
      // Small screen: vertical layout
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },

    fields: {
      flex: 1,
      display: 'flex',
      '& > :not(:first-child)': {
        [theme.breakpoints.up('sm')]: {
          marginLeft: '8px'
        }
      },
      '& > :first-child': {
        [theme.breakpoints.up('xs')]: {
          marginBottom: '8px'
        }
      },
      // Small screen: vertical layout
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        width: '100%'
      }
    },
    field: {
      flexGrow: 1,
      flexBasis: 0,
      width: 0,
      marginTop: 0,
      marginBottom: 0,
      height: '36px',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    fieldLabel: {
      fontSize: '0.875rem',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center'
    },
    emphasizedField: {
      animation: `$pulseEmphasizedField 1000ms infinite`
    },
    '@keyframes pulseEmphasizedField': {
      '0%': {
        boxShadow: '0 0 0 0 rgba(255,140,0,0)'
      },
      '50%': {
        boxShadow: '0 0 10px 0 rgba(255,140,0,1)'
      },
      '100%': {
        boxShadow: '0 0 0 0 rgba(255,140,0,1)'
      }
    },

    downloadLocation: {
      width: '100%',
      textTransform: 'none'
    },
    downloadLocationLabel: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      direction: 'rtl'
    },
    mainButton: {
      // Small screen: more vertical spacing, full width
      [theme.breakpoints.down('xs')]: {
        marginTop: '0.5rem',
        width: '100%'
      }
    }
  })
);

interface Props {
  component: 'bar' | 'icon';
  content: MarketContent | LocalContent;
  className?: string;
}

function getImportableFiles(
  files: ContentPackageFile[],
  packageType: ContentPackageType
): ContentPackageFile[] {
  const importableFiles = files.filter((file) => ['.skp', '.skatter'].includes(extname(file.name)));

  // Sort alphabetically
  importableFiles.sort();

  // Move '_fullGeometry.skp' files at the bottom of the list if package is proxy
  if (packageType === 'proxy') {
    importableFiles.sort((a, b) => {
      if (
        a.name.toLowerCase().endsWith('_fullgeometry.skp') &&
        !b.name.toLowerCase().endsWith('_fullgeometry.skp')
      ) {
        return 1;
      } else if (
        !a.name.toLowerCase().endsWith('_fullgeometry.skp') &&
        b.name.toLowerCase().endsWith('_fullgeometry.skp')
      ) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  // Move .skatter files at the top of the list
  importableFiles.sort((a, b) => {
    if (a.name.toLowerCase().endsWith('.skatter') && !b.name.toLowerCase().endsWith('.skatter')) {
      return -1;
    } else if (
      !a.name.toLowerCase().endsWith('.skatter') &&
      b.name.toLowerCase().endsWith('.skatter')
    ) {
      return 1;
    } else {
      return 0;
    }
  });

  return importableFiles;
}

function ContentPrimaryAction(props: Props) {
  const classes = useStyles(props);
  const { enqueueSnackbar, closeSnackbar } = useCustomSnackbar();
  const history = useHistory();

  const currentLocation = useSelector(selectCurrentLocation);
  const maintenanceMode = useSelector(selectMaintenanceMode);
  const downloadedPackages = useSelector(selectDownloadedPackages);
  const downloadingPackages = useSelector(selectDownloadingPackages);
  const ongoingImport = useSelector(selectOngoingImport);
  const { preferredPackageType, preferredFileType, preferredDownloadLocation } = useSelector(
    selectPreferences
  );

  // Store pending notification keys when a choice has to be made by users

  const [packageTypeNotifKey, setPackageTypeNotifKey] = React.useState<SnackbarKey>();
  const [downloadLocationNotifKey, setDownloadLocationNotifKey] = React.useState<SnackbarKey>();
  const [fileToImportNotifKey, setFileToImportNotifKey] = React.useState<SnackbarKey>();

  const [highlightFields, setHighlightFields] = React.useState(false);

  // Close the notifications when unmounting the component
  // (a bit complicated as it requires refs but whatever works)

  const packageTypeNotifKeyRef = React.useRef<SnackbarKey | undefined>();
  const downloadLocationNotifKeyRef = React.useRef<SnackbarKey | undefined>();
  const fileToImportNotifKeyRef = React.useRef<SnackbarKey | undefined>();

  React.useEffect(() => {
    packageTypeNotifKeyRef.current = packageTypeNotifKey;
  }, [packageTypeNotifKey]);
  React.useEffect(() => {
    downloadLocationNotifKeyRef.current = downloadLocationNotifKey;
  }, [downloadLocationNotifKey]);
  React.useEffect(() => {
    fileToImportNotifKeyRef.current = fileToImportNotifKey;
  }, [fileToImportNotifKey]);

  React.useEffect(() => {
    return () => {
      closeSnackbar(packageTypeNotifKeyRef.current);
      closeSnackbar(downloadLocationNotifKeyRef.current);
      closeSnackbar(fileToImportNotifKeyRef.current);
    };
  }, [closeSnackbar]);

  // Download location
  // - Local content: use the same folder as existing packages
  // - Market content: let the user choose

  const downloadLocation =
    props.content.type === 'localcontent'
      ? dirname(Base64.decode(props.content.id))
      : preferredDownloadLocation;

  // Look for available packages either from:
  // - the downloaded packages if the content is a marketplace content
  // - the local content's available packages if the content is a downloaded marketplace content

  const availablePackages =
    props.content.type === 'marketcontent' ? downloadedPackages : props.content.availablePackages;

  // Selected package (can be either downloading or downloaded)

  const [selectedPackage, setSelectedPackage] = React.useState<ContentPackage>();

  const downloadedPackage = selectedPackage ? availablePackages[selectedPackage.id] : undefined;

  const downloadingPackage = selectedPackage ? downloadingPackages[selectedPackage.id] : undefined;

  React.useEffect(() => {
    // Try to automatically select a package when undefined:
    // - single package, just pick it
    // - otherwise try to pick the preferred package type if available
    // - otherwise keep empty

    setSelectedPackage((prevSelectedPackage) => {
      if (prevSelectedPackage === undefined && props.content.packages !== undefined) {
        return props.content.packages.length === 1
          ? props.content.packages[0]
          : props.content.packages.find((p) => p.packageType === preferredPackageType);
      }

      return prevSelectedPackage;
    });
  }, [props.content.packages, preferredPackageType, packageTypeNotifKey, closeSnackbar]);

  // Selected file

  const [selectedFileIndex, setSelectedFileIndex] = React.useState<number>();

  React.useEffect(() => {
    // Try to automatically select a file when undefined

    setSelectedFileIndex((prevFileIndex) => {
      if (prevFileIndex === undefined && downloadedPackage !== undefined) {
        const importableFiles = getImportableFiles(
          downloadedPackage.files,
          downloadedPackage.packageType
        );
        const existingFiles = importableFiles.filter((f) => f.exists);

        // Single file? Just use it

        if (existingFiles.length === 1) {
          return importableFiles.findIndex((f) => f === existingFiles[0]);
        }

        // Otherwise, use the first valid one with the preferred type

        const index = importableFiles.findIndex(
          (f) => f.exists && getFileTypefromName(f.name) === preferredFileType
        );

        // Otherwise, use the first one

        return index < 0 ? 0 : index;
      }

      return prevFileIndex;
    });
  }, [downloadedPackage, preferredFileType]);

  // Icon version: open the ContentOverlay
  const openOverlayIfIconVersion = () => {
    if (props.component === 'icon') {
      history.push(createLocationString({ ...currentLocation, contentPath: [props.content.id] }));
    }
  };

  // Display a tooltip when downloading from the online version

  const [importTooltipOpen, setImportTooltipOpen] = React.useState(false);

  const ImportTooltip = (props: React.PropsWithChildren<any>) => (
    <MUI.Tooltip
      open={importTooltipOpen}
      style={{
        backgroundColor: 'white',
        color: 'red'
      }}
      title={
        <MUI.ClickAwayListener
          onClickAway={() => setImportTooltipOpen(false)}
          mouseEvent='onMouseDown'
        >
          <RouterLink
            to='/local'
            style={{
              color: 'inherit',
              textDecoration: 'none'
            }}
          >
            <MUI.Typography variant='body1'>
              Install the{' '}
              <MUI.Box display='inline' fontWeight='bold'>
                3D Bazaar SketchUp extension
              </MUI.Box>{' '}
              to import this file in one click.
              <MUI.Box mt={1}>
                It will automatically find and re-link all the maps and proxies!
              </MUI.Box>
            </MUI.Typography>
          </RouterLink>
        </MUI.ClickAwayListener>
      }
      arrow
      disableFocusListener
      disableHoverListener
      disableTouchListener
      interactive
    >
      {props.children}
    </MUI.Tooltip>
  );

  // Download

  const notifPosition: MUI.SnackbarOrigin = { vertical: 'top', horizontal: 'center' };

  const notifOptions = {
    persist: false,
    autoHideDuration: 5000,
    anchorOrigin: notifPosition
  };

  const handleDownload = () => {
    // Check that mandatory choices have been made before downloading
    // - a package must have been selected
    // - Extension only: a location must have been picked

    if (!selectedPackage) {
      const key = enqueueSnackbar('Please select a Geometry Type', 'info', notifOptions);
      setPackageTypeNotifKey(key);

      setHighlightFields(true);

      openOverlayIfIconVersion();
    } else if (isExtension() && !downloadLocation) {
      const key = enqueueSnackbar('Please select a download location', 'info', notifOptions);
      setDownloadLocationNotifKey(key);

      setHighlightFields(true);

      openOverlayIfIconVersion();
    } else {
      if (!props.content.name) {
        throw new Error('Cannot download package for content without name');
      }

      dispatchAndNotify(
        downloadPackage({
          downloadLocation,
          contentName: props.content.name,
          contentId:
            props.content.type === 'localcontent' ? props.content.marketplaceId : props.content.id,
          packageId: selectedPackage.id
        }),
        enqueueSnackbar
      );

      if (!isExtension()) {
        setImportTooltipOpen(true);
      }
    }
  };

  // Import

  const handleImport = () => {
    // Ensure that a file is selected
    if (selectedFileIndex === undefined) {
      const key = enqueueSnackbar('Please select a file to import', 'info', notifOptions);
      setFileToImportNotifKey(key);

      setHighlightFields(true);

      openOverlayIfIconVersion();
    }

    // Import the selected file
    else if (downloadedPackage) {
      const path = getImportableFiles(downloadedPackage.files, downloadedPackage.packageType)[
        selectedFileIndex
      ].path;

      if (path) {
        startImport(path, props.content, enqueueSnackbar, closeSnackbar);
      }
    }
  };

  const downloadLabel =
    'Download' + (selectedPackage?.size ? ` (${prettyBytes(selectedPackage.size)})` : '');

  // Bar version: render as an action bar with potentially multiple option fields

  if (props.component === 'bar') {
    const availablePackageTypes = Object.entries(PACKAGE_TYPES_DATA).map(([type, typeData]) => {
      const pack = props.content.packages.find((p) => p.packageType === type);
      if (pack) {
        const renderers = getRendererIcons(pack.renderers, 14);
        return (
          <MUI.MenuItem value={type} key={type}>
            <MUI.Box height='16px' display='flex' alignItems='center'>
              {typeData.name}
              &nbsp;
              {renderers}
            </MUI.Box>
          </MUI.MenuItem>
        );
      }

      return null;
    });

    const onSelectPackage = (packageType: ContentPackageType) => {
      const pack = props.content.packages.find((p) => p.packageType === packageType);

      setSelectedPackage(pack);
      dispatchAndNotify(setPreferredPackageType(packageType), enqueueSnackbar);

      closeSnackbar(packageTypeNotifKey);
    };

    const fields: JSX.Element[] = [
      <MUI.FormControl
        key='packageSelection'
        variant='outlined'
        margin='dense'
        className={classes.field}
      >
        <MUI.InputLabel>Geometry type</MUI.InputLabel>
        <MUI.Select
          classes={{ root: classes.fieldLabel }}
          className={classnames({ [classes.emphasizedField]: highlightFields && !selectedPackage })}
          label='Geometry type'
          MenuProps={{ MenuListProps: { dense: true } }}
          value={selectedPackage?.packageType || ''}
          onChange={(e) => onSelectPackage(e.target.value as ContentPackageType)}
        >
          {availablePackageTypes}
        </MUI.Select>
      </MUI.FormControl>
    ];

    if (isExtension()) {
      // Package has been downloaded: show available files

      if (downloadedPackage) {
        const availableFiles = getImportableFiles(
          downloadedPackage.files,
          downloadedPackage.packageType
        );

        const selectedPackageFiles = availableFiles.map((file, index) => {
          const fileType = getFileTypefromName(file.name);

          const tooltip = !file.exists
            ? 'This file is missing'
            : fileType
            ? CONTENT_TYPES_DATA[fileType].name
            : '';

          return (
            <MUI.MenuItem key={index} value={index} disabled={!file.exists}>
              <MUI.Tooltip title={tooltip} enterDelay={0}>
                <MUI.Box
                  height='16px'
                  display='flex'
                  alignItems='center'
                  color={
                    downloadedPackage.packageType === 'proxy' &&
                    file.name.toLowerCase().includes('_fullgeometry.skp')
                      ? '#aaa'
                      : undefined
                  }
                >
                  {fileType && (
                    <Icon
                      size={18}
                      icon={CONTENT_TYPES_DATA[fileType].icon}
                      color={CONTENT_TYPES_DATA[fileType].iconColor}
                    />
                  )}
                  &nbsp;
                  {file.name}
                </MUI.Box>
              </MUI.Tooltip>
            </MUI.MenuItem>
          );
        });

        const onSelectFile = (fileIndex: number) => {
          setSelectedFileIndex(fileIndex);

          // Save as the preferred file type
          const file = availableFiles[fileIndex];
          const type = file ? getFileTypefromName(file.name) : undefined;
          dispatchAndNotify(setPreferredFileType(type as ContentType), enqueueSnackbar);

          closeSnackbar(fileToImportNotifKey);
        };

        fields.push(
          <MUI.FormControl
            key='fileSelection'
            variant='outlined'
            margin='dense'
            className={classes.field}
          >
            <MUI.InputLabel>File</MUI.InputLabel>
            <MUI.Select
              classes={{ root: classes.fieldLabel }}
              className={classnames({
                [classes.emphasizedField]: highlightFields && selectedFileIndex === undefined
              })}
              label='File'
              MenuProps={{ MenuListProps: { dense: true } }}
              value={selectedFileIndex === undefined ? '' : selectedFileIndex}
              onChange={(e) => onSelectFile(e.target.value as number)}
            >
              {selectedPackageFiles}
            </MUI.Select>
          </MUI.FormControl>
        );
      }

      // Package not downloaded yet: show download location interface
      else {
        const selectDownloadLocation = () => {
          dispatchAndNotify(pickDownloadLocation(), enqueueSnackbar);
          closeSnackbar(downloadLocationNotifKey);
        };

        const downloadLocationText = downloadLocation || 'Select Download Location';
        const ellipsisedDownloadLocation = (
          <MiddleEllipsis ellipsedText={downloadLocationText}>
            <span className={classnames(classes.fieldLabel, classes.downloadLocationLabel)}>
              {downloadLocationText}
            </span>
          </MiddleEllipsis>
        );

        fields.push(
          <MUI.Tooltip title={downloadLocation ?? ''} enterDelay={500}>
            <div className={classes.field}>
              <MUI.Button
                key='downloadLocation'
                className={classnames(classes.downloadLocation, {
                  [classes.emphasizedField]: highlightFields && !downloadLocation
                })}
                variant='outlined'
                startIcon={<Icon icon='mdi-folder-outline' />}
                onClick={selectDownloadLocation}
                disabled={
                  // Downloading
                  (downloadingPackage &&
                    (downloadingPackage.status === 'downloading' ||
                      downloadingPackage.status === 'extracting')) ||
                  // or local content
                  props.content.type === 'localcontent'
                }
              >
                {ellipsisedDownloadLocation}
              </MUI.Button>
            </div>
          </MUI.Tooltip>
        );
      }
    }

    // Either show an Import or Download button

    const buttonData = downloadedPackage
      ? {
          label: 'Import',
          icon: 'mdi-import',
          onClick: handleImport,
          disabled: ongoingImport !== undefined
        }
      : {
          label: downloadLabel,
          icon: 'mdi-download-outline',
          onClick: handleDownload,
          loading:
            downloadingPackage &&
            (downloadingPackage.status === 'downloading' ||
              downloadingPackage.status === 'extracting'),
          loadingProgress:
            downloadingPackage?.progress && downloadingPackage.progress >= 0
              ? downloadingPackage.progress * 100
              : undefined,
          disabled: maintenanceMode,
          tooltip: maintenanceMode
            ? 'Maintenance in progress: the marketplace is temporarily unavailable'
            : undefined
        };

    return (
      <MUI.Box className={classnames(classes.root, props.className)}>
        <MUI.Box className={classes.fields}>{fields}</MUI.Box>

        <ImportTooltip>
          <MUI.Box>
            <Button
              variant='contained'
              color='primary'
              classes={{ root: classes.mainButton }}
              disabled={props.content.status === 'loading' || buttonData.disabled}
              onClick={buttonData.onClick}
              startIcon={<Icon icon={buttonData.icon} />}
              loading={buttonData.loading}
              loadingProgress={buttonData.loadingProgress}
              tooltip={buttonData.tooltip}
            >
              {buttonData.label}
            </Button>
          </MUI.Box>
        </ImportTooltip>
      </MUI.Box>
    );
  }

  // Icon version: render as an icon button to be displayed on ContentCards or similar
  else {
    return downloadedPackage ? (
      <IconButton
        icon='mdi-import'
        tooltip='Import'
        disabled={ongoingImport !== undefined}
        onClick={handleImport}
      />
    ) : downloadingPackage ? null : ( // no button if downloading
      <IconButton
        icon='mdi-download-outline'
        disabled={maintenanceMode}
        onClick={handleDownload}
        tooltip={
          maintenanceMode
            ? 'Maintenance in progress: the marketplace is temporarily unavailable'
            : downloadLabel
        }
      />
    );
  }
}

export default React.memo(ContentPrimaryAction);

// Import feedback
//
// There are two steps:
// - 1: loading into SketchUp
// - 2: waiting for the user's to place the asset
//
// Because SketchUp freezes the UI when importing an asset, we have to
// make sure that the first step's notification has finished showing up
// before triggering the actual import or users won't get any feedback.
//
// That's why the actual import is triggered in the onEntered callback.
export function startImport(
  path: string,
  content: Content,
  enqueueSnackbar: ReturnType<typeof useCustomSnackbar>['enqueueSnackbar'],
  closeSnackbar: ReturnType<typeof useCustomSnackbar>['closeSnackbar']
) {
  const doImport = async (notifKey1: SnackbarKey) => {
    // Short delay to let the UI render before letting SketchUp freeze everything up

    await sleep(1000);

    // Start the actual import now that the notification is visible
    const loaded = await dispatchAndNotify(
      importFileInSketchup({ path, contentId: content.id }),
      enqueueSnackbar
    );

    closeSnackbar(notifKey1);

    if (!loaded.fulfilled) {
      return;
    }

    // Step 2: waiting for the user's input

    const notifKey2 = enqueueSnackbar(
      'Import completed. You can now place your asset in the SketchUp viewport.',
      'success',
      {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        persist: true,
        action: undefined
      }
    );

    await dispatchAndNotify(waitForImportEnd({ timeout: 30_000 }), enqueueSnackbar);

    closeSnackbar(notifKey2);
  };

  // Step 1: loading in progress

  const name =
    content.type === 'marketcontent' ? content.name : basename(Base64.decode(content.id));

  enqueueSnackbar(`Importing ${name ? `"${name}"` : 'model'} into SketchUp...`, 'info', {
    anchorOrigin: { vertical: 'top', horizontal: 'center' },
    persist: true,
    transitionDuration: 0,
    action: (key) => (
      <React.Fragment>
        <MUI.CircularProgress style={{ color: 'white' }} size={20} />
      </React.Fragment>
    ),
    onEntered: async (node, isAppearing, notifKey) => {
      doImport(notifKey);
    }
  });
}
