import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import * as H from 'history';
import * as zod from 'zod';

import {
  RootState,
  ResultShape,
  collectAllPromiseErrors,
  ActionError,
  toActionError
} from '../../store';
import { isExtension, sketchupSchema, sketchupAsync, nullToUndefined } from '../../utils';
import { HideFullGeometryFiles, HideFullGeometryFilesSchema } from './appTypes';
import { fetchAuthToken, refreshAuthToken } from '../auth/authActions';
import { syncCartContents } from '../cart/cartActions';
import {
  fetchMarketplaceCategories,
  fetchVendors,
  fetchFreeMarketplaceContents,
  fetchFeaturedMarketplaceContents,
  fetchPopularMarketplaceContents,
  fetchPurchasedContents,
  searchContentsInPath
} from '../contents/contentsActions';
import {
  ContentPackageType,
  ContentType,
  ContentPackageTypeSchema,
  ContentTypeSchema
} from '../contents/contentsTypes';
import { fetchDownloadedPackages } from '../download/downloadActions';
import { fetchFavoriteContents } from '../favorites/favoritesActions';
import { fetchLocalFolders } from '../library/libraryActions';
import { fetchTerms } from '../terms/termsActions';
import { checkNewVersion } from '../update/updateActions';

export const activateMaintenance = createAction<void>('app/activateMaintenance');

export const setPreferredPackageType = createAsyncThunk<
  ContentPackageType | undefined,
  ContentPackageType | undefined,
  { state: RootState; rejectValue: ActionError }
>('app/setPreferredPackageType', async (type, thunkAPI) => {
  try {
    if (isExtension()) {
      const schema = sketchupSchema(zod.null());

      const response = await sketchupAsync({
        methodName: 'setPreferredPackageType',
        args: [type],
        schema
      });

      if (!response.success) {
        throw response.errors;
      }

      return nullToUndefined(type);
    } else {
      if (type) {
        localStorage.setItem('preferredPackageType', type);
      } else {
        localStorage.removeItem('preferredPackageType');
      }
      return type;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('set preferred package type', error));
  }
});

export const setPreferredFileType = createAsyncThunk<
  ContentType | undefined,
  ContentType | undefined,
  { state: RootState; rejectValue: ActionError }
>('app/setPreferredFileType', async (type, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot set preferred file type out of the extension');
    }

    const schema = sketchupSchema(zod.null());

    const response = await sketchupAsync({
      methodName: 'setPreferredFileType',
      args: [type],
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    return nullToUndefined(type);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('set preferred file type', error));
  }
});

export const setHideFullGeometryFiles = createAsyncThunk<
  HideFullGeometryFiles | undefined,
  HideFullGeometryFiles | undefined,
  { state: RootState; rejectValue: ActionError }
>('app/setHideFullGeometryFiles', async (hideFullGeometryFiles, thunkAPI) => {
  console.log(`app/setHideFullGeometryFiles: ${hideFullGeometryFiles}`);
  try {
    if (!isExtension()) {
      throw new Error('cannot set hideFullGeometryFiles out of the extension');
    }

    const schema = sketchupSchema(zod.null());

    const response = await sketchupAsync({
      methodName: 'setHideFullGeometryFiles',
      args: [hideFullGeometryFiles],
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    await thunkAPI
      .dispatch(
        searchContentsInPath({
          location: thunkAPI.getState().navigation,
          append: false
        })
      )
      .then(unwrapResult);

    return nullToUndefined(hideFullGeometryFiles);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('set hideFullGeometryFiles', error));
  }
});

export const setHideTheaSubfolders = createAsyncThunk<
  boolean | undefined,
  boolean | undefined,
  { state: RootState; rejectValue: ActionError }
>('app/setHideTheaSubfolders', async (hideTheaSubfolders, thunkAPI) => {
  console.log(`app/setHideTheaSubfolders: ${hideTheaSubfolders}`);
  try {
    if (!isExtension()) {
      throw new Error('cannot set hideTheaSubfolders out of the extension');
    }

    const schema = sketchupSchema(zod.null());

    const response = await sketchupAsync({
      methodName: 'setHideTheaSubfolders',
      args: [hideTheaSubfolders],
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    thunkAPI.dispatch(fetchLocalFolders());

    return nullToUndefined(hideTheaSubfolders);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('set hideTheaSubfolders', error));
  }
});

const fetchPreferredPackageType = createAsyncThunk<
  ContentPackageType | undefined,
  void,
  { state: RootState; rejectValue: ActionError }
>('app/fetchPreferredPackageType', async (_, thunkAPI) => {
  try {
    if (isExtension()) {
      const schema = sketchupSchema(ContentPackageTypeSchema.nullable());

      const response = await sketchupAsync({
        methodName: 'getPreferredPackageType',
        schema
      });

      if (!response.success) {
        throw response.errors;
      }

      return nullToUndefined(response.data);
    } else {
      const type = localStorage.getItem('preferredPackageType') as ContentPackageType;

      return nullToUndefined(type);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch preferred package type', error));
  }
});

const fetchPreferredFileType = createAsyncThunk<
  ContentType | undefined,
  void,
  { state: RootState; rejectValue: ActionError }
>('app/fetchPreferredFileType', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot fetch preferred file type out of the extension');
    }

    const schema = sketchupSchema(ContentTypeSchema.nullable());

    const response = await sketchupAsync({
      methodName: 'getPreferredFileType',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    const type = nullToUndefined(response.data);
    return type;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch preferred file type', error));
  }
});

const fetchPreferredDownloadLocation = createAsyncThunk<
  string | undefined,
  void,
  { state: RootState; rejectValue: ActionError }
>('app/fetchPreferredDownloadLocation', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot fetch preferred download location out of the extension');
    }

    const schema = sketchupSchema(zod.string().nullable());

    const response = await sketchupAsync({
      methodName: 'getPreferredDownloadLocation',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    const location = nullToUndefined(response.data);
    return location;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch preferred download location', error));
  }
});

const fetchHideFullGeometryFiles = createAsyncThunk<
  HideFullGeometryFiles | undefined,
  void,
  { state: RootState; rejectValue: ActionError }
>('app/fetchHideFullGeometryFiles', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot fetch hideFullGeometryFiles out of the extension');
    }

    const schema = sketchupSchema(HideFullGeometryFilesSchema.nullable());

    const response = await sketchupAsync({
      methodName: 'getHideFullGeometryFiles',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    const hideFullGeometryFiles = nullToUndefined(response.data);
    return hideFullGeometryFiles;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch hideFullGeometryFiles', error));
  }
});

const fetchHideTheaSubfolders = createAsyncThunk<
  boolean | undefined,
  void,
  { state: RootState; rejectValue: ActionError }
>('app/fetchHideTheaSubfolders', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot fetch hideTheaSubfolders out of the extension');
    }

    const schema = sketchupSchema(zod.boolean().nullable());

    const response = await sketchupAsync({
      methodName: 'getHideTheaSubfolders',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    const hideTheaSubfolders = nullToUndefined(response.data);
    return hideTheaSubfolders;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch hideTheaSubfolders', error));
  }
});

export const fetchPreferences = createAsyncThunk<
  {
    preferredPackageType: ContentPackageType | undefined;
    preferredFileType: ContentType | undefined;
    preferredDownloadLocation: string | undefined;
    hideFullGeometryFiles: HideFullGeometryFiles | undefined;
    hideTheaSubfolders: boolean | undefined;
  },
  void,
  { state: RootState; rejectValue: ActionError }
>('app/fetchPreferences', async (_, thunkAPI) => {
  try {
    // Fetch all preferences

    const errors: string[] = [];

    let preferredPackageType: ContentPackageType | undefined = undefined;
    let preferredFileType: ContentType | undefined = undefined;
    let preferredDownloadLocation: string | undefined = undefined;
    let hideFullGeometryFiles: HideFullGeometryFiles | undefined = undefined;
    let hideTheaSubfolders: boolean | undefined = undefined;

    await thunkAPI
      .dispatch(fetchPreferredPackageType())
      .then(unwrapResult)
      .then((result) => (preferredPackageType = result))
      .catch((error) => errors.push(error.message));

    if (isExtension()) {
      await thunkAPI
        .dispatch(fetchPreferredFileType())
        .then(unwrapResult)
        .then((result) => (preferredFileType = result))
        .catch((error) => errors.push(error.message));

      await thunkAPI
        .dispatch(fetchPreferredDownloadLocation())
        .then(unwrapResult)
        .then((result) => (preferredDownloadLocation = result))
        .catch((error) => errors.push(error.message));

      await thunkAPI
        .dispatch(fetchHideFullGeometryFiles())
        .then(unwrapResult)
        .then((result) => (hideFullGeometryFiles = result))
        .catch((error) => errors.push(error.message));

      await thunkAPI
        .dispatch(fetchHideTheaSubfolders())
        .then(unwrapResult)
        .then((result) => (hideTheaSubfolders = result))
        .catch((error) => errors.push(error.message));
    }

    // Check if anything failed

    if (errors.length > 0) {
      throw errors;
    }

    return {
      preferredPackageType,
      preferredFileType,
      preferredDownloadLocation,
      hideFullGeometryFiles,
      hideTheaSubfolders
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch preferences', error));
  }
});

export const fetchReadOnly = createAsyncThunk<
  boolean,
  void,
  { state: RootState; rejectValue: ActionError }
>('app/fetchReadOnly', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot fetch read-only mode out of the extension');
    }

    const schema = sketchupSchema(zod.boolean());

    const response = await sketchupAsync({
      methodName: 'isReadOnly',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch read-only', error));
  }
});

export const fetchExtensionVersion = createAsyncThunk<
  string,
  void,
  { state: RootState; rejectValue: ActionError }
>('app/fetchExtensionVersion', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot fetch extension version out of the extension');
    }

    const schema = sketchupSchema(zod.string());

    const response = await sketchupAsync({
      methodName: 'getCurrentVersion',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch extension version', error));
  }
});

export const fetchSketchupVersion = createAsyncThunk<
  string,
  void,
  { state: RootState; rejectValue: ActionError }
>('app/fetchSketchupVersion', async (_, thunkAPI) => {
  try {
    if (!isExtension()) {
      throw new Error('cannot fetch SketchUp version out of the extension');
    }

    const schema = sketchupSchema(zod.string());

    const response = await sketchupAsync({
      methodName: 'getSketchupVersion',
      schema
    });

    if (!response.success) {
      throw response.errors;
    }

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('fetch SketchUp version', error));
  }
});

export const startup = createAsyncThunk<
  void,
  H.History,
  { state: RootState; rejectValue: ActionError }
>('app/startup', async (history, thunkAPI) => {
  try {
    // First, start actions that do not depend on authentication

    const marketplaceActions = Promise.all<ResultShape | null>([
      thunkAPI.dispatch(fetchPreferences()),
      thunkAPI.dispatch(fetchMarketplaceCategories()),
      thunkAPI.dispatch(fetchVendors()),
      thunkAPI.dispatch(fetchFreeMarketplaceContents()),
      thunkAPI.dispatch(fetchFeaturedMarketplaceContents()),
      thunkAPI.dispatch(fetchPopularMarketplaceContents()),

      // Specific to the extension
      isExtension() ? thunkAPI.dispatch(checkNewVersion()) : null,
      isExtension() ? thunkAPI.dispatch(fetchReadOnly()) : null,
      isExtension() ? thunkAPI.dispatch(fetchSketchupVersion()) : null,
      isExtension() ? thunkAPI.dispatch(fetchFavoriteContents()) : null,
      isExtension() ? thunkAPI.dispatch(fetchLocalFolders()) : null,
      isExtension() ? thunkAPI.dispatch(fetchDownloadedPackages()) : null
    ]);

    // Try to log in before the other steps that depend on it

    let authToken: string | undefined;

    const refreshToken = await thunkAPI.dispatch(fetchAuthToken()).then(unwrapResult);

    if (refreshToken) {
      const refreshTokenResult = await thunkAPI.dispatch(refreshAuthToken()).then(unwrapResult);
      authToken = refreshTokenResult.authToken;
    }

    // Run other steps that depend on being authenticated

    const authRelatedActions = Promise.all<ResultShape | null>([
      // In any case (this also retrieves the saved cart from local storage)

      thunkAPI.dispatch(syncCartContents()),

      // Only if logged in

      thunkAPI.dispatch(fetchPurchasedContents()),
      authToken ? thunkAPI.dispatch(fetchTerms()) : null
      // We fetch terms to force-show them to logged users if they haven't accepted them yet
      // (if not logged in, terms will only be fetched when opening their dialog)
    ]);

    // Wait for everything to be done and collect errors

    const promises = [...(await marketplaceActions), ...(await authRelatedActions)];

    const errors = collectAllPromiseErrors(promises);

    if (errors.length > 0) {
      throw errors;
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('startup', error));
  }
});
