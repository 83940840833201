import { createAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import _ from 'lodash';
import sleep from 'sleep-promise';
import * as zod from 'zod';

import { ActionError, RootState, toActionError } from '../../store';
import {
  jsendSchema,
  webApiAsync,
  nullToUndefined,
  isExtension,
  sketchupSchema,
  sketchupAsync,
  extractJsendError
} from '../../utils';
import { selectUserId } from '../auth/authState';
import { fetchPurchasedContentIds } from '../contents/contentsActions';
import { selectPurchasedContentIds } from '../contents/contentsState';
import {
  MarketplaceContentSchema,
  Content,
  MarketContent,
  marketplaceContentToBranchContent
} from '../contents/contentsTypes';
import { Checkout, selectCartContents } from './cartState';

export const resetCart = createAction<void>('cart/resetCart');
export const updateCheckout = createAction<Checkout | undefined>('cart/updateCheckout');

const setContentSchema = jsendSchema(
  {
    cart: zod.object({
      id: zod.string(),
      contents: zod.array(MarketplaceContentSchema)
    })
  },
  {
    message: zod.string()
  }
);

// Saves the cart to local storage
//
// This stores the Content objects (not just the ID)
//  so that all the data is available without API requests
function saveCartLocally(contents: Content[]) {
  // Store the Content objects, not just the ID
  localStorage.setItem('cart', JSON.stringify(contents));
}

// Synchronizes the remote cart with the local one
// (the local cart has priority)
export const syncCartContents = createAsyncThunk<
  {
    cartOnlineId?: string;
    cartContents?: MarketContent[];
  },
  void,
  { state: RootState; rejectValue: ActionError }
>('cart/syncContents', async (__, thunkAPI) => {
  try {
    const { authToken } = thunkAPI.getState().auth;
    const { contentIds } = thunkAPI.getState().cart;

    // We need to filter out purchased contents
    const removePurchasedContents = (contents: MarketContent[]) => {
      const purchased = selectPurchasedContentIds(thunkAPI.getState());

      const purchasedInCart = _.intersection(
        purchased,
        contents.map((c) => c.id)
      );
      purchasedInCart.forEach((id) =>
        console.warn(`Cart content ${id} has already been purchased, ignoring it`)
      );

      return contents.filter((c) => !purchased.includes(c.id));
    };

    // Logged in: Sync the remote cart

    if (authToken) {
      const userId = selectUserId(thunkAPI.getState());

      // There's a local cart already: overwrite the remote one

      if (contentIds.length > 0) {
        const response = await webApiAsync<zod.infer<typeof setContentSchema>>({
          method: 'post',
          endpoint: 'marketplace/v1/carts/setcontents/',
          data: {
            contentIds,
            userId
          },
          jwt: authToken,
          schema: setContentSchema
        });

        if (response.data.status !== 'success') {
          throw extractJsendError(response.data);
        }

        const cart = response.data.data?.cart;

        if (!cart) {
          return {
            cartOnlineId: undefined,
            cartContents: undefined
          };
        }

        const contents = cart.contents.map(marketplaceContentToBranchContent);

        return {
          cartOnlineId: cart.id,
          cartContents: removePurchasedContents(contents)
        };
      }

      // No local cart: fetch the remote one if it exists
      else {
        const schema = jsendSchema(
          {
            cart: zod
              .object({
                id: zod.string(),
                contents: zod.array(MarketplaceContentSchema)
              })
              .optional() // undefined if there's no active cart
          },
          {
            message: zod.string()
          }
        );

        const response = await webApiAsync({
          method: 'get',
          endpoint: 'marketplace/v1/carts/active/' + userId,
          jwt: authToken,
          schema
        });

        if (response.data.status !== 'success') {
          throw extractJsendError(response.data);
        }

        const cart = response.data.data.cart;

        if (!cart) {
          return {
            cartOnlineId: undefined,
            cartContents: undefined
          };
        }

        const contents = cart.contents.map(marketplaceContentToBranchContent);

        // Save the cart locally so that users can still
        // use it even if they log out without modifying it
        saveCartLocally(contents);

        return {
          cartOnlineId: cart.id,
          cartContents: removePurchasedContents(contents)
        };
      }
    }

    // Logged out: sync from local storage
    else {
      const cartStorage = nullToUndefined(localStorage.getItem('cart'));
      const newContents = (cartStorage ? JSON.parse(cartStorage) : []) as MarketContent[];

      return {
        cartContents: removePurchasedContents(newContents)
      };
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('sync cart', error));
  }
});

// Saves the contents of the cart locally, and remotely when logged in
export const saveCartContents = createAsyncThunk<
  {
    id?: string;
  },
  void,
  { state: RootState; rejectValue: ActionError }
>('cart/saveContents', async (_, thunkAPI) => {
  try {
    const { authToken } = thunkAPI.getState().auth;
    const { contentIds } = thunkAPI.getState().cart;

    // Always save locally

    const contents = selectCartContents(thunkAPI.getState());
    saveCartLocally(contents);

    // Logged in: save to the remote cart

    if (authToken) {
      const response = await webApiAsync<zod.infer<typeof setContentSchema>>({
        method: 'post',
        endpoint: 'marketplace/v1/carts/setcontents/',
        data: {
          contentIds,
          userId: selectUserId(thunkAPI.getState())
        },
        jwt: authToken,
        schema: setContentSchema
      });

      if (response.data.status !== 'success') {
        throw extractJsendError(response.data);
      }

      return { id: response.data.data?.cart?.id };
    }

    return { id: undefined };
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('save cart', error));
  }
});

// Adds an item
export const addContentToCart = createAsyncThunk<
  void,
  Content,
  { state: RootState; rejectValue: ActionError }
>('cart/addContent', async (content, thunkAPI) => {
  try {
    // (Cart edited in the reducer)

    await thunkAPI.dispatch(saveCartContents()).then(unwrapResult);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('add to cart', error));
  }
});

// Removes an item
export const removeContentFromCart = createAsyncThunk<
  void,
  Content,
  { state: RootState; rejectValue: ActionError }
>('cart/removeContent', async (content, thunkAPI) => {
  try {
    // (Cart edited in the reducer)

    await thunkAPI.dispatch(saveCartContents()).then(unwrapResult);
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('remove from cart', error));
  }
});

// Checkout
//
// 1. Fetch the checkout URL
// 2.
//   A. Extension: open an external checkout dialog, use the API to confirm
//   B. Website: open the checkout overlay, use Paddle events to confirm
// 3. Validate that the cart assets are now registered as purchased
export const doCheckout = createAsyncThunk<
  void,
  void,
  { state: RootState; rejectValue: ActionError }
>('cart/doCheckout', async (_x, thunkAPI) => {
  try {
    thunkAPI.dispatch(updateCheckout({ status: 'loading' }));

    // Retrieve a unique checkout URL

    let checkoutUrl: string | undefined;

    const { authToken } = thunkAPI.getState().auth;
    const { onlineId } = thunkAPI.getState().cart;

    if (onlineId === undefined) {
      throw new Error('no online cart ID');
    }

    try {
      const urlSchema = jsendSchema(
        {
          url: zod.string()
        },
        {
          message: zod.string()
        }
      );

      const urlResponse = await webApiAsync({
        method: 'get',
        endpoint: 'marketplace/v1/carts/checkout/' + onlineId,
        jwt: authToken,
        schema: urlSchema
      });

      if (urlResponse.data?.status !== 'success') {
        throw extractJsendError(urlResponse.data);
      }

      checkoutUrl = urlResponse.data.data.url;
    } catch (error) {
      throw new Error(`could not fetch checkout URL, ${error.message ?? error}`);
    }

    // Wait for the checkout to be fulfilled.
    // There are two different paths for the extension and the web versions.

    thunkAPI.dispatch(updateCheckout({ url: checkoutUrl, status: 'inprogress' }));

    try {
      // Extension: we have to display the checkout UI via another SketchUp dialog
      // instead of putting it in the main dialog due to Paypal limitations
      if (isExtension()) {
        const checkoutSchema = sketchupSchema(zod.string()); // Returns the order ID

        const checkoutResponse = await sketchupAsync({
          methodName: 'checkout',
          args: [checkoutUrl],
          schema: checkoutSchema
        });

        if (!checkoutResponse.success) {
          throw checkoutResponse.errors;
        }

        // Check that the order has been completed
        // (Contrarily to the web version, we cannot check this via events)

        // https://checkout.paddle.com/checkout/custom/eyJ0IjoiM0QgQmF6YWFyIEFzc2V0cyIsImkiOiJodHRwczpcL1wvc3RhdGljLnBhZGRsZS5jb21cL2

        const orderSchema = jsendSchema(
          {
            state: zod.enum(['incomplete', 'processing', 'processed'])
          },
          {}
        );

        const orderResponse = await webApiAsync({
          method: 'get',
          endpoint: 'marketplace/v1/carts/checkoutdetails/' + checkoutResponse.data,
          jwt: authToken,
          schema: orderSchema
        });

        if (orderResponse.data?.status !== 'success') {
          throw extractJsendError(orderResponse.data);
        }

        const status = orderResponse.data?.data?.state;

        // Stop there if the checkout got cancelled
        if (status === 'incomplete') {
          thunkAPI.dispatch(updateCheckout(undefined));
          return;
        }
      } else {
        // Web version: having a checkout URL will open up the Paddle UI wrapped
        // in an iframe that forwards messages from the Paddle SDK.
        //
        // We listen for the SDK's messages here so that the whole logic is
        // centralized in the same spot.

        // Tricky bit below: we "promisify" events to wait until the checkout
        // is either cancelled or completed.

        let listeners: any[] = [];

        const completed = await Promise.race([
          // Wait for cancellation
          new Promise((res) => {
            const listener = (event: any) => {
              if (event.data.event_name === 'Checkout.Close') res(false);
            };
            window.addEventListener('message', listener);
            listeners.push(listener);
          }),

          // Wait for completion
          new Promise((res) => {
            const listener = (event: any) => {
              if (event.data.event_name === 'Checkout.Complete') res(true);
            };
            window.addEventListener('message', listener);
            listeners.push(listener);
          })
        ]);

        for (let listener of listeners) {
          window.removeEventListener('message', listener);
        }

        // Stop there if the checkout got cancelled
        if (!completed) {
          thunkAPI.dispatch(updateCheckout(undefined));
          return;
        }
      }
    } catch (error) {
      throw new Error(`could not fulfill checkout, ${error.message ?? error}`);
    }

    // Validate the purchase
    //
    // We fetch the purchased contents from the server until we get the contents that has just been purchased
    // (we only fetch IDs at this point)

    thunkAPI.dispatch(updateCheckout({ url: checkoutUrl, status: 'validating' }));

    try {
      const step = 2_000;
      const timeout = 60_000;

      let contentPurchaseToConfirm = thunkAPI.getState().cart.contentIds;

      for (let t = 0; t < timeout; t += step) {
        console.log(
          `Waiting for purchase confirmation for contents: ${contentPurchaseToConfirm.join(', ')}`
        );

        const allPurchasedContentIds = unwrapResult(
          await thunkAPI.dispatch(fetchPurchasedContentIds())
        );

        contentPurchaseToConfirm = _.difference(contentPurchaseToConfirm, allPurchasedContentIds);

        if (contentPurchaseToConfirm.length > 0) {
          await sleep(step);
        } else {
          console.log('Purchase confirmed');
          break;
        }
      }

      if (contentPurchaseToConfirm.length > 0) {
        throw new Error(`could not verify purchase (delay > ${timeout} ms)`);
      }
    } catch (error) {
      throw new Error(`could not verify purchase, ${error.message ?? error}`);
    }

    thunkAPI.dispatch(updateCheckout(undefined));
    thunkAPI.dispatch(resetCart());
  } catch (error) {
    return thunkAPI.rejectWithValue(toActionError('checkout', error));
  }
});
